import type React from "react";

import { DescriptionDetail, DescriptionList, DescriptionTerm } from "@fremtind/jkl-description-list-react";

import styles from "./DetailTable.module.scss";

export interface DetailTableRowProps {
    label: string;
    value: React.ReactNode;
}

interface DetailTableProps {
    children: React.ReactNode;
}

export const DetailTable = ({ children }: DetailTableProps) => {
    return <DescriptionList className={styles.detailTable}>{children}</DescriptionList>;
};

export const DetailTableRow = ({ label, value }: DetailTableRowProps) => {
    return (
        <>
            <DescriptionTerm>{label}</DescriptionTerm>
            <DescriptionDetail>{value}</DescriptionDetail>
        </>
    );
};

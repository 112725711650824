import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { HomePageCTA as HomePageCTAV05 } from "@features/kjop/bil";
import { useAvailableProducts } from "@features/kjop/kjop.queries";
import { useCustomerNumberCheck, useIsManaged } from "@features/kjop/kjop.utils";
import { HomePageCTA as HomePageCTAY44 } from "@features/kjop/reise";

export const SelfServiceSection = () => {
    const { products, isLoading } = useAvailableProducts();

    const Y44Object = products?.find((product) => product.productCode === AvtaleProduktID.REISE_NERING);

    // Ikke vis innganger dersom kunden ikke har et gyldig kundenummer i NICE.
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    // Ikke vis innganger for meglede kunder.
    const isManaged = useIsManaged();

    const shouldShowSelfService = (hasNiceCustomerNumber || Y44Object?.flowPath) && !isManaged;

    if (!shouldShowSelfService || isLoading) return null;

    return (
        <section className="bm-purchase__selfservice jkl-spacing-64--bottom">
            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                Kjøp forsikring selv
            </Typography>
            <div className="bm-purchase__selfservice__cards">
                {Y44Object?.flowPath && <HomePageCTAY44 shouldShow={Y44Object.flowPath} title="Reiseforsikring" />}
                <HomePageCTAV05 shouldShow={hasNiceCustomerNumber ? "/kjop/bil" : undefined} title="Bilforsikring" />
            </div>
        </section>
    );
};

import { PrimaryLinkButton } from "@components/Button";
import { Typography } from "@components/Typography";

export const NoClaimsMsg = ({ buttonLink }: { buttonLink: string | undefined }) => {
    return (
        <section>
            <Typography component="p" variant="body" className="jkl-spacing-xl--bottom">
                Ingen skadesaker funnet
            </Typography>
            {buttonLink && <PrimaryLinkButton to={buttonLink}>Meld ny skadesak</PrimaryLinkButton>}
        </section>
    );
};

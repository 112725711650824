import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import { useAnimatedHeight } from "@fremtind/jkl-react-hooks";

import { adminRoller, findAdminRolle } from "../../../../common/rolle";
import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { StegProps } from "../../../../components/SkjemaFlyt";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Toast } from "../../../../components/Toast";
import { Rolle } from "../../../../model/Rolle";
import { Trackingkey } from "../../../../tracking/tracking.model";
import { useGetTilganger, usePostTilganger } from "../../queries";
import { FormDataPerson } from "./SkjemaKontroller";

export const TilgangerLeggTilSkjema = (props: StegProps) => {
    const tilgangerQuery = useGetTilganger();
    const tilgangerMutation = usePostTilganger();

    const { register, handleSubmit, watch, setValue } = useFormContext<FormDataPerson>();

    const person = watch(["fornavn", "etternavn"]);
    const personNavn = `${person[0]} ${person[1]}`;

    // const [tilgang, setTilgang] = useState<Tilgangype>(tilgangValue || "administrator");
    const tilgang = watch("tilgang");
    const [rollerInvalid, setRollerInvalid] = useState(false);

    const [checkboxRef] = useAnimatedHeight<HTMLDivElement>(tilgang === "endre");

    const rollerValue = watch("roller");

    const avtaleQuery = useAgreementList();

    // lag en oversikt over hvilke unike avtaletyper kunden har
    const avtaleTyper = [...new Set(avtaleQuery.data?.agreements.map((a) => a.category))].flat();

    useEffect(() => {
        if (tilgang === "endre" && !rollerValue) {
            setValue(
                "roller",
                tilgangerQuery.data?.tilgjengeligePersonTilganger
                    .map((t) => t.rolle)
                    // legg alle roller untatt administrator under "endre"
                    .filter((rolle) => !adminRoller.includes(rolle)) ?? [],

                { shouldDirty: true }
            );
        }
    }, [tilgang, rollerValue, setValue, tilgangerQuery.data]);

    useEffect(() => {
        if (rollerValue?.length) {
            setRollerInvalid(false);
        }
    }, [rollerValue]);

    const handleBack = () => {
        props.onTilbake();
    };

    if (avtaleQuery.isLoading) {
        return <Loader textDescription="Henter avtaler" />;
    }

    if (!tilgangerQuery.data) {
        return <Loader textDescription="Henter tilganger" />;
    }

    const adminRolle = findAdminRolle(
        tilgangerQuery.data?.tilgjengeligePersonTilganger.map(({ rolle }) => rolle) || []
    );

    const onSubmit = async (formValues: FormDataPerson) => {
        if (formValues.tilgang === "endre" && !formValues.roller?.length) {
            setRollerInvalid(true);
            return;
        }

        if (formValues.roller && !Array.isArray(formValues.roller)) {
            formValues.roller = [formValues.roller];
        }

        const personData: {
            person: {
                fodselsnummer: string;
                fornavn: string;
                etternavn: string;
            };
            roller: Rolle[];
        } = {
            person: {
                fodselsnummer: formValues.fodselsnummer,
                fornavn: formValues.fornavn,
                etternavn: formValues.etternavn
            },
            roller: formValues.tilgang === "administrator" && adminRolle ? [adminRolle] : formValues.roller
        };

        tilgangerMutation.mutate(
            { data: personData },
            {
                onSuccess: () => {
                    props.onFrem();
                }
            }
        );
    };

    return (
        <form
            name="legg til ny tilgang"
            className="tilganger-legg-til__gi-tilganger-side"
            onSubmit={handleSubmit(onSubmit)}
        >
            <RadioButtonGroup legend={`Hvilke tilganger skal ${personNavn} ha?`}>
                <RadioButton
                    data-testid="gi-tilgang-radio-administrator"
                    label="Administrator"
                    value="administrator"
                    defaultChecked
                    {...register("tilgang")}
                    aria-describedby="administrator-help-label"
                />
                {/* label er assosiert med kontroll gjennom aria-describedby */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label id="administrator-help-label" className="radio-label">
                    Administratorer kan se og endre alle forsikringer og er de eneste som kan gi tilganger og samtykke
                    på vegne av bedriften.
                </label>

                {!!avtaleTyper.length && (
                    <>
                        <RadioButton
                            data-testid="gi-tilgang-radio-endre"
                            label="Kun se og endre forsikringer"
                            {...register("tilgang")}
                            value="endre"
                            aria-describedby="endre-help-label"
                        />
                        <label id="endre-help-label" className="radio-label jkl-spacing-m--bottom">
                            Velg hvilke forsikringstyper {personNavn} skal ha tilgang til.
                        </label>
                        <div
                            ref={checkboxRef}
                            className={`checkbox-group ${tilgang === "endre" ? "" : "checkbox-group--hidden"}`}
                        >
                            <FieldGroup
                                id="gi-tilgang-rolle-valg"
                                data-testautoid="gi-tilgang-rolle-valg"
                                legend={`Velg hvilke forsikringstyper ${personNavn} skal ha tilgang til.`}
                                labelProps={{ srOnly: true }}
                                errorLabel={rollerInvalid ? "Du må velge forsikringstype" : undefined}
                            >
                                {tilgangerQuery.data?.tilgjengeligePersonTilganger
                                    .filter(({ rolle }) => !adminRoller.includes(rolle))
                                    .sort((a, b) => a.rolle.localeCompare(b.label))
                                    .map(({ rolle, label }) => (
                                        <Checkbox
                                            key={rolle}
                                            invalid={rollerInvalid}
                                            {...register("roller")}
                                            value={rolle}
                                            aria-describedby="roller-error-label"
                                        >
                                            {label}
                                        </Checkbox>
                                    ))}
                            </FieldGroup>
                        </div>
                    </>
                )}
            </RadioButtonGroup>

            <SkjemaFooter>
                <PrimaryButton
                    data-testid="gi-tilgang-person-lagre"
                    dataTestautoid="gi-tilgang-person-lagre"
                    track={[
                        { hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-person-lagre" },
                        { hendelse: Trackingkey.Tilgang, tilgang: "person", handling: "gi" }
                    ]}
                    loader={{ showLoader: tilgangerMutation.isPending, textDescription: "lagrer tilgang ansatt" }}
                >
                    Lagre
                </PrimaryButton>

                <SecondaryButton
                    data-testid="gi-tilgang-person-forrige"
                    dataTestautoid="gi-tilgang-person-forrige"
                    className="jkl-spacing-l--right"
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-person-forrige" }}
                    type="button"
                    onClick={handleBack}
                >
                    ← Forrige
                </SecondaryButton>
            </SkjemaFooter>

            <Toast
                open={tilgangerMutation.isError}
                onClose={tilgangerMutation.reset}
                severity="error"
                title="Vi har gjort en feil"
            >
                Vi kunne ikke gi tilgang til {personNavn} akkurat nå. Prøv en gang til eller kom tilbake senere.
            </Toast>
        </form>
    );
};

import { useFormContext } from "react-hook-form";

import { Loader } from "@fremtind/jkl-loader-react";

import { getRolleFromTilgjengeligeTilganger } from "../../../../common/rolle";
import { SecondaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { Kvittering as KvitteringBase } from "../../../../components/Kvittering";
import { StegProps } from "../../../../components/SkjemaFlyt";
import { Trackingkey } from "../../../../tracking";
import { useBruker } from "../../../bruker/queries";
import { useGetTilganger } from "../../queries";
import { FormDataPerson } from "./SkjemaKontroller";

export const Kvittering = (props: StegProps) => {
    const { valgtOrganisasjon } = useBruker();
    const tilgangerQuery = useGetTilganger();
    const { getValues } = useFormContext<FormDataPerson>();

    const values = getValues();

    if (!tilgangerQuery.data) {
        return <Loader textDescription="laster inn tilganger" />;
    }

    const heading =
        values.tilgang === "administrator"
            ? `Flott! Som administrator kan ${values.fornavn} ${values.etternavn}:`
            : `Flott! ${values.fornavn} ${values.etternavn} kan nå se og endre:`;

    const positives =
        values.tilgang === "administrator"
            ? [
                  `Gi og endre samtykker på vegne av ${valgtOrganisasjon?.navn}`,
                  `Opprette og vedlikeholde hvem som har tilgang til ${valgtOrganisasjon?.navn} sine forsikringer`,
                  "Se, endre og kjøpe forsikringer"
              ]
            : ((Array.isArray(values.roller) ? values.roller : [values.roller])
                  .map(
                      (rolle) =>
                          getRolleFromTilgjengeligeTilganger(rolle, tilgangerQuery.data.tilgjengeligePersonTilganger)
                              ?.label
                  )
                  .filter((val) => typeof val === "string") as string[]);

    return (
        <section className="gi-tilganger-side">
            <KvitteringBase heading={heading} positives={positives}>
                <SecondaryLinkButton
                    to="/tilganger"
                    data-testid="gi-tilgang-kvittering-hjem"
                    onClick={() => props.onFerdig()}
                >
                    Ferdig
                </SecondaryLinkButton>
                <SecondaryButton
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-kvittering-restart" }}
                    dataTestautoid="gi-tilgang-kvittering-restart"
                    data-testid="gi-tilgang-kvittering-restart"
                    onClick={() => props.onRestart()}
                >
                    Ny tilgang
                </SecondaryButton>
            </KvitteringBase>
        </section>
    );
};

import { type FC, useCallback, useEffect, useMemo, useState } from "react";

import { FormProvider, UseFormReturn } from "react-hook-form";

import { Typography } from "@components/Typography";
import { CheckListItem, List } from "@fremtind/jkl-list-react";

import { Trackingkey, track } from "../../tracking";
import "./Flow.scss";

export interface StepFunctions {
    onPrevious: (programmatisk?: boolean, steg?: number) => void;
    onNext: (programmatisk?: boolean) => void;
    onSubmit: (restart?: boolean, programmatisk?: boolean) => void;
    onCancel: (programmatisk?: boolean) => void;
    onRestart: (programmatisk?: boolean) => void;
}
export interface Step<T = Record<string, unknown> & StepFunctions> {
    stepName: string;
    stepDescription?: string;
    element: FC<T>;
}

export interface FormTrackingMeta {
    flowName: string;
    stepName: string;
}

export interface FlowProps<T extends StepFunctions> {
    flowName: string;
    steps: Step<T>[];
    formMethods: UseFormReturn<any>;
    image?: string;
    valuesToSummarize?: string | string[] | undefined | null;
    setFormTrackingMeta?: (arg0: FormTrackingMeta) => void;
}

const isOutOfBounds = (newIndex: number, length: number) => {
    if (length - (newIndex + 1) < 0) {
        return true;
    }

    if (newIndex + 1 > length) {
        return true;
    }

    return false;
};

const useFlowTracking = (flytnavn: string, stegnavn: string) => {
    const fh = useCallback(
        (handling: "neste" | "tilbake" | "ferdig" | "avbryt" | "restart", programmatisk = false, omstart = false) => {
            if (handling === "ferdig") {
                track({
                    hendelse: Trackingkey.Skjemaflyt,
                    flytnavn,
                    stegnavn,
                    handling,
                    programmatisk,
                    omstart
                });
            } else {
                track({
                    hendelse: Trackingkey.Skjemaflyt,
                    flytnavn,
                    stegnavn,
                    handling
                });
            }
        },
        [flytnavn, stegnavn]
    );

    return fh;
};

export function Flow<T extends StepFunctions>({
    steps,
    formMethods,
    flowName,
    image = "/bedrift/static/images/forms/image.webp",
    valuesToSummarize,
    setFormTrackingMeta,
    ...rest
}: FlowProps<T>) {
    const [currentStep, setCurrentStep] = useState(0);
    const currentStepObject = useMemo(() => steps[currentStep], [currentStep, steps]);
    const flowAction = useFlowTracking(flowName, currentStepObject.stepName);

    useEffect(() => {
        window.scroll(0, 0);
    }, [currentStep]);

    useEffect(() => {
        if (setFormTrackingMeta) {
            setFormTrackingMeta({ flowName: flowName, stepName: currentStepObject.stepName });
        }
    }, [currentStepObject, flowName, setFormTrackingMeta]);

    const onNext: StepFunctions["onNext"] = useCallback(
        (programmatisk = false) => {
            const nextStep = currentStep + 1;

            if (isOutOfBounds(nextStep, steps.length)) {
                return;
            }

            flowAction("neste", programmatisk);
            setCurrentStep(nextStep);
        },
        [currentStep, flowAction, steps.length]
    );

    const onPrevious: StepFunctions["onPrevious"] = useCallback(
        (programmatisk = false, steg?: number) => {
            const nextStep = steg !== undefined ? steg : currentStep - 1;

            if (isOutOfBounds(nextStep, steps.length)) {
                return;
            }

            flowAction("tilbake", programmatisk);
            setCurrentStep(nextStep);
        },
        [currentStep, flowAction, steps.length]
    );

    const onSubmit: StepFunctions["onSubmit"] = useCallback(
        (restart = false, programmatisk = false) => {
            flowAction("ferdig", programmatisk, restart);

            if (restart) {
                formMethods.reset();
                setCurrentStep(0);
            }
        },
        [flowAction, formMethods]
    );

    const onCancel: StepFunctions["onCancel"] = useCallback(
        (programmatisk = false) => {
            flowAction("avbryt", programmatisk);
        },
        [flowAction]
    );

    const onRestart: StepFunctions["onRestart"] = useCallback(
        (programmatisk = false) => {
            flowAction("restart", programmatisk);
            setCurrentStep(0);
            formMethods.reset();
        },
        [flowAction, formMethods]
    );

    return (
        <div className="flow">
            <Typography onlyScreenReader variant="heading-1">
                {flowName}
            </Typography>
            <div className={`flow__form ${currentStep === steps.length - 1 ? "flow__form--submitted" : ""}`}>
                <hgroup className="flow__form__header">
                    <Typography variant="heading-3" component="h2">
                        {steps[currentStep].stepName}
                    </Typography>
                    {steps[currentStep].stepDescription && (
                        <Typography>{steps[currentStep].stepDescription}</Typography>
                    )}
                </hgroup>
                <FormProvider {...formMethods}>
                    {/* @ts-ignore Vi bryr oss ikke om hvilket spesifikke element som blir brukt her. */}
                    <currentStepObject.element
                        onNext={onNext}
                        onPrevious={onPrevious}
                        onCancel={onCancel}
                        onSubmit={onSubmit}
                        onRestart={onRestart}
                        {...rest}
                    />
                </FormProvider>
            </div>
            {valuesToSummarize && (
                <aside className="flow__aside">
                    {Array.isArray(valuesToSummarize) ? (
                        valuesToSummarize.some((value) => value) && (
                            <div className="flow__aside__summary">
                                <Typography variant="heading-3" component="h2">
                                    Oppsummering
                                </Typography>
                                <List>
                                    {valuesToSummarize.map((value) => (
                                        <CheckListItem key={value}>{value}</CheckListItem>
                                    ))}
                                </List>
                            </div>
                        )
                    ) : (
                        <div className="flow__aside__summary">
                            <Typography variant="heading-3" component="h2">
                                Oppsummering
                            </Typography>
                            <Typography variant="body">{valuesToSummarize}</Typography>
                        </div>
                    )}
                </aside>
            )}
            {currentStep === steps.length - 1 && <img className="flow__image" src={image} alt="" />}
        </div>
    );
}

import { toNorwegianDateFormat } from "src/common/formatting";
import type { AgreementDetails } from "src/model/gen";

import { InfoMessageBox } from "@fremtind/jkl-message-box-react";

import { AgreementRenewalText } from "./AgreementRenewalText";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementRenewal = ({ agreementDetails }: Props) => {
    const inRenewalOrPreRenewalPhase =
        agreementDetails.renewalPhase === "RENEWAL" || agreementDetails.renewalPhase === "PRE_RENEWAL";

    if (!inRenewalOrPreRenewalPhase) return null;

    return (
        <div style={{ maxWidth: "777px" }}>
            <InfoMessageBox
                title={`Avtalen din fornyes ${toNorwegianDateFormat(agreementDetails.nextVersion!.startDate)}`}
                fullWidth
                className="jkl-spacing-2xl--bottom"
                data-testid="agreement-renewal"
            >
                <AgreementRenewalText agreementDetails={agreementDetails} />
            </InfoMessageBox>
        </div>
    );
};

import { Link } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";

import { Typography } from "@components/Typography";
import { formatValuta } from "@fremtind/jkl-formatters-util";

import { InvoiceDto } from "../../../../model/gen";
import { Trackingkey, track } from "../../../../tracking";
import { StatusTag } from "../StatusTag";
import "./UnpaidInvoiceCard.scss";

interface Props {
    invoice: InvoiceDto;
}

export const UnpaidInvoiceCard = ({ invoice }: Props) => {
    return (
        <Link
            to={invoice.id}
            title={formatValuta(invoice.belop)}
            onClick={() =>
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "se-fakturadetaljer-fakturaoversikt"
                })
            }
            className="bm-unpaid-invoices__card jkl-nav-card"
        >
            <Typography component="h3" variant="heading-2">
                {formatValuta(invoice.belop)}
            </Typography>
            <StatusTag
                status={invoice.status}
                dueDate={invoice.betalingsFrist}
                invoiceEventType={invoice.invoiceEventType}
                className="bm-unpaid-invoices__card-item--align-right"
            />
            <Typography>Frist: {toNorwegianDateFormat(invoice.betalingsFrist)}</Typography>
            <Typography className="bm-unpaid-invoices__card-item--align-right">
                {invoice.invoiceEventType
                    .split("_")
                    .map((w) => {
                        const _w = w.toLocaleLowerCase();
                        return _w.charAt(0).toUpperCase() + _w.slice(1);
                    })
                    .join(" ")}
            </Typography>
        </Link>
    );
};

import { ReactNode } from "react";

import { Typography } from "@components/Typography";

import { BreadCrumbs } from "../BreadCrumbs";
import { SkeletonAnimation, SkeletonElement } from "../Skeleton";
import "./SideHeader.scss";

interface SideHeaderNyProps {
    title: string;
    lead?: string | ReactNode;
    actions?: ReactNode;
    links?: ReactNode;
    loading?: boolean;
    hideBreadcrumbs?: boolean;
}

/**
 * Bruk som header på sider. Underelementer vil dukke opp til høyre for teksten.
 */
export const SideHeader = ({ title, lead, actions, links, loading, hideBreadcrumbs }: SideHeaderNyProps) => {
    return (
        <header className="page-header">
            {!hideBreadcrumbs && <BreadCrumbs />}

            {!loading ? (
                <>
                    <hgroup>
                        <Typography variant="title" component="h1">
                            {title}
                        </Typography>
                        {lead && <Typography>{lead}</Typography>}
                    </hgroup>
                    {actions && <div className="actions">{actions}</div>}
                    {links && (
                        <div className="links">
                            <Typography variant="heading-5" component="h2">
                                Lenker
                            </Typography>
                            {links}
                        </div>
                    )}
                </>
            ) : (
                <SkeletonAnimation>
                    <SkeletonElement style={{ marginBottom: 16 }} width={256} height={64} />
                    <SkeletonElement style={{ marginBottom: 12 }} width={512} height={20} />
                    <SkeletonElement style={{ marginBottom: 12 }} width={384} height={20} />
                    <SkeletonElement style={{ marginBottom: 12 }} width={256} height={20} />
                </SkeletonAnimation>
            )}
        </header>
    );
};

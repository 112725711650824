import { useEffect } from "react";

import { TaskResponseDto } from "src/model/gen/models/TaskResponseDto";
import { Trackingkey, track } from "src/tracking";

import { HomePageOffers } from "@features/kjop/components/HomePageOffers";

import { TaskCard } from "../TaskCard/TaskCard";

interface TaskListProps {
    heading?: React.ReactNode;
    className?: string;
    tasks: TaskResponseDto[];
    category?: TaskResponseDto["category"];
    status?: TaskResponseDto["status"][];
    productCode?: string;
    agreementId?: string;
    sort?: {
        order: "asc" | "desc";
        sortBy: "createdDate" | "priority" | "title" | "status";
    };
    showImages?: boolean;
    showOffers?: boolean;
}

export const TaskList = (props: TaskListProps) => {
    const { category, status, tasks, sort, heading, className } = props;
    const showImage = props.showImages || false;
    const showOffers = props.showOffers || false;

    const filteredTasks = tasks?.filter(
        (task: TaskResponseDto) =>
            (category ? task.category?.toLowerCase() === category.toLowerCase() : true) &&
            (status ? status?.includes(task.status) : task.status?.includes("sendt") || task.status?.includes("lest"))
    );

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: `varsler ${category ? `i kategorien ${category}` : ""}`,
            antall: filteredTasks?.length ?? 0
        });
    }, [filteredTasks, category]);

    if (!filteredTasks?.length) {
        return showOffers ? <HomePageOffers homepage /> : null;
    }

    if (sort) {
        filteredTasks.sort((a, b) => {
            // sort by createdDate desc by default
            if (sort.sortBy === "createdDate") {
                return new Date(a.createdDate ?? "") < new Date(b.createdDate ?? "") ? 1 : -1;
            }
            if (sort.order === "asc") {
                return (a[sort.sortBy] ?? "") > (b[sort.sortBy] ?? "") ? 1 : -1;
            } else {
                return (a[sort.sortBy] ?? "") < (b[sort.sortBy] ?? "") ? 1 : -1;
            }
        });
    }

    return (
        <section className={`${className ? className : "varslinger"}`}>
            {heading && heading}
            <ul>
                {filteredTasks?.map((task) => (
                    <li key={task.taskId}>
                        <TaskCard task={task} {...{ showImage }} />
                    </li>
                ))}
                {showOffers && <HomePageOffers list />}
            </ul>
        </section>
    );
};

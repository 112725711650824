import React, { createContext, useContext } from "react";

import { useScreen as useJklScreen } from "@fremtind/jkl-react-hooks";

type ScreenType = ReturnType<typeof useJklScreen>;

export const ScreenContext = createContext<ScreenType>({} as unknown as ScreenType);

export const ScreenProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const screen = useJklScreen();

    return <ScreenContext.Provider value={screen}>{children}</ScreenContext.Provider>;
};

export const useScreen = () => {
    const context = useContext(ScreenContext);

    if (typeof context === "undefined") {
        throw new Error("useScreen must be used within a ScreenProvider");
    }

    return context;
};

import React from "react";

import cx from "classnames";

import "./SkjemaFooter.scss";

interface SkjemaFooterProps {
    className?: string;
}

export const SkjemaFooter: React.FC<React.PropsWithChildren<SkjemaFooterProps>> = ({ children, className = "" }) => {
    return <footer className={cx("skjema-footer", className)}>{children}</footer>;
};

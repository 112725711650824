import { useRemarkSync } from "react-remark";
import { Link } from "react-router-dom";
import { Trackingkey, track } from "src/tracking";

import { Link as JklLink } from "@fremtind/jkl-core";
import { ListItem, UnorderedList } from "@fremtind/jkl-list-react";

import { type LenkeId, useExternalLinks } from "../lenker";

export const useMarkdown = (markdown?: string) => {
    const { getLinkByLinkId } = useExternalLinks();

    return useRemarkSync(markdown!, {
        rehypeReactOptions: {
            components: {
                a: (_props: unknown) => {
                    const props = _props as { children: string; href: LenkeId };
                    const link = getLinkByLinkId(props.href);

                    if (!link) {
                        return null;
                    }

                    if (link.href.includes("http")) {
                        return (
                            <JklLink
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: link.lenkeId
                                    })
                                }
                                {...props}
                                href={link.href}
                            />
                        );
                    } else {
                        return (
                            <Link
                                className="jkl-link"
                                onClick={() =>
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: link.lenkeId
                                    })
                                }
                                {...props}
                                to={link.href}
                            />
                        );
                    }
                },
                ul: (_props: unknown) => {
                    const props = _props as { children: string };
                    return <UnorderedList>{props.children}</UnorderedList>;
                },
                li: (_props: unknown) => {
                    const props = _props as { children: string };
                    return <ListItem>{props.children}</ListItem>;
                }
            }
        }
    });
};

import { Link } from "react-router-dom";

import "./IkkeFunnetFeil.scss";

export function IkkeFunnetFeil() {
    return (
        <div className="not-found">
            <div className="not-found__informasjon">
                <h2 className="jkl-title">Oi! Denne siden finnes ikke.</h2>
                <p className="jkl-body not-found__informasjon--hjelpe-tekst">
                    <span>
                        Vi på bakrommet vet ikke helt hvor du skal, så vi foreslår at du går til oversikten over
                        forsikringene dine.
                    </span>
                </p>

                <div className="not-found__informasjon--handlinger">
                    <Link className="jkl-button jkl-button--primary link-empty-style" to="/hjem">
                        Gå til forsiden
                    </Link>
                </div>
            </div>
        </div>
    );
}

import { BrukerRolle } from "src/model/bruker.typer";
import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import { antallslisteOversikt, endreAntallAnsatte } from "./views";

export const antallslisteRoutes: BMRoute = {
    path: "ansatte-antall",
    children: [
        {
            index: true,
            loader: antallslisteOversikt.loader,
            element: (
                <ProtectedRoute
                    requiredRolle={[
                        BrukerRolle.PERSONAL,
                        BrukerRolle.FORENING,
                        BrukerRolle.RADGIVER,
                        BrukerRolle.RADGIVER_DNB,
                        BrukerRolle.ADMINISTRATOR_DNB
                    ]}
                >
                    <antallslisteOversikt.AntallslisteOversikt />
                </ProtectedRoute>
            ),
            breadcrumbLabel: "Endre antall ansatte"
        },
        {
            path: "endre-antall",
            children: [
                {
                    index: true,
                    breadcrumbLabel: "Endre antall",
                    loader: endreAntallAnsatte.loader,
                    element: (
                        <ProtectedRoute
                            requiredRolle={[
                                BrukerRolle.PERSONAL,
                                BrukerRolle.RADGIVER,
                                BrukerRolle.ADMINISTRATOR_DNB,
                                BrukerRolle.RADGIVER_DNB
                            ]}
                        >
                            <endreAntallAnsatte.EndreAntallAnsatte />
                        </ProtectedRoute>
                    )
                },
                {
                    path: ":indeks",
                    loader: endreAntallAnsatte.loader,
                    element: (
                        <ProtectedRoute
                            requiredRolle={[
                                BrukerRolle.PERSONAL,
                                BrukerRolle.RADGIVER,
                                BrukerRolle.ADMINISTRATOR_DNB,
                                BrukerRolle.RADGIVER_DNB
                            ]}
                        >
                            <endreAntallAnsatte.EndreAntallAnsatte />
                        </ProtectedRoute>
                    )
                }
            ]
        }
    ]
};

import React, { useCallback, useEffect } from "react";

import { add, endOfDay, isAfter } from "date-fns";
import { FieldError, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { constants, model, utils } from "@features/navneliste";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { formatInput } from "@fremtind/jkl-datepicker-react";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { CheckListItem, List, ListItem, UnorderedList } from "@fremtind/jkl-list-react";
import { ErrorMessageBox, InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";

import { FormStateAvtale } from ".";
import { FormState, StegProps } from ".";
import { isDefined } from "../../../../common/utils";
import { PrimaryButton, SecondaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Typography } from "../../../../components/Typography";
import { Trackingkey } from "../../../../tracking";

const isForGammelForAvtale = (insured: model.Person, avtale: model.OppdateringStatusAvtale) => {
    if (!avtale.avtaleGrupper.length) {
        return false;
    }

    return avtale.avtaleGrupper.some((gruppe) => gruppe.forGammel === true);
};

const isForUngForAvtale = (insured: model.Person, avtale: model.OppdateringStatusAvtale) => {
    if (!avtale.avtaleGrupper.length) {
        return false;
    }
    return avtale.avtaleGrupper.some((gruppe) => gruppe.forUng === true);
};

const createInnmeldingDato = (gruppe: model.OppdateringStatusAvtaleGruppe, avtaleEtablertDato: string) => {
    // TODO: Dette er en regresjon som ble inført med FBM-2169. Felter som var i bruk ble fjernet.

    let min = constants.DEFAULT_INNMELDING_DATO.min;
    const max = constants.DEFAULT_INNMELDING_DATO.max;

    // if (gruppe.datoPersonNarMinAlder && isAfter(endOfDay(new Date(gruppe.datoPersonNarMinAlder)), min)) {
    //     min = new Date(gruppe.datoPersonNarMinAlder);
    // }

    // if (gruppe.datoPersonNarMaksAlder && isBefore(startOfDay(new Date(gruppe.datoPersonNarMaksAlder)), max)) {
    //     max = new Date(gruppe.datoPersonNarMaksAlder);
    // }

    if (isAfter(endOfDay(new Date(avtaleEtablertDato)), min)) {
        min = new Date(avtaleEtablertDato);
    }

    return {
        min: min,
        max: max
    };
};
interface ChooseForsikringProps extends StegProps {}

export function ChooseForsikring({ availableAvtaler, ...props }: ChooseForsikringProps) {
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext<FormState>();

    const { indeks } = useParams<"indeks">();

    const selectedAvtaleIDs = watch("selectedAvtaleIDs");
    const insured = watch("insured")!;
    const insuredName = utils.resolvePersonName(insured);

    const sorterteAvtaler = (availableAvtaler ?? []).sort((a, b) => {
        const nameCompare = a.avtaleNavn.localeCompare(b.avtaleNavn, "no");
        if (nameCompare !== 0) {
            return nameCompare;
        }
        return a.avtaleNummer.localeCompare(b.avtaleNummer, "no");
    });

    const avtalerTilgjengeligForInnmelding = sorterteAvtaler.filter((avtale) => avtale.innmeldingMulig);
    const avtalerIkkeTilgjengeligForInnmelding = sorterteAvtaler.filter((avtale) => !avtale.innmeldingMulig);

    const innmeldteAvtaler = sorterteAvtaler.filter(
        (avtale) => !avtale.innmeldingMulig && avtale.innmeldingStatusKode === 2
    );

    const ingenAvtaler = sorterteAvtaler.length === 0;

    const forGammel = avtalerIkkeTilgjengeligForInnmelding.filter((avtale) => isForGammelForAvtale(insured, avtale));
    const forUng = avtalerIkkeTilgjengeligForInnmelding.filter((avtale) => isForUngForAvtale(insured, avtale));

    const getSelectedAvtaleIDs = useCallback(
        () => (Array.isArray(selectedAvtaleIDs) ? selectedAvtaleIDs : [selectedAvtaleIDs]),
        [selectedAvtaleIDs]
    );

    const getSelectedAvtaleProduktkoder = useCallback(
        () =>
            getSelectedAvtaleIDs()
                .map((avtaleId) => availableAvtaler?.find((avtale) => avtale.avtaleId === avtaleId)?.produktKode)
                .filter(isDefined),
        [availableAvtaler, getSelectedAvtaleIDs]
    );

    const hasOnlyChosenReiseNering = (() => {
        const produktkoder = getSelectedAvtaleProduktkoder();

        return produktkoder.length === 1 && produktkoder.includes(AvtaleProduktID.REISE_NERING);
    })();

    const hasChosenReiseNering = getSelectedAvtaleProduktkoder().includes(AvtaleProduktID.REISE_NERING);

    const onSubmit = (data: FormState) => {
        const avtaler = getSelectedAvtaleIDs()
            .map((avtaleId) => {
                const avtale = availableAvtaler?.find((avtale) => avtale.avtaleId === avtaleId);

                if (!avtale) {
                    return;
                }

                const newAvtale: FormStateAvtale = {
                    avtaleId,
                    avtaleProdukt: {
                        produktkode: avtale.produktKode,
                        avtaleNavn: avtale.avtaleNavn
                    },
                    fagsystem: avtale.fagsystem,
                    arbeidsdyktighetKreves: avtale.innmeldingArbeidsdyktigKreves,
                    epostKreves: avtale.innmeldingEmailKreves,
                    lonnKreves: avtale.innmeldingLonnKreves,
                    minimumLonn: avtale.minimumLonnForInnmeldingOgEndring,
                    innmeldingsDato: constants.DEFAULT_INNMELDING_DATO
                };

                const avtaleGruppe = avtale.avtaleGrupper.find(
                    (gruppe) => gruppe.gruppeId === data.avtaleGrupper?.[avtaleId]
                );

                if (avtaleGruppe) {
                    newAvtale.gruppeId = avtaleGruppe.gruppeId;
                    newAvtale.maximumAlder = avtaleGruppe.maksAlderInnmelding;
                    newAvtale.minimumAlder = avtaleGruppe.minAlderInnmelding;
                    newAvtale.innmeldingsDato = createInnmeldingDato(avtaleGruppe, avtale.avtaleEtablertDato);
                } else if (avtale.avtaleGrupper.length === 1) {
                    newAvtale.gruppeId = avtale.avtaleGrupper[0].gruppeId;
                    newAvtale.maximumAlder = avtale.avtaleGrupper[0].maksAlderInnmelding;
                    newAvtale.minimumAlder = avtale.avtaleGrupper[0].minAlderInnmelding;
                    newAvtale.innmeldingsDato = createInnmeldingDato(
                        avtale.avtaleGrupper[0],
                        avtale.avtaleEtablertDato
                    );
                }

                return newAvtale;
            })
            .filter(isDefined);

        if (hasOnlyChosenReiseNering) {
            const dateFormatted = formatInput(add(new Date(), { days: 1 })) as unknown as Date;
            setValue("innmeldtDato", dateFormatted);
        }

        setValue("avtaler", avtaler);

        props.onFrem();
    };

    useEffect(() => {
        if (avtalerTilgjengeligForInnmelding.length === 1 && getSelectedAvtaleIDs().length === 0) {
            setValue("selectedAvtaleIDs", avtalerTilgjengeligForInnmelding[0].avtaleId);
        }
    }, [setValue, getValues, avtalerTilgjengeligForInnmelding, getSelectedAvtaleIDs]);

    const enableSelectAll = avtalerTilgjengeligForInnmelding.length >= 3;

    const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;

        if (checked) {
            setValue(
                "selectedAvtaleIDs",
                avtalerTilgjengeligForInnmelding.map((avtale) => avtale.avtaleId)
            );
        } else {
            setValue("selectedAvtaleIDs", []);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {avtalerTilgjengeligForInnmelding.length > 0 && (
                    <FieldGroup
                        legend={"Velg forsikring"}
                        className={`choose-forsikring ${enableSelectAll ? "choose-forsikring--select-all" : ""}`}
                        errorLabel={(errors.selectedAvtaleIDs as FieldError | undefined)?.message}
                    >
                        {enableSelectAll && (
                            <Checkbox
                                value="yes"
                                name="select-all"
                                onChange={onSelectAll}
                                checked={avtalerTilgjengeligForInnmelding.length === selectedAvtaleIDs.length}
                            >
                                Velg alle
                            </Checkbox>
                        )}
                        {avtalerTilgjengeligForInnmelding.map((avtale) => (
                            <React.Fragment key={avtale.avtaleId}>
                                <Checkbox
                                    {...register("selectedAvtaleIDs", {
                                        required: model.Feilmelding.VELG_FORSIKRING_INGEN_FORSIKRING
                                    })}
                                    value={avtale.avtaleId}
                                >
                                    {avtale.avtaleNavn}{" "}
                                    <Typography
                                        component="span"
                                        aria-label={`Avtalenummer ${avtale.avtaleId.split("").join(" ")}`}
                                    >
                                        ({avtale.avtaleNummer})
                                    </Typography>
                                </Checkbox>

                                {selectedAvtaleIDs &&
                                    selectedAvtaleIDs.includes(avtale.avtaleId) &&
                                    avtale.avtaleGrupper.length > 1 && (
                                        <RadioButtonGroup
                                            legend="Velg gruppe"
                                            errorLabel={errors.avtaleGrupper?.[avtale.avtaleId]?.message}
                                            className="choose-forsikring__group-selector"
                                            data-testautoid={`meld-inn-velg-gruppe-${avtale.avtaleNummer}`}
                                        >
                                            {avtale.avtaleGrupper.map((avtalegruppe) => (
                                                <RadioButton
                                                    {...register(`avtaleGrupper.${avtale.avtaleId}`, {
                                                        required: "Du må velge en gruppe"
                                                    })}
                                                    label={avtalegruppe.gruppeNavn}
                                                    value={avtalegruppe.gruppeId}
                                                    key={avtalegruppe.gruppeId}
                                                />
                                            ))}
                                        </RadioButtonGroup>
                                    )}
                            </React.Fragment>
                        ))}
                    </FieldGroup>
                )}

                {innmeldteAvtaler.length > 0 && (
                    <p className="jkl-spacing-xl--top jkl-spacing-l--bottom">{insuredName} er allerede meldt inn i</p>
                )}

                <List>
                    {innmeldteAvtaler.map((avtale) => (
                        <CheckListItem key={avtale.avtaleId}>{avtale.avtaleNavn}</CheckListItem>
                    ))}
                </List>

                {hasChosenReiseNering && (
                    <InfoMessageBox className="jkl-spacing-2xl--top" title="Reise næring">
                        Startdato for Reise Næring er automatisk neste arbeidsdag etter i dag.
                    </InfoMessageBox>
                )}

                {!ingenAvtaler && forGammel.length > 0 && (
                    <InfoMessageBox className="jkl-spacing-2xl--top">
                        {insuredName} er for gammel til å bli meldt inn i:
                        <UnorderedList>
                            {forGammel.map((avtale) => (
                                <ListItem key={avtale.avtaleId}>
                                    {`${avtale.avtaleNavn} (${avtale.avtaleId}): ${avtale.innmeldingStatusTekst}`}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </InfoMessageBox>
                )}

                {!ingenAvtaler && forUng.length > 0 && (
                    <InfoMessageBox className="jkl-spacing-2xl--top">
                        {insuredName} er for ung til å meldes inn i:
                        <UnorderedList>
                            {forUng.map((avtale) => (
                                <ListItem key={avtale.avtaleId}>
                                    {`${avtale.avtaleNavn} (${avtale.avtaleId}): ${avtale.innmeldingStatusTekst}`}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </InfoMessageBox>
                )}

                {ingenAvtaler && (
                    <ErrorMessageBox title="Kan ikke meldes inn">
                        {ingenAvtaler ? (
                            `${insuredName} har ikke noen forsikringer tilgjengelig`
                        ) : forUng.length ? (
                            <>
                                {insuredName} er for ung til å meldes inn i:
                                <UnorderedList>
                                    {forUng.map((avtale) => (
                                        <ListItem key={avtale.avtaleId}>
                                            {`${avtale.avtaleNavn}: ${avtale.innmeldingStatusTekst}`}
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </>
                        ) : (
                            forGammel.length && (
                                <>
                                    {insuredName} er for gammel til å bli meldt inn i:
                                    <UnorderedList>
                                        {forUng.map((avtale) => (
                                            <ListItem key={avtale.avtaleId}>
                                                {`${avtale.avtaleNavn}: ${avtale.innmeldingStatusTekst}`}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </>
                            )
                        )}
                    </ErrorMessageBox>
                )}

                <SkjemaFooter>
                    {hasOnlyChosenReiseNering ? (
                        <PrimaryButton
                            data-testid="velgforsikring-meldinn-knapp"
                            dataTestautoid="meld-inn-knapp-velg-forsikring-meldinn"
                            track={{
                                hendelse: Trackingkey.Knappetrykk,
                                knappeId: "meld-inn-knapp-velg-forsikring-meldinn"
                            }}
                        >
                            Meld inn
                        </PrimaryButton>
                    ) : (
                        avtalerTilgjengeligForInnmelding.length > 0 && (
                            <PrimaryButton
                                data-testid="velgforsikring-neste-knapp"
                                dataTestautoid="meld-inn-knapp-velg-forsikring-neste"
                                track={{
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-meld-inn-knapp-velg-forsikring-neste"
                                }}
                            >
                                Neste →
                            </PrimaryButton>
                        )
                    )}

                    {indeks ? (
                        <SecondaryLinkButton to={props.oversiktLink} onClick={() => props.onAvbryt()}>
                            Avbryt
                        </SecondaryLinkButton>
                    ) : (
                        <SecondaryButton
                            type="button"
                            onClick={() => props.onTilbake()}
                            dataTestautoid="meld-inn-knapp-velg-forsikring-forrige"
                            track={{
                                hendelse: Trackingkey.Knappetrykk,
                                knappeId: "meld-inn-knapp-velg-forsikring-forrige"
                            }}
                        >
                            ← Forrige
                        </SecondaryButton>
                    )}
                </SkjemaFooter>
            </form>
        </div>
    );
}

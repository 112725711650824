import React from "react";

import classNames from "classnames";

import "./LinkListe.scss";

interface LinkListeProps {
    heading?: string;
    horizontal?: boolean;
}

export const LinkListe: React.FC<React.PropsWithChildren<LinkListeProps>> = ({
    heading,
    horizontal = false,
    children
}) => {
    // const menuPreference = getPreference("prefersMenu");
    const menuPreference = "mega";

    const classes = classNames("link-liste", {
        "link-liste__horizontal": horizontal,
        "link-liste--with-megameny": menuPreference === "mega"
    });

    return (
        <div className={classes}>
            {heading && <h2>{heading}</h2>}
            <ul>{children}</ul>
        </div>
    );
};

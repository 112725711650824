import { Fragment, useState } from "react";

import { searchByRegex } from "src/common/utils";
import { Typography } from "src/components/Typography";
import { Trackingkey, track } from "src/tracking";

import { SearchField } from "@components/SearchField";
import { components, model } from "@features/faq";
import { Accordion } from "@fremtind/jkl-accordion-react";

import "./FaqList.scss";

interface Props {
    categories: model.FaqSchema[];
}

export function FaqList({ categories }: Props) {
    const [match, setMatch] = useState("");

    const searchFilter = (match: string) => (faqItem: model.FaqSchemaItem) => {
        if (!match) return true;

        //stringify answer object to be able to search in it
        const answerContent = faqItem.answer;
        const answerObj: object = {
            answerContent: answerContent
        };

        const keywords = [`${faqItem.category}, ${JSON.stringify(answerObj)}, ${faqItem.question}`];

        const found = searchByRegex(keywords, match);

        return found;
    };

    const faqFilteredLists = categories.map((category) => ({
        ...category,
        items: category.items.filter(searchFilter(match))
    }));

    function trackFilterChange(arg0: string, arg1: number, arg2: number): void {
        track({ hendelse: Trackingkey.Søk, tekst: arg0, tekstlengde: arg1, treff: arg2 });
    }

    const emptyList: boolean = faqFilteredLists.every((category) => category.items.length === 0);

    return (
        <div className="faqlist">
            <SearchField
                {...{ match, setMatch }}
                placeholder="Søk i listen"
                labelProps={{ srOnly: true }}
                dataTestautoid="faq-table-search-field"
                className="faq-table__filter-søk jkl-spacing-xl--bottom"
                matchedCount={faqFilteredLists.length}
                onBlur={(e) =>
                    trackFilterChange(
                        e.target.value,
                        e.target.value.length,
                        faqFilteredLists.map((category) => category.items).flat().length //TODO: Spørsmål som har distributørkrav telles en gang per distributør.
                    )
                }
            />
            {!emptyList ? (
                faqFilteredLists.map((category) =>
                    category.items.length ? (
                        <Fragment key={category.heading}>
                            <Typography component="h3" variant="heading-4" className="faqlist__heading">
                                {category.heading}
                            </Typography>
                            <Accordion className="faqlist">
                                {category.items.map((item) => (
                                    <components.FaqItem key={item.requiresDistributor + item.question} item={item} />
                                ))}
                            </Accordion>
                        </Fragment>
                    ) : null
                )
            ) : (
                <div className="empty-result">
                    <Typography>Ingen resultater</Typography>
                </div>
            )}
        </div>
    );
}

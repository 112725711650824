import { useForm } from "react-hook-form";
import { AgreementDetails } from "src/model/gen";

import { ModalInstance } from "@fremtind/jkl-modal-react";

import { SkjemaFlyt, Steg, StegProps } from "../../../../../components/SkjemaFlyt";
import { useEndreAvtaledetaljer } from "../agreementchanges.queries";
import { Kvittering } from "./Kvittering";
import { CancelForm } from "./cancel/CancelForm";
import { StandardForm } from "./change/StandardForm";
import { VehicleForm } from "./change/VehicleForm";

export interface FormDataChanges {
    kilometerstand?: string | undefined;
    kjorelengde?: string | undefined;
    annulationcause?: string | undefined;
    sluttdato?: Date | undefined;
    annet?: string | undefined;
    improvements?: string | undefined;
    email: string;
    telefon: string;
    customAnnulationCause?: string;
}

interface Props extends StegProps {
    formType: "standard" | "car";
    formAction: "change" | "cancel";
    avtaleDetaljer: AgreementDetails;
    chosenObject?: string;
    feecbackProps?: any;
    dialogRef?: ModalInstance;
    mutation?: ReturnType<typeof useEndreAvtaledetaljer>;
    onClose?: boolean;
}

export const SkjemaKontroller = (props: Props) => {
    const formMethods = useForm<FormDataChanges>();
    const formName =
        props.formAction === "change" ? (props.formType === "standard" ? StandardForm : VehicleForm) : CancelForm;

    const stegListe: Steg<Props>[] = [
        {
            element: formName,
            stegnavn: "endre-avtale"
        },
        {
            element: Kvittering,
            stegnavn: "kvittering"
        }
    ];

    return (
        <SkjemaFlyt
            flytnavn="forsikringer/legg-til-endringer"
            formMethods={formMethods}
            stegListe={stegListe}
            {...{
                avtaleDetaljer: props.avtaleDetaljer,
                dialogRef: props.dialogRef,
                chosenObject: props.chosenObject,
                mutation: props.mutation,
                onClose: props.onClose
            }}
        />
    );
};

import { NumberBasedAgreementDto, UpdateNumberBasedAgreementRequest } from "src/model/gen";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const queryKey = {
    all: ["antallsbaserteAnsatte"],
    list: (organisasjonsnummer: string) => [...queryKey.all, organisasjonsnummer, "list"]
};

async function requestEndreAntall(body: UpdateNumberBasedAgreementRequest) {
    return http.post<UpdateNumberBasedAgreementRequest>(`antall-baserte-avtaler`, body);
}

export function requestAntallsbaserteAvtaler() {
    return http.get<NumberBasedAgreementDto[]>(`antall-baserte-avtaler`);
}

export function useAntallsbaserteAvtaler() {
    const { valgtOrganisasjon } = useBruker();
    const query = useQuery({
        queryKey: queryKey.list(valgtOrganisasjon!.organisasjonsnummer!),
        queryFn: () => requestAntallsbaserteAvtaler(),
        staleTime: 10000
    });
    return query;
}

export function useEndreAntall() {
    const queryClient = useQueryClient();
    const bruker = useBruker();
    return useMutation({
        mutationFn: (body: UpdateNumberBasedAgreementRequest) => requestEndreAntall(body),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [queryKey.list(bruker.valgtOrganisasjon!.organisasjonsnummer!)]
            });
        }
    });
}

import { useParams } from "react-router-dom";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { TTPNyTilgang } from "../components/TTPNyTilgang";
import { useTilgangerTredjePart } from "../queries";

export const GiTilgangBedriftsansvarlig = () => {
    const query = useTilgangerTredjePart();
    const { fodselsnummer } = useParams<"fodselsnummer">();

    return (
        <Side compact>
            <SideHeader title="Gi tilgang" />
            <SideInnhold loadingText="Henter tilganger" query={query} ignoreError>
                {query.error ? (
                    <ErrorMessageBox title="Oi, litt tekniske problemer her">
                        Vi klarer ikke å hente bedrifter og bedriftsansvarlige akkurat nå. Prøv en gang til eller kom
                        tilbake senere.
                    </ErrorMessageBox>
                ) : (
                    <>
                        {query.data && (
                            <TTPNyTilgang
                                flytnavn="tilgang på bedriftsansvarlig"
                                subject={
                                    query.data.tilganger.kamer
                                        .map(({ kam }) => kam)
                                        .find((kam) => kam.fodselsnummer === fodselsnummer)!
                                }
                                options={query.data.tilganger.bedrifter
                                    .filter(
                                        (bedrift) =>
                                            !bedrift.kamer.some(({ kam }) => kam.fodselsnummer === fodselsnummer)
                                    )
                                    .map(({ bedrift }) => bedrift)}
                                tilganger={query.data.tilganger}
                            />
                        )}
                    </>
                )}
            </SideInnhold>
        </Side>
    );
};

import { Fragment } from "react";

import { toNorwegianDateFormat } from "src/common/formatting";
//import { AgreementField, type AgreementDetails } from "src/model/gen";
import { type AgreementDetails } from "src/model/gen";

import { Typography } from "@components/Typography";
import { utils } from "@features/agreements/avtale.module";
import { formatNumber } from "@fremtind/jkl-formatters-util";

import "./AgreementDetailSection.scss";

type DetaljerProps = {
    agreementDetails: AgreementDetails;
};

export function AgreementDetailSection({ agreementDetails }: Readonly<DetaljerProps>) {
    return (
        <div className="agreement-details__section">
            <Typography variant="heading-2" component="h2">
                Detaljer
            </Typography>
            <dl className="agreement-details__section__details">
                {transformDetails(agreementDetails) //add filter that filters out double values:
                    .filter((v, i, a) => a.findIndex((t) => t.description === v.description) === i)
                    .map(({ description, value, type }, index) => (
                        <Fragment key={index}>
                            <dt className="agreement-details__section__details__term">{description}</dt>
                            <dd className="agreement-details__section__details__detail">
                                {utils.formatValue(value, type)}
                            </dd>
                        </Fragment>
                    ))}
            </dl>
        </div>
    );
}

function returnMilage(value: string | undefined) {
    if (value && value !== "Ubegrenset") {
        return `${formatNumber(value.replace(/\D/g, ""))} km`;
    } else {
        return value;
    }
}

function transformDetails(detaljer: AgreementDetails): Array<any> {
    const detailObjects = detaljer.objects;
    const fields = detailObjects.map((o) => o.fields);

    const getFieldValue = (fieldLabel: string) => {
        return fields.flat().find((f) => f.description === fieldLabel)?.value;
    };

    return [
        {
            description: "Kjørelengde",
            value: getFieldValue("Kjørelengde"),
            type: "STRING"
        },
        {
            description: `Kilometerstand \n(${getFieldValue("Siste avlesning") && toNorwegianDateFormat(getFieldValue("Siste avlesning"))})`,
            value: getFieldValue("Kilometerstand")
                ? returnMilage(getFieldValue("Kilometerstand"))
                : getFieldValue("Km.stand")
                  ? returnMilage(getFieldValue("Km.stand"))
                  : "",
            type: "STRING"
        },
        {
            description: "Bonus",
            value: getFieldValue("Bonus"),
            type: "STRING"
        }
        /*{
            description: "Bruksområde",
            value: getFieldValue("Bruksområde"),
            type: "STRING"
        }*/
    ];
}

import { LoaderFunction } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";
import type { ClaimOverview } from "src/model/gen";

import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { claimsListQuery } from "@features/claims/claims.queries";

export const claimsDetailsLoader: LoaderFunction = async ({ params }) => {
    const { claimId } = params;

    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker.gjeldendeOrganisasjonsnummer && claimId) {
        const dataFetcher = await claimsListQuery();
        const data = dataFetcher as unknown as Array<ClaimOverview>;
        const claim = data.find((claim: ClaimOverview) => claim.claimNumber.toString() === claimId);
        return claim;
    }

    return Promise.resolve();
};

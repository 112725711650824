import { useFormContext } from "react-hook-form";

import { TextInput } from "@fremtind/jkl-text-input-react";
import { isValidEpost, isValidTelefonnummer } from "@fremtind/jkl-validators-util";

import { FormDataChanges } from "./forms/SkjemaKontroller";

export const ContactInfoInputs = () => {
    const { register, formState } = useFormContext<FormDataChanges>();
    const { errors } = formState;

    return (
        <div className="input-fields-horizontal">
            <TextInput
                className="email-input"
                {...register("email", {
                    required: `Du må oppgi e-post`,
                    validate: (value) => (isValidEpost(value) ? true : "Ugyldig e-post")
                })}
                data-testautoid="meldinn-merinfo-epost"
                label={`E-post`}
                labelProps={{ variant: "medium" }}
                placeholder="E-post"
                errorLabel={errors.email?.message}
            />
            <TextInput
                {...register("telefon", {
                    required: `Du må oppgi telefonnummer`,
                    validate: (value) => (isValidTelefonnummer(value) ? true : "Ugyldig mobilnummer")
                })}
                data-testautoid="meldinn-merinfo-telefon"
                label={`Telefonnummer`}
                placeholder="Telefonnummer"
                errorLabel={errors.telefon?.message}
                labelProps={{ variant: "medium" }}
            />
        </div>
    );
};

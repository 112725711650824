import { useMemo, useReducer } from "react";

import { Trackingkey, track } from "src/tracking";

import { useAgreementList } from "@features/agreements/agreements.queries";

import { PrimaryLinkButton, SecondaryLinkButton } from "../../components/Button";
import { Side, SideHeader, SideInnhold } from "../../components/Side";
import { useBruker } from "../../features/bruker/queries";
import { KortMode, TilgangerKort } from "../../features/tilganger/components/TilgangerKort";
import { OrganisasjonTilgang, PersonTilgang, Tilganger } from "../../features/tilganger/models";
import { useGetTilganger } from "../../features/tilganger/queries";
import "./TilgangerOversikt.scss";

interface AktivtKort {
    id: string;
    mode: KortMode;
}

const initialState: AktivtKort = { id: "", mode: null };

// reducer for å unngå race condition mellom click outside og klikk på et nytt kort
const reducer = (state: AktivtKort, action: AktivtKort) => {
    if (action.id && action.mode === null && state?.id && action.id !== state.id) {
        return state;
    }

    if (action.mode === null) {
        return initialState;
    }

    return action;
};

interface InnholdProps {
    data: Tilganger;
    harAktivAvtale: boolean;
}

const Innhold = ({ data, harAktivAvtale }: InnholdProps) => {
    const sorterObjektNavn = (d: any) => {
        return d.sort((a: any, b: any) => {
            if ("organisasjon" in a) {
                return a.organisasjon.navn.localeCompare(b.organisasjon.navn);
            } else if ("person" in a) {
                return a.person.fornavn.localeCompare(b.person.fornavn);
            }
        });
    };

    const [aktivtKort, dispatch] = useReducer(reducer, initialState);

    const setAktivtKort = (kort: AktivtKort) => {
        dispatch(kort);
    };

    // finn en multiplikator for høyden til innholdet i et kort
    // dette er enten flest roller eller 3 rader
    const kortHoydePerson = useMemo(
        () =>
            data.personTilganger.reduce((acc, { roller }) => {
                if (roller.length > acc && roller.length < 3) {
                    return roller.length;
                } else {
                    return acc;
                }
            }, 0),
        [data.personTilganger]
    );

    const kortHoydeOrg = useMemo(
        () =>
            data.organisasjonsTilganger.reduce((acc, { roller }) => {
                if (roller.length > acc && roller.length < 3) {
                    return roller.length;
                } else {
                    return acc;
                }
            }, 0),
        [data.organisasjonsTilganger]
    );

    const visOrganisasjonSeksjon = useMemo(() => {
        if (!harAktivAvtale) {
            return !!data.organisasjonsTilganger.length;
        }

        return harAktivAvtale;
    }, [harAktivAvtale, data.organisasjonsTilganger]);

    track({
        hendelse: Trackingkey.SeTilganger,
        type: "egen organisasjon",
        personer: data.personTilganger.length,
        organisasjoner: data.organisasjonsTilganger.length
    });

    return (
        <>
            <div className="tilganger-oversikt__button-group">
                <PrimaryLinkButton
                    to="/tilganger/gi-tilgang/person"
                    data-testautoid="tilgang-til-person"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "gi-tilgang-til-person"
                        });
                    }}
                >
                    Gi tilgang til person
                </PrimaryLinkButton>
                {harAktivAvtale && (
                    <SecondaryLinkButton
                        to="/tilganger/gi-tilgang/bedrift"
                        data-testautoid="tilgang-til-bedrift"
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.LenkeTrykk,
                                lenkeId: "gi-tilgang-til-bedrift"
                            });
                        }}
                    >
                        Gi tilgang til annen bedrift
                    </SecondaryLinkButton>
                )}
            </div>

            <section>
                <h2 className="jkl-heading-3">Personer</h2>
                <p className="jkl-body jkl-spacing-xl--bottom jkl-spacing-m--top">
                    Alle enkeltpersoner med tilgang til bedriften. Administratorer er de eneste som kan gi tilganger og
                    samtykke på vegne av bedriften.
                </p>
                <div
                    className={`tilganger-oversikt__tilgang-liste ${
                        visOrganisasjonSeksjon ? "tilganger-oversikt__tilgang-liste--luftig" : ""
                    }`}
                >
                    {sorterObjektNavn(data.personTilganger).map((person: PersonTilgang) => (
                        <TilgangerKort
                            key={person.id}
                            objektType="person"
                            rolleRader={kortHoydePerson}
                            tilgangsObjekt={person}
                            apentKort={aktivtKort?.id === person.id}
                            mode={aktivtKort?.mode}
                            onClick={(mode: KortMode) => setAktivtKort({ id: person.id, mode })}
                            tilgjengeligePersonTilganger={data.tilgjengeligePersonTilganger}
                            tilgjengeligeOrganisasjonsTilganger={data.tilgjengeligeOrganisasjonsTilganger}
                        />
                    ))}
                </div>
            </section>

            {visOrganisasjonSeksjon && (
                <section>
                    <h2 className="jkl-heading-3">Bedrifter</h2>

                    <p className="jkl-body jkl-spacing-xl--bottom jkl-spacing-m--top tilgang__ingress">
                        Bedrifter som har tilgang til å se og endre dine forsikringer.
                    </p>
                    <div className="tilganger-oversikt__tilgang-liste">
                        {sorterObjektNavn(data.organisasjonsTilganger).map((organisasjon: OrganisasjonTilgang) => (
                            <TilgangerKort
                                key={organisasjon.organisasjon.organisasjonsnummer}
                                objektType="organisasjon"
                                rolleRader={kortHoydeOrg}
                                tilgangsObjekt={organisasjon}
                                apentKort={aktivtKort?.id === organisasjon.organisasjon.organisasjonsnummer}
                                mode={aktivtKort?.mode}
                                onClick={(mode: KortMode) =>
                                    setAktivtKort({ id: organisasjon.organisasjon.organisasjonsnummer, mode })
                                }
                                tilgjengeligePersonTilganger={data.tilgjengeligePersonTilganger}
                                tilgjengeligeOrganisasjonsTilganger={data.tilgjengeligeOrganisasjonsTilganger}
                            />
                        ))}
                    </div>
                </section>
            )}
        </>
    );
};

export const TilgangerOversikt = () => {
    const tilgangerQuery = useGetTilganger();
    const { valgtOrganisasjon } = useBruker();
    const avtalerQuery = useAgreementList();
    const harAktivAvtale = avtalerQuery.data?.agreements.length ? true : false;

    return (
        <Side compactTextOnly>
            <SideHeader
                title={`Tilganger \u2013 ${valgtOrganisasjon?.navn}`}
                lead="Velg hvem som skal ha tilgang til å se, endre og kjøpe forsikringer i din bedrift."
            />
            <SideInnhold loadingText="Henter tilganger" query={tilgangerQuery}>
                <Innhold data={tilgangerQuery.data!} harAktivAvtale={harAktivAvtale} />
            </SideInnhold>
        </Side>
    );
};

import React from "react";

import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { Trackingkey } from "../../../../tracking";
import { useDeleteTilganger } from "../../queries";
import "./TilgangerFjern.scss";

type ObjektType = "person" | "organisasjon";
interface Props {
    avbryt: (event: React.MouseEvent) => void;
    bekreft: () => void;
    objektType: ObjektType;
    person?: string;
    organisasjon?: string;
    mutation: ReturnType<typeof useDeleteTilganger>;
}

export function TilgangerFjern({ avbryt, bekreft, objektType, person, organisasjon, mutation }: Props) {
    return (
        <>
            <p className="tilgang-kort--fjern__ingress">
                {objektType === "person"
                    ? `Hvis du fjerner tilgangen mister ${person} alle tilganger til bedriftens forsikringer.`
                    : ` Hvis du fjerner tilgangen mister ${organisasjon} alle tilganger til din bedrifts forsikringer.`}
            </p>

            <footer className="tilgang-kort--fjern__knapper">
                <SecondaryButton
                    density="compact"
                    onClick={avbryt}
                    className="jkl-spacing-m--right"
                    data-testid="tilganger-fjern-avbryt"
                    dataTestautoid="tilganger-fjern-avbryt"
                    track={{
                        hendelse: Trackingkey.Knappetrykk,
                        knappeId: "tilganger-fjern-avbryt"
                    }}
                >
                    Avbryt
                </SecondaryButton>
                <PrimaryButton
                    density="compact"
                    onClick={bekreft}
                    dataTestautoid={`tilganger-fjern-${objektType}-bekreft`}
                    data-testid={`tilganger-fjern-${objektType}-bekreft`}
                    loader={{ showLoader: mutation.isPending, textDescription: "Fjerner tilgang" }}
                    track={[
                        {
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: `tilganger-fjern-${objektType}-bekreft`
                        },
                        { hendelse: Trackingkey.Tilgang, handling: "fjern", tilgang: objektType }
                    ]}
                >
                    Bekreft fjerning
                </PrimaryButton>
            </footer>
        </>
    );
}

import { useMemo } from "react";

import { Link, matchRoutes, useLocation, useMatch } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "@fremtind/jkl-breadcrumb-react";

import { useBruker } from "../../features/bruker/queries";
import { breadcrumbRoutes } from "../../sider";
import { Trackingkey, track } from "../../tracking";
import "./BreadCrumbs.scss";

export const BreadCrumbs = () => {
    const location = useLocation();
    const { valgtOrganisasjon } = useBruker();

    const isOrganisasjonsbytte = useMatch("/bytt-organisasjon/:orgnr");

    const matches = useMemo(() => {
        const routeMatches = (matchRoutes(breadcrumbRoutes({ valgtOrganisasjon }), location) ?? [])
            .filter((m) => !!m.route.element)
            .filter((m) => m.route.index !== true);

        return [{ pathname: "/hjem", route: { element: "Hjem" } }, ...routeMatches];
    }, [location, valgtOrganisasjon]);

    if (isOrganisasjonsbytte) {
        return null;
    }

    return (
        <Breadcrumb className="fbm-breadcrumb">
            {matches.map((match) => (
                <BreadcrumbItem key={match.pathname}>
                    <Link
                        to={match.pathname}
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.LenkeTrykk,
                                lenkeId: match.pathname,
                                type: "brødsmulesti"
                            });
                        }}
                    >
                        {match.route.element}
                    </Link>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};

import { Suspense, useEffect, useState } from "react";

import { Await, useLoaderData } from "react-router-dom";
import { type ClaimOverview, AgreementDetails as IAgreementDetails } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { Side, SideInnhold } from "@components/Side";
import { useClaimsList } from "@features/claims/claims.queries";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorBoundary } from "@sentry/react";

import { AgreementDetailsHeader } from "../../components";
import { NewAgreementDetailsPreview } from "../../preview/views/AgreementDetailsPreview";
import { AgreementDetails } from "./AgreementDetails";

interface LoaderData {
    agreementDetailsResponse: IAgreementDetails;
}

export const AgreementDetailsComposer = () => {
    const agreementDetailsFetcher = useLoaderData() as LoaderData;
    const agreementDetails = agreementDetailsFetcher.agreementDetailsResponse;
    const [registeredMixpanelEvent, setRegisteredMixpanelEvent] = useState(false);
    const { data } = useClaimsList();

    const onGoingClaims = ((data as unknown as ClaimOverview[]) ?? []).filter(
        (d) => d.agreementNumber.includes(agreementDetails.agreementId) && d.status !== "Avsluttet"
    );

    useEffect(() => {
        if (!registeredMixpanelEvent) {
            setRegisteredMixpanelEvent(true);

            track({
                hendelse: Trackingkey.SeAvtaledetaljer,
                produkt: agreementDetails.productCode,
                kategori: agreementDetails.category,
                forsikringstype: agreementDetails.groupInsuranceType,
                kanEndres: agreementDetails.changeable,
                startdato: agreementDetails.startDate,
                sluttdato: agreementDetails.endDate,
                coreSystem: agreementDetails.coreSystem,
                objekter: agreementDetails.objects.map(
                    (o, index) => o.name ?? `Objekt ${index + 1} av ${agreementDetails.objects.length}`
                ),
                status: agreementDetails.status,
                nyAvtaleTilgjengelig: agreementDetails.nextVersion !== undefined,
                skadesaker: onGoingClaims.length,
                sistOppdatert: agreementDetails.lastUpdated
            });
        }
    }, [agreementDetails, onGoingClaims, registeredMixpanelEvent]);

    return (
        <Side>
            <Suspense fallback={<Loader textDescription="Henter forsikringer" />}>
                <Await resolve={agreementDetailsFetcher} errorElement={<ErrorBoundary />}>
                    {(data: LoaderData) =>
                        //check if the agreements productoce is V05 And show the new component:
                        data.agreementDetailsResponse.productCode === "V05" ? (
                            <NewAgreementDetailsPreview agreementDetails={data.agreementDetailsResponse} />
                        ) : (
                            <>
                                <AgreementDetailsHeader agreementDetails={data.agreementDetailsResponse} />
                                <SideInnhold hideFooter>
                                    <AgreementDetails agreementDetails={data.agreementDetailsResponse} />
                                </SideInnhold>
                            </>
                        )
                    }
                </Await>
            </Suspense>
        </Side>
    );
};

import { useFormContext } from "react-hook-form";

import { Toast } from "@components/Toast";
import { PrimaryButton, SecondaryButton } from "@fremtind/jkl-button-react";
import { Loader } from "@fremtind/jkl-loader-react";

import { SigneringFormData, SigneringStegProps } from ".";
import { isRadgiver } from "../../../common/rolle";
import { SkjemaFooter } from "../../../components/SkjemaFooter";
import { Typography } from "../../../components/Typography";
import { useBruker } from "../../bruker/queries";
import { RRHExpandableLovkrav } from "../../rrh/components/RRHExpandableLovkrav";
import { ReelleRettighetshavereTabell } from "../../rrh/components/ReelleRettighetshavereContent";
import { useReelleRettighetshavere } from "../../rrh/queries";
import { useStartSignering } from "../queries";

export const RRH = ({ onFrem, onTilbake: onBack }: SigneringStegProps) => {
    const query = useReelleRettighetshavere();
    const mutation = useStartSignering();
    const form = useFormContext<SigneringFormData>();
    const { bruker } = useBruker();
    const brukerIsRadgiver = isRadgiver(bruker);

    const onSubmit = (data: SigneringFormData) => {
        mutation.mutate(data.tilbud, {
            onSuccess: (res) => {
                form.setValue("signering", res.entity);
                onFrem();
            }
        });
    };

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Typography variant="heading-1" component="h2" className="jkl-spacing-m--bottom">
                Reelle rettighetshavere
            </Typography>

            <Typography className="jkl-spacing-m--bottom">
                For å signere tilbud må du først bekrefte reelle rettighetshavere. Bekreft at listen nedenfor er korrekt
                og fullstendig.
            </Typography>

            <RRHExpandableLovkrav closedText="Les mer om reelle rettighetshavere" className="jkl-spacing-xl--bottom" />

            {!query.data ? (
                <Loader textDescription="Henter reelle rettighetshavere" />
            ) : (
                <>
                    <ReelleRettighetshavereTabell rows={query.data.reelleRettighetshavere} />

                    <Typography variant="heading-2" className="jkl-spacing-xs--bottom jkl-spacing-2xl--top">
                        Listen er korrekt
                    </Typography>

                    <Typography>
                        Ved å trykke &quot;Neste&quot; bekrefter jeg at listen med reelle rettighetshavere ovenfor er
                        korrekt og fullstendig.
                    </Typography>

                    <Toast
                        open={mutation.error?.status === 403 && brukerIsRadgiver}
                        onClose={mutation.reset}
                        severity="error"
                        title="Kan ikke signere"
                    >
                        Rådgiver kan ikke signere tilbud. Kunden må signere dette selv.
                    </Toast>

                    <SkjemaFooter>
                        <PrimaryButton
                            loader={{ showLoader: mutation.isPending, textDescription: "Starter signering" }}
                            role="button"
                            data-testid="gruppeliv-rrh-signering"
                        >
                            Neste
                        </PrimaryButton>

                        <SecondaryButton type="button" onClick={() => onBack()}>
                            Tilbake
                        </SecondaryButton>
                    </SkjemaFooter>
                </>
            )}
        </form>
    );
};

import { useEffect, useState } from "react";

import { isSafari } from "react-device-detect";
import { FieldError, useForm } from "react-hook-form";

import { PrimaryButton, SecondaryButton } from "@fremtind/jkl-button-react";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { Link } from "@fremtind/jkl-core";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";

import * as utils from "./utils";
import { LenkeId, useExternalLinks } from "../../common/lenker";
import { PrimaryLinkButton, SecondaryLinkButton } from "../../components/Button";
import { Kvittering } from "../../components/Kvittering";
import { Side, SideHeader, SideInnhold } from "../../components/Side";
import { SkjemaFooter } from "../../components/SkjemaFooter";
import { Toast } from "../../components/Toast";
import { Typography } from "../../components/Typography";
import { useAnsattbrosjyreAvtaler, useAnsattbrosjyrePdf } from "../../features/agreements/avtale.queries";
import { Trackingkey, track } from "../../tracking";

const getFirstError = (errors: FieldError | FieldError[] | undefined) => {
    if (Array.isArray(errors)) {
        return errors[0];
    }

    return errors;
};

export interface AnsattbrosjyreFormData {
    checked: Partial<AnsattBrosjyreAvtaleRequest>[];
}

// denne må endres til EmployeeBrochureAgreementRequest og EmployeeBrochureProduct
export interface AnsattBrosjyreAvtaleRequest {
    productId?: string;
    productName?: string;
    agreementId?: string | undefined;
    objectId?: string | undefined;
    objects?: Array<any>;
}

export function Ansattbrosjyre() {
    const { links } = useExternalLinks();

    const query = useAnsattbrosjyreAvtaler();

    const { watch, getValues, setValue, handleSubmit, register, formState, reset } = useForm<AnsattbrosjyreFormData>({
        defaultValues: {
            checked: []
        }
    });

    const mutation = useAnsattbrosjyrePdf();

    const [allChecked, setAllChecked] = useState(false);

    const [error, setError] = useState("");

    const checked = watch("checked");

    // Uncheck "Velg alle" if some checkbox gets unchecked after checking "Velg alle"
    useEffect(() => {
        const subscription = watch(({ checked }) => {
            if (checked) {
                if (allChecked && checked.filter((val) => val?.productId).length !== query.data?.length) {
                    setAllChecked(false);
                }
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [allChecked, query.data?.length, watch]);

    function onCheckAll() {
        if (allChecked) {
            setValue("checked", getValues().checked.map(utils.uncheck));
            setAllChecked(false);
        } else {
            setValue("checked", query.data!.map(utils.check));
            setAllChecked(true);
        }
    }

    function onSubmit(data: AnsattbrosjyreFormData) {
        if (utils.someChecked(data)) {
            mutation.mutate(utils.onlyChecked(data), {
                // download the pdf when the mutation is successful:

                onSuccess: () => {
                    track({
                        hendelse: Trackingkey.LastNed,
                        dokumenttype: "ansattbrosjyre",
                        enkelt: true
                    });
                }
            });
        } else {
            setError("Du må velge minst én avtale.");
        }
    }

    const pensjonHref = links.find((link) => link.lenkeId === LenkeId.pensjon)?.href;

    return (
        <Side>
            <SideHeader
                title="Ansattbrosjyre"
                lead={
                    <span>
                        Her kan du lage en brosjyre for å informere dine ansatte om hvilke forsikringer de har. For å
                        lage brosjyre for pensjonsavtaler må du gå til{" "}
                        <Link
                            external
                            target="_blank"
                            onClick={() => {
                                track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: "ansattbrosjyre-pensjon" });
                            }}
                            href={pensjonHref}
                        >
                            kundeportal pensjon
                        </Link>
                    </span>
                }
            />
            <SideInnhold query={query}>
                {mutation.isSuccess ? (
                    <Kvittering
                        heading="Flott!"
                        lead="Ansattbrosjyren inneholder produktene:"
                        positives={utils.getKvitteringPositives(query.data!, getValues())}
                        spacedBottom
                        hidePayment
                    >
                        {isSafari && (
                            <div style={{ width: "100%", marginTop: "-24px" }}>
                                <InfoMessageBox className="jkl-spacing-l--bottom" title="Ser du ikke brosjyren?">
                                    Da kan årsaken være at nettleseren din (Safari) blokkerer åpning av nye faner
                                    (pop-ups). Dette må i så fall godkjennes i nettleserinnstillingene. <br />
                                    <Link
                                        external={true}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://support.apple.com/en-us/102524#:~:text=Check%20Safari%20settings&text=On%20your%20Mac%2C%20open%20Safari,some%20or%20all%20pop%2Dups."
                                    >
                                        Les mer om hvordan du godkjenner pop-ups i Safari
                                    </Link>
                                    <br />
                                    <br />
                                    Alternativt kan du bruke en annen nettleser for å åpne brosjyren.
                                </InfoMessageBox>
                            </div>
                        )}
                        <PrimaryLinkButton to="/hjem">Ferdig</PrimaryLinkButton>

                        <SecondaryButton
                            onClick={() => {
                                mutation.reset();
                                reset();
                            }}
                        >
                            Ny brosjyre
                        </SecondaryButton>
                    </Kvittering>
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="heading-1" className="jkl-spacing-m--bottom">
                            Velg avtaler
                        </Typography>

                        <Typography className="jkl-spacing-xl--bottom">
                            Velg forsikringsavtalene du ønsker å ha med i brosjyren.
                        </Typography>

                        <FieldGroup legend="Velg" errorLabel={error}>
                            <Checkbox
                                name="Velg alle"
                                value="not important"
                                checked={allChecked}
                                onChange={() => onCheckAll()}
                            >
                                Velg alle
                            </Checkbox>

                            {query.data?.map(
                                (produkt: AnsattBrosjyreAvtaleRequest, index) =>
                                    produkt.productId && (
                                        <div key={produkt.productId}>
                                            <Checkbox
                                                value={produkt.productId}
                                                className="jkl-spacing-xl--left"
                                                {...register(`checked.${index}.productId`, {
                                                    onChange: (e: any) => {
                                                        if (
                                                            e.target.checked &&
                                                            produkt.objects &&
                                                            produkt.objects.length === 1
                                                        ) {
                                                            const gruppe = produkt.objects[0];
                                                            setValue(`checked.${index}.objectId`, gruppe.objectId);
                                                            setValue(
                                                                `checked.${index}.agreementId`,
                                                                gruppe.agreementId
                                                            );
                                                        }
                                                    }
                                                })}
                                            >
                                                {produkt.productName}
                                            </Checkbox>

                                            {checked[index]?.productId &&
                                                produkt.objects &&
                                                produkt.objects.length > 1 && (
                                                    <RadioButtonGroup
                                                        legend="Velg gruppe"
                                                        labelProps={{ variant: "small" }}
                                                        key={checked[index]?.productId}
                                                        className="jkl-spacing-2xl--left jkl-spacing-m--top jkl-spacing-xs--bottom"
                                                        errorLabel={
                                                            getFirstError(formState.errors?.checked?.[index]?.objectId)
                                                                ?.message
                                                        }
                                                    >
                                                        {produkt.objects.map((gruppe) =>
                                                            isSafari ? (
                                                                <RadioButton
                                                                    {...register(`checked.${index}.objectId`, {
                                                                        required: "Du må velge en gruppe"
                                                                    })}
                                                                    key={`${gruppe.agreementId}-${gruppe.objectId}`}
                                                                    data-testid="ansattbrosjyre__gruppe--radio"
                                                                    label={
                                                                        <span>
                                                                            {gruppe.objectName ??
                                                                                `Gruppe ${gruppe.objectId}`}
                                                                            <Typography component="span">
                                                                                {" "}
                                                                                ({gruppe.agreementId})
                                                                            </Typography>
                                                                        </span>
                                                                    }
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setValue(
                                                                                `checked.${index}.agreementId`,
                                                                                gruppe.agreementId
                                                                            );
                                                                            if (isSafari) {
                                                                                setValue(
                                                                                    `checked.${index}.objectId`,
                                                                                    gruppe.objectId
                                                                                );
                                                                            }
                                                                        }
                                                                    }}
                                                                    checked={
                                                                        checked[index]?.agreementId ===
                                                                            gruppe.agreementId &&
                                                                        checked[index]?.objectId === gruppe.objectId
                                                                    }
                                                                    value={gruppe.objectName ?? gruppe.objectId}
                                                                />
                                                            ) : (
                                                                <RadioButton
                                                                    {...register(`checked.${index}.objectId`, {
                                                                        required: "Du må velge en gruppe"
                                                                    })}
                                                                    key={`${gruppe.agreementId}-${gruppe.objectId}`}
                                                                    data-testid="ansattbrosjyre__gruppe--radio"
                                                                    label={
                                                                        <span>
                                                                            {gruppe.objectName ??
                                                                                `Gruppe ${gruppe.objectId}`}
                                                                            <Typography component="span">
                                                                                {" "}
                                                                                ({gruppe.agreementId})
                                                                            </Typography>
                                                                        </span>
                                                                    }
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setValue(
                                                                                `checked.${index}.agreementId`,
                                                                                gruppe.agreementId
                                                                            );
                                                                        }
                                                                    }}
                                                                    value={gruppe.objectId}
                                                                />
                                                            )
                                                        )}
                                                    </RadioButtonGroup>
                                                )}
                                        </div>
                                    )
                            )}
                        </FieldGroup>

                        <SkjemaFooter>
                            <SecondaryLinkButton to="/hjem">Avbryt</SecondaryLinkButton>
                            <PrimaryButton
                                data-testid="ansattbrosjyre-apne-pdf"
                                loader={{ showLoader: mutation.isPending, textDescription: "Laster inn PDF" }}
                            >
                                Åpne PDF
                            </PrimaryButton>
                        </SkjemaFooter>
                    </form>
                )}

                <Toast open={mutation.isError} onClose={mutation.reset} severity="error" title="Noe gikk galt">
                    Vi klarte ikke hente ansattbrosjyren din.
                </Toast>
            </SideInnhold>
        </Side>
    );
}

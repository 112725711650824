import { Suspense, useRef } from "react";

import { Await, useLoaderData } from "react-router-dom";

import { Dialog } from "@components/Dialog";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { Typography } from "@components/Typography";
import { Link } from "@fremtind/jkl-core";
import { ListItem, OrderedList } from "@fremtind/jkl-list-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { ModalInstance } from "@fremtind/jkl-modal-react";

import { VehiclesComposer } from "./VehiclesComposer";
import { VehiclesLoader } from "./VehiclesLoader";
import { VehicleListResponse } from "./loader";

const ErrorBoundary = () => {
    return (
        <ErrorMessageBox title="Oi, litt tekniske problemer her">
            Vi klarer ikke hente kjøretøyene dine. Prøv en gang til eller kom tilbake senere.
        </ErrorMessageBox>
    );
};

export const Vehicles = () => {
    const dialogRef = useRef<ModalInstance | null>();
    const data = useLoaderData() as { vehiclesResponse: VehicleListResponse };

    return (
        <Side className="agreement-overview">
            <SideHeader
                title="Kjøretøy"
                lead={
                    <Typography>
                        Her kan du se en oversikt over alle kjøretøyene som er meldt inn på flåteavtalene dine. Kjøretøy
                        kan ikke fjernes manuelt.{" "}
                        <Link
                            tabIndex={0}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                dialogRef.current.show();
                            }}
                            onKeyDown={(event: any) => event.key === "Enter" && dialogRef.current.show()}
                        >
                            Les mer om fjerning av kjøretøy.
                        </Link>
                    </Typography>
                }
            ></SideHeader>
            <SideInnhold>
                <Suspense fallback={<VehiclesLoader />}>
                    <Await resolve={data.vehiclesResponse} errorElement={<ErrorBoundary />}>
                        {(data: VehicleListResponse) => (
                            <>
                                <VehiclesComposer vehicleLists={data} />
                                <Dialog
                                    title="Du kan ikke fjerne biler fra forsikringsavtalen din selv. De kan bare fjernes på disse tre måtene:"
                                    dialogRef={(instance) => {
                                        dialogRef.current = instance;
                                    }}
                                    onConfirm={() => {
                                        dialogRef.current?.hide();
                                    }}
                                    onCancel={() => {
                                        dialogRef.current?.hide();
                                    }}
                                >
                                    <OrderedList>
                                        <ListItem>
                                            <b>Salg</b>
                                            <br />
                                            Forsikringen avsluttes automatisk på salgsdato når salgsmelding er
                                            registrert hos Statens Vegvesen.
                                        </ListItem>
                                        <ListItem>
                                            <b>Avregistrering</b>
                                            <br />
                                            Forsikringen avsluttes automatisk ved avregistrering eller hvis bilen
                                            vrakes.
                                        </ListItem>
                                        <ListItem>
                                            <b>Bytte forsikringsselskap</b>
                                            <br />
                                            Hvis du forsikrer bilen hos et annet selskap, vil det andre selskapet
                                            avslutte forsikringen hos oss.
                                        </ListItem>
                                    </OrderedList>
                                </Dialog>
                            </>
                        )}
                    </Await>
                </Suspense>
            </SideInnhold>
        </Side>
    );
};

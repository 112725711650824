import { LenkeId, useExternalLinks } from "src/common/lenker";
import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { Link } from "@fremtind/jkl-core";

export const ClaimsFAQ = () => {
    const meldSkadeLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    const faq = [
        {
            question: "Hvordan melder jeg en ny skadesak?",
            answer: meldSkadeLink ? (
                <p>
                    Du kan melde en{" "}
                    <Link href={meldSkadeLink} external target="_blank" rel="noopener noreferrer">
                        ny skadesak her
                    </Link>
                    . Sakene sendes gjennom et annet system, men vil dukke opp her når de er sendt, og gir deg status på
                    saken.
                </p>
            ) : (
                "Du kan melde en ny skadesak på nettsiden vår. Sakene sendes gjennom et annet system"
            )
        },
        {
            question: "Hvordan vet jeg når saken min oppdateres seg?",
            answer: "Du vil få SMS eller epost når det skjer noe. Ønsker du mer informasjon kan du svare på eposten du fikk da du sendte saken."
        },
        {
            question: "Hvor lang behandlingstid er det på skadesaken min?",
            answer: "Det er vanskelig å si noe spesifikt om behandlingstider ved skadesaker fordi de kan være veldig komplekse. Hvis du ikke har hørt noe på en stund kan du svare på eposten du fikk da du sendte saken."
        },
        {
            question: "Hvordan ser jeg alle skadesakene på ansattprodukter?",
            answer: "Av personvernhensyn kan du ikke se detaljer om skadesaker på ansattprodukter."
        }
    ];

    return (
        <Accordion>
            {faq.map((qa) => (
                <AccordionItem
                    key={qa.question}
                    title={qa.question}
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.Utvid,
                            id: qa.question
                        });
                    }}
                >
                    <Typography variant="body">{qa.answer}</Typography>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

import React from "react";

import classNames from "classnames";

import { IHttpError } from "../../model/http.typer";
import { Feil } from "../Feil/Feil";
import { IngenBedriftFeil } from "../Feil/IngenBedriftFeil/IngenBedriftFeil";

export interface FeilWrapperProps {
    errorRedirectTo?: string;
    className?: string;
    error?: IHttpError;
    illustrasjon?: boolean;
}

export const FeilWrapper: React.FC<React.PropsWithChildren<FeilWrapperProps>> = ({
    className,
    errorRedirectTo,
    error,
    illustrasjon = true,
    children
}) => {
    const classes = classNames(className);

    if (error) {
        return <Feil error={error} redirectOnOk={errorRedirectTo} illustrasjon={illustrasjon} />;
    }

    return (
        <div className={classes}>
            <IngenBedriftFeil />
            {children}
        </div>
    );
};

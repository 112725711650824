import { Typography } from "@components/Typography";
import { Link } from "@fremtind/jkl-core";
import { formatValuta } from "@fremtind/jkl-formatters-util";

import { InvoiceDto } from "../../../../model/gen";
import { UnpaidInvoiceCard } from "./UnpaidInvoiceCard";
import "./UnpaidInvoiceOverview.scss";

interface Props {
    unpaidInvoices: InvoiceDto[];
}

export const UnpaidInvoiceOverview = ({ unpaidInvoices }: Props) => {
    const totalOutstandingSum = unpaidInvoices.reduce((sum, invoice) => {
        return (sum += invoice.belop);
    }, 0);

    return (
        <div className="bm-invoice-overview__unpaid-invoices">
            <Typography component="h2" variant="heading-3">
                Ubetalte fakturaer
            </Typography>
            {unpaidInvoices.length !== 0 && (
                <Typography>
                    {totalOutstandingSum < 0 ? "Beløp til gode" : "Utestående beløp"}:{" "}
                    {formatValuta(Math.abs(totalOutstandingSum))}
                </Typography>
            )}
            {unpaidInvoices.length > 0 ? (
                <ul className="bm-unpaid-invoices-list">
                    {unpaidInvoices.map((invoice) => (
                        <li className="bm-unpaid-invoices-list__item" key={invoice.id}>
                            <UnpaidInvoiceCard invoice={invoice} />
                        </li>
                    ))}
                </ul>
            ) : (
                <Typography className="bm-unpaid-invoices-done">
                    Vi kan ikke se at dere har noen utestående fakturaer på aktive avtaler. Faktura for avsluttede
                    avtaler kan også ligge i{" "}
                    <Link className="jkl-link" href="/bedrift/dokumenter">
                        dokumenter
                    </Link>
                    .
                </Typography>
            )}
        </div>
    );
};

import { LoaderFunction } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { claimsListQuery } from "@features/claims/claims.queries";

export const claimsOverviewLoader: LoaderFunction = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker.gjeldendeOrganisasjonsnummer) {
        const dataFetcher = claimsListQuery();

        return { dataFetcher };
    }

    return Promise.resolve();
};

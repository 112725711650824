import { http } from "@features/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { brukerHarTilgang } from "../../common/rolle";
import { BrukerRolle } from "../../model/bruker.typer";
import { SamtykkeOgKontaktResponse } from "../../model/gen";
import { IHttpError } from "../../model/http.typer";
import { useBruker } from "../bruker/queries";

const samtykkeQueryKey = ["samtykke"];

// FETCH SAMTYKKE //
const fetchSamtykke = async () => {
    const { entity, feilmelding } = await http.getEntity<SamtykkeOgKontaktResponse>("samtykke");
    return {
        samtykke: {
            ekunde: entity.samtykke.ekunde?.kundeSamtykkeGitt,
            delingAvData: entity.samtykke.delingAvData?.kundeSamtykkeGitt,
            epost: entity.kontaktInfo.epost.epost,
            mobil: entity.kontaktInfo.mobil.mobilNr
        },
        feilmelding
    };
};

export const useSamtykke = () => {
    const { bruker } = useBruker();
    return useQuery({
        queryKey: samtykkeQueryKey,
        queryFn: fetchSamtykke,
        enabled:
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_DNB]),
        refetchOnWindowFocus: false,
        staleTime: Infinity
    });
};
// UPDATE SAMTYKKE //
const updateSamtykke = async ({ epost, mobil, ekunde, delingAvData }: UpdateSamtykkeArgs) =>
    http.post("samtykke", {
        kontaktInfo: ekunde ? { epost, mobil } : undefined,
        samtykke: {
            ekunde: {
                kundeSamtykkeGitt: ekunde
            },
            delingAvData: {
                kundeSamtykkeGitt: delingAvData
            }
        }
    });

export const useUpdateSamtykke = () => {
    const queryClient = useQueryClient();
    return useMutation<unknown, IHttpError, UpdateSamtykkeArgs>({
        mutationFn: updateSamtykke,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: samtykkeQueryKey });
        }
    });
};

// MODEL //
export interface SamtykkeQueryData {
    ekunde?: boolean;
    delingAvData?: boolean;
    epost: string;
    mobil: string;
}

export type UpdateSamtykkeArgs = SamtykkeQueryData;

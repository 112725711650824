import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { model } from "@features/navneliste";
import { useMutation, useQuery } from "@tanstack/react-query";

export const queryKey = {
    all: ["ansatte"],
    list: () => [...queryKey.all, "list"],
    detail: (id: string) => [...queryKey.all, id],
    oppdateringStatus: (indeks: string) => [...queryKey.all, "oppdateringStatus", indeks]
};

// ALLE ANSATTE //
const getAnsatte = async (orgNr: string) => {
    const res = await http.getEntity<model.AnsattNavnelisteResponse>(`ansatte/${orgNr}`);

    return res;
};

export const useAnsattList = () => {
    const { bruker } = useBruker();

    const query = useQuery({
        queryKey: queryKey.list(),
        queryFn: () => getAnsatte(bruker!.gjeldendeOrganisasjonsnummer!),
        enabled: !!bruker?.gjeldendeOrganisasjonsnummer,
        staleTime: 3 * 60 * 1000
    });
    return query;
};

// ANSATT DETALJER //
const getAnsattDetaljer = async (indeks: string) => {
    const res = await http.getEntity<[model.Detaljer]>(`ansatte/detaljer?ansatteIndeks=${indeks}`);

    return { entity: res.entity[0], feilmelding: res.feilmelding };
};

export const useAnsattDetaljer = (id: string, enabled: boolean) => {
    const query = useQuery({ queryKey: queryKey.detail(id), queryFn: () => getAnsattDetaljer(id), enabled });

    return query;
};

// ANSATT OPPDATERING STATUS //
const fetchAnsattOppdateringStatus = async (orgNr: string, ansattIndeks: string) => {
    const res = await http.get<model.OppdateringStatus[]>(
        `ansatte/${orgNr}/oppdateringstatus?ansatteIndeks=${ansattIndeks}`
    );

    // API returnerer alltid en liste med ett element.
    return res[0];
};

export const useAnsattOppdateringStatus = (ansattIndeks?: string) => {
    const { valgtOrganisasjon } = useBruker();

    return useQuery({
        queryKey: queryKey.oppdateringStatus(ansattIndeks!),
        queryFn: () => fetchAnsattOppdateringStatus(valgtOrganisasjon!.organisasjonsnummer, ansattIndeks!),
        enabled: !!ansattIndeks,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
    });
};

// ENDRE ANSATT AVTALER //
type EndreAnsattAvtalerArgs = {
    orgNr: string;
    endringRequest: model.OppdateringRequest;
};

const oppdaterAnsatte = async ({ orgNr, endringRequest }: EndreAnsattAvtalerArgs) =>
    http.post<model.AnsattOppdateringResponse>(`ansatte/${orgNr}/oppdateransatte`, endringRequest);

export const useOppdaterAnsatte = () => {
    const { valgtOrganisasjon } = useBruker();

    const orgNr = valgtOrganisasjon!.organisasjonsnummer;

    return useMutation({
        mutationFn: (endringRequest: model.OppdateringRequest) => oppdaterAnsatte({ orgNr, endringRequest })
    });
};

// ENDRE ANSATT AVTALER //
const endreAnsattSykelonn = async (orgNr: string, endringRequest: model.EndreSykelonnRequest) => {
    return http.post(`${orgNr}/ansatte/endre/sykelonn`, endringRequest);
};

export const useEndreAnsattSykelonn = () => {
    const { valgtOrganisasjon } = useBruker();

    const orgNr = valgtOrganisasjon!.organisasjonsnummer;

    return useMutation({
        mutationFn: (endringRequest: model.EndreSykelonnRequest) => endreAnsattSykelonn(orgNr, endringRequest)
    });
};

import { useFormContext } from "react-hook-form";

import { components } from "@features/navneliste";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";

import { SecondaryLinkButton } from "../../../../components/Button";
import { ChooseFromList } from "../../../../components/ChooseFromList";

type StegProps = components.Utmelding.StegProps;
type FormState = components.Utmelding.FormState;
interface ChooseInsuredProps extends StegProps {
    textContent: {
        lead: string;
        listInstruction: string;
    };
    searchFieldProps: {
        placeholder: string;
    };
    backLinkTo: string;
}

export const ChooseInsured = ({ insuredList, ...props }: ChooseInsuredProps) => {
    const { setValue } = useFormContext<FormState>();

    return (
        <ChooseFromList
            options={insuredList.map((insured) => ({
                label: `${insured.fornavn} ${insured.etternavn}`,
                extraLabel: `(${formatFodselsnummer(insured.fodselsnummer)})`,
                value: insured.indeks
            }))}
            title="Hvem skal meldes ut"
            subtitle={props.textContent.lead}
            instruction="Søk og velg ansatt"
            onSubmit={({ checked }) => {
                if (!checked) {
                    return;
                }

                if (Array.isArray(checked)) {
                    setValue("insuredIndeks", checked[0]);
                } else {
                    setValue("insuredIndeks", checked);
                }

                props.onFrem();
            }}
            required="Du må velge en ansatt"
            withSearch={{ ...props.searchFieldProps, noMatchText: "Ingen søketreff" }}
            asSelect
        >
            <PrimaryButton data-testautoid="meld-ut-ansatt-neste-knapp">Neste</PrimaryButton>
            <SecondaryLinkButton
                onClick={() => {
                    props.onAvbryt();
                }}
                to={props.backLinkTo}
            >
                Avbryt
            </SecondaryLinkButton>
        </ChooseFromList>
    );
};

import { useContext } from "react";

import { LenkeId, useExternalLinks } from "src/common/lenker";
import ThemeContext from "src/context/ThemeContext";
import type { AgreementDetails } from "src/model/gen";

import { TertiaryLinkButton } from "@components/Button";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";
import { Typography } from "@components/Typography";

import styles from "./TravelCardSection.module.scss";
import { TravelInsuranceCard } from "./TravelInsuranceCard/TravelInsuranceCard";

interface Props {
    agreementDetails: AgreementDetails;
}

export const TravelCardSection = ({ agreementDetails }: Props) => {
    const isNumberBased = agreementDetails.groupInsuranceType === "NUMBER_BASED";
    const isTravelInsurance = agreementDetails.productCode === "Y44";
    const { getLinkByLinkId } = useExternalLinks();
    const travelinsuranceLink = getLinkByLinkId(LenkeId.privatforsikring)?.href;

    const { distributor } = useContext(ThemeContext);
    const ISODateToday = new Date().toISOString().split("T")[0];
    const travelCardNotAvaliable = agreementDetails.startDate >= ISODateToday && distributor !== "dnb";

    if (!isTravelInsurance) {
        return null;
    }

    if (travelCardNotAvaliable) {
        return (
            <section>
                <Typography variant="heading-2" className="jkl-spacing-l--bottom" data-testid="Reisekort-heading">
                    Reisekort
                </Typography>
                <div>
                    <Typography className="jkl-spacing-l--bottom">
                        Reisekortet produseres fortsatt. Dette kan ta opp til 24 timer.
                    </Typography>
                </div>
            </section>
        );
    }
    return (
        <section>
            <Typography variant="heading-2" className="jkl-spacing-l--bottom" data-testid="Reisekort-heading">
                Reisekort
            </Typography>
            {isNumberBased ? (
                <div className={styles.travelinsurance}>
                    <div className="left">
                        <Typography>
                            Reisekortet er et forsikringsbevis på at du har reiseforsikring og det er dette
                            legekontoret/sykehuset i utlandet vil spørre etter, hvis du skulle bli utsatt for sykdom
                            eller skade. Reisekortet trenger bare å vise avtalenummer og avtaleperiode for å være
                            gyldig.
                        </Typography>
                        <Typography variant="heading-4" className="jkl-spacing-2xl--top jkl-spacing-12--bottom">
                            Kontakt SOS International
                        </Typography>
                        <Typography>
                            SOS International er vår alarmsentral som du kan benytte deg av hele døgnet hvis noe skulle
                            skje på reisen i utlandet.
                        </Typography>{" "}
                        <div className={styles.copyToClipboard + " " + "jkl-spacing-l--top"}>
                            <Typography>+47 22 96 50 45</Typography>
                            <CopyToClipboardButton textToCopy={"+4722965045"} actionLabel="Kopiér telefonnummer" />
                        </div>
                    </div>
                    <div className={styles.centerContent}>
                        <TravelInsuranceCard agreementDetails={agreementDetails} />
                    </div>
                </div>
            ) : (
                <div className={styles.travelinsurance}>
                    <Typography>
                        Dine ansatte finner reisekortet i privatløsningen vår. Der kan de laste ned reisekortet for seg
                        selv og sine familier (dersom avtalen dekker det) og finne informasjon om SmartDelay+.
                    </Typography>
                    {travelinsuranceLink && (
                        <div className={styles.buttons}>
                            <TertiaryLinkButton to={travelinsuranceLink} target="_blank">
                                Gå til privatløsningen
                            </TertiaryLinkButton>
                            <CopyToClipboardButton
                                textToCopy={travelinsuranceLink}
                                actionLabel="Kopiér lenke"
                                actionAsLabel
                            />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};

import type { AgreementField } from "src/model/gen";

import { utils } from "@features/agreements/avtale.module";

import styles from "./AgreementDetailInstance.module.scss";

export const AgreementDetailInstance = ({ description, value, type }: AgreementField) => {
    return (
        <div key={description} className={styles.detail}>
            <dt>{description}</dt>
            <dd>{utils.formatValue(value, type)}</dd>
        </div>
    );
};

import { SecondaryLinkButton } from "src/components/Button";
import { Typography } from "src/components/Typography";

import { CopyToClipboardButton } from "@components/CopyToClipboardButton";

import { Trackingkey, track } from "../../../tracking";
import "./KontaktCard.scss";

interface Props {
    mobil?: string;
    epost?: string;
    btnTxt?: string;
}

export const KontaktCard = ({ mobil, epost, btnTxt }: Props) => {
    const contactTitle = mobil ? "Mobil" : epost ? "E-post" : "";
    const contactInfo = mobil ? mobil : epost ? epost : "";
    const buttonTxt = btnTxt ? btnTxt : mobil ? "Ring oss" : epost ? "Send e-post" : "Kontakt oss";
    const contactLink = mobil ? `tel:${mobil.replace(/\s/g, "")}` : epost ? `mailto:${epost}` : "";
    const trackingLenkeId = mobil ? "kontaktinfo/telefon" : epost ? "kontaktinfo/epost" : "";

    return (
        <div className="kontakt-card">
            <div>
                <Typography component="p" variant="heading-4" className="contact-title">
                    {contactTitle}
                </Typography>
                <CopyToClipboardButton textToCopy={contactInfo?.toString()} actionLabel={`Kopiér ${contactTitle}`} />
            </div>
            <footer className="jkl-spacing-m--top">
                <SecondaryLinkButton
                    to={contactLink}
                    onClick={() => track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: trackingLenkeId })}
                >
                    {buttonTxt}
                </SecondaryLinkButton>
            </footer>
        </div>
    );
};

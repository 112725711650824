import { useEffect } from "react";

import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";
import { Featureflag, Flag } from "src/common/featureflag";
import { toCapitalizedLowerCase } from "src/common/formatting";
import { getFeilmelding } from "src/common/utils";
import { AgreementOverview, AgreementOverviewResponse } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { agreementStatusIsRenewal } from "@features/agreements/avtale.utils";
import { RenderTags } from "@features/agreements/components";
import { useBruker } from "@features/bruker/queries";
import { AgreementCTA, useVehicles } from "@features/kjop/bil";
import { GenericAgreementCta } from "@features/kjop/bil/agreementcta/GenericAgreementCTA";
import { useProductPath } from "@features/kjop/kjop.queries";
import { useCustomerNumberCheck, useIsManaged } from "@features/kjop/kjop.utils";
import { unicode } from "@fremtind/jkl-constants-util";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox, InfoMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { Tab, TabList, TabPanel, Tabs } from "@fremtind/jkl-tabs-react";

import { Typography } from "../../../../components/Typography";
import "./AgreementsOverviewComposer.scss";

interface Props {
    agreements: Array<AgreementOverview>;
    errorMessages: AgreementOverviewResponse["clientErrorMessages"];
}

const tabHeadingMap: Record<string, string> = {
    ALLE_FORSIKRINGER: "Alle forsikringer",
    ANSATT: "Ansatte",
    DRIFT_OG_EIENDOM: "Drift og eiendom",
    KJORETOY: "Kjøretøy",
    FORENING_FORBUND: "Forening og forbund"
};

export function Forsikringer(props: Props) {
    const categories = [
        "ALLE_FORSIKRINGER",
        ...new Set(props.agreements.map((agreement) => agreement.category).sort((a, b) => a.localeCompare(b)))
    ];

    const { bruker } = useBruker();
    const hasV05 = useProductPath(AvtaleProduktID.BIL_NAERING);

    // Ikke vis innganger dersom kunden ikke har kundenummer i NICE.
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    // Ikke vis innganger for meglede kunder.
    const isManaged = useIsManaged();

    const { vehicles, setShouldRunQuery } = useVehicles();

    useEffect(() => {
        if (!hasNiceCustomerNumber || isManaged) return;
        setShouldRunQuery(true);
    }, [hasNiceCustomerNumber, isManaged]); // eslint-disable-line

    const nonPurchasedVehicles = vehicles?.filter((vehicle) => !vehicle.purchased);
    const purchasedVehicles = vehicles?.filter((vehicle) => vehicle.purchased);

    useEffect(() => {
        if (!vehicles?.length) return;

        track({
            hendelse: Trackingkey.SeTilbud,
            produkt: AvtaleProduktID.BIL_NAERING,
            organisasjon: bruker?.gjeldendeOrganisasjonsnummer ?? ""
        });
        track({
            hendelse: Trackingkey.SeListe,
            type: "avtaler-bil-flyttet-V05",
            antall: purchasedVehicles?.length || 0,
            liste: purchasedVehicles?.map((vehicle) => vehicle.registrationNumber)
        });
        if (hasV05) {
            track({
                hendelse: Trackingkey.SeListe,
                type: "avtaler-bil-kan-flyttes-V05",
                antall: nonPurchasedVehicles?.length || 0,
                liste: nonPurchasedVehicles?.map((vehicle) => vehicle.registrationNumber)
            });
        }
    }, [vehicles]); // eslint-disable-line react-hooks/exhaustive-deps

    const sortedAgreeements = props.agreements.sort((a, b) => a.productName.localeCompare(b.productName));
    const filteredAgreements = sortedAgreeements.filter((agreement) => agreement.status !== "RENEWAL");
    const agreementsToDisplay = filteredAgreements;
    const hasRenewalAgreements = props.agreements.some(agreementStatusIsRenewal);

    const hasAnsattAvtaler = props.agreements.some((avtale) => avtale.category === "ANSATT");

    const trackTabFilterChange = (tabIndex: number) => {
        track({
            hendelse: Trackingkey.Filter,
            side: "Forsikringsoversikt",
            valg: categories[tabIndex],
            label: tabHeadingMap[categories[tabIndex]],
            tilgjengeligeValg: categories
        });
    };

    const trackAgreementsList = () => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "avtaler",
            antall: agreementsToDisplay.length,
            liste: agreementsToDisplay.map((a) => a.productCode)
        });
    };

    useEffect(() => {
        trackAgreementsList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const feilmelding = !!props.errorMessages.length && getFeilmelding(props.errorMessages[0]);

    const CTAS: React.FC<{ category: string }> = ({ category }) => {
        const shouldShowGenericCTA = vehicles?.length === 0 || (vehicles && vehicles?.length > 5);

        if (shouldShowGenericCTA) {
            return (
                <li className="agreement-overview__list-item" key={category + nonPurchasedVehicles?.length}>
                    <GenericAgreementCta
                        title={category === "KJORETOY" ? "Kjøp bilforsikring" : "Kjøp forsikring"}
                        body={
                            category === "KJORETOY"
                                ? "Trenger dere ny bilforsikring eller ønsker å flytte forsikring fra annet selskap?"
                                : "Er du usikker på om din bedrift har dekket sine forsikringsbehov? Se alle forsikringene vi tilbyr."
                        }
                        href={category === "KJORETOY" ? "kjop/bil" : "forsikringer/kjop-forsikring"}
                    />
                </li>
            );
        }

        return (
            !!vehicles?.length &&
            (category === "KJORETOY" || category === "ALLE_FORSIKRINGER") && (
                <>
                    {purchasedVehicles?.map((vehicle) => (
                        <li className="agreement-overview__list-item" key={vehicle.registrationNumber}>
                            <AgreementCTA
                                hasBeenPurchased={vehicle.purchased}
                                carMake={vehicle.make + " " + vehicle.model}
                                registrationNumber={vehicle.registrationNumber}
                            />
                        </li>
                    ))}
                    {!!hasV05 &&
                        nonPurchasedVehicles?.map((vehicle) => (
                            <li className="agreement-overview__list-item" key={vehicle.registrationNumber}>
                                <AgreementCTA
                                    hasBeenPurchased={vehicle.purchased}
                                    carMake={vehicle.make + " " + vehicle.model}
                                    registrationNumber={vehicle.registrationNumber}
                                />
                            </li>
                        ))}
                </>
            )
        );
    };

    return (
        <>
            <div className="infomessages">
                <Featureflag flag={Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN}>
                    <div style={{ maxWidth: "777px" }}>
                        <WarningMessageBox className="agreement-overview__info-message" fullWidth>
                            På grunn av teknisk vedlikehold vil skadeforsikringer være utilgjengelige den 21. januar fra
                            00:05 til 17:00.
                        </WarningMessageBox>
                    </div>
                </Featureflag>
                {hasRenewalAgreements && (
                    <div style={{ maxWidth: "777px" }}>
                        <InfoMessageBox
                            className="agreement-overview__info-message"
                            fullWidth
                            title="Du har forsikringer til fornyelse"
                        >
                            Forsikringsavtalene gjelder for ett år av gangen og vil fornyes automatisk. Dette kan føre
                            til ny pris og endringer av vilkår. Klikk deg inn på avtalen for å gjøre endringer.
                        </InfoMessageBox>
                    </div>
                )}

                <Featureflag flag={Flag.LONNSBERETNINGER_VARSEL}>
                    {hasAnsattAvtaler && (
                        <div style={{ maxWidth: "777px" }}>
                            <InfoMessageBox title="Årsavslutning 2023" fullWidth>
                                Hvis du har skattepliktige personalforsikringer, er dokumentene tilgjengelige nå.
                                <br />
                                <Link
                                    className="jkl-link"
                                    to="/dokumenter?dokumentFilter=Lønnsinnberetning"
                                    onClick={() => {
                                        track({
                                            hendelse: Trackingkey.LenkeTrykk,
                                            lenkeId: "lonnsberetninger-avtaleoversikt-varsel"
                                        });
                                    }}
                                >
                                    Se lønnsinnberetninger i dokumentoversikt
                                </Link>
                            </InfoMessageBox>
                        </div>
                    )}
                </Featureflag>
            </div>
            {feilmelding && (
                <ErrorMessageBox className="jkl-spacing-xl--bottom jkl-spacing-2xl-top" title={feilmelding.title}>
                    {feilmelding.message}
                </ErrorMessageBox>
            )}
            {props.agreements?.length === 0 ? (
                <>
                    <Typography className="agreement-overview__no-agreements" prose>
                        Vi kan ikke se at du har noen aktive bedriftsforsikringer hos oss.
                    </Typography>
                    <Typography className="agreement-overview__no-agreements" prose>
                        Hvis det ikke stemmer, kan det hende du ikke har tilgang til å se forsikringene. Ta kontakt med
                        administrator, daglig leder eller bedriftens forsikringsrådgiver.
                    </Typography>
                </>
            ) : (
                <Tabs onChange={trackTabFilterChange}>
                    <TabList>
                        {categories.map((category) => (
                            <Tab key={category} data-testautoid={`forsikringsoversikt-tab-${category}`}>
                                {tabHeadingMap[category] ?? toCapitalizedLowerCase(category.replaceAll("_", " "))}
                            </Tab>
                        ))}
                    </TabList>
                    {categories.map((category) => (
                        <TabPanel key={category} data-testautoid="forsikringsoversikt-liste">
                            <ul className="agreement-overview__list">
                                {agreementsToDisplay
                                    .filter((agreement) => {
                                        if (category === "ALLE_FORSIKRINGER") {
                                            mixpanel.people.set(
                                                "avtaler",
                                                agreementsToDisplay.map((a) => a.productCode + " " + a.productName)
                                            );
                                            return true;
                                        }

                                        return agreement.category === category;
                                    })
                                    .map((agreement, i) => (
                                        <li key={agreement.id} className="agreement-overview__list-item">
                                            <Link to={agreement.id} className="agreement-card">
                                                <>
                                                    <div className="agreement-card__content">
                                                        <Typography
                                                            component="h2"
                                                            variant="body"
                                                            className="agreement-card__heading"
                                                        >
                                                            {`${agreement.productName}${unicode.nbsp}→`}
                                                        </Typography>
                                                        <Typography
                                                            variant="small"
                                                            className="agreement-card__identificator"
                                                        >
                                                            {agreement.objectSummaryText}
                                                        </Typography>
                                                    </div>
                                                    <footer className="agreement-card__footer">
                                                        <Typography
                                                            variant="small"
                                                            className="agreement-card__price"
                                                        >{`${formatValuta(agreement.annualPremium)}/år`}</Typography>
                                                        <RenderTags
                                                            agreement={agreement}
                                                            agreementsToDisplay={agreementsToDisplay}
                                                            i={i}
                                                        />
                                                    </footer>
                                                </>
                                            </Link>
                                        </li>
                                    ))}
                                <CTAS category={category} />
                                {props.agreements.length % 2 === 1 ? (
                                    <li
                                        aria-hidden="true"
                                        className="agreement-overview__list-item agreement-overview__list-item--hidden"
                                    />
                                ) : null}
                            </ul>
                        </TabPanel>
                    ))}
                </Tabs>
            )}
        </>
    );
}

import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import { formatOrganisasjonsnummer } from "@fremtind/jkl-formatters-util";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { useMutation } from "@tanstack/react-query";

import { httpGet } from "../../../../common/api";
import { getFeilmelding } from "../../../../common/utils";
import { PrimaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { TextInput } from "../../../../components/TextInput";
import { Rolle } from "../../../../model/Rolle";
import { BronnoysundFeilmelding } from "../../../../model/brreg.enumer";
import { BronnoysundSjekkRespons } from "../../../../model/brreg.typer";
import { IHttpError } from "../../../../model/http.typer";
import { Trackingkey } from "../../../../tracking";
import { useBruker } from "../../../bruker/queries";
import { useGetTilganger } from "../../queries";

const verifiserBedriftMotBronnoysund = (organisasjonsnummer: string) =>
    httpGet<BronnoysundSjekkRespons>(`tilganger/validerorg/${organisasjonsnummer}`);

interface FormValues {
    organisasjonsnummer: string;
    navn: string;
    eksisterer: boolean;
    aktiv: boolean;
    roller: Rolle[];
    error: any;
}

interface Props {
    values: FormValues;
    save: (values: FormValues) => void;
    goForward: () => void;
}

export const Bedrift = ({ values, save, goForward }: Props) => {
    const {
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        setValue,
        control,
        watch
    } = useForm<FormValues>({
        defaultValues: values
    });
    const tilgangerQuery = useGetTilganger();
    const { valgtOrganisasjon } = useBruker();
    const mutation = useMutation<BronnoysundSjekkRespons, IHttpError, string>({
        mutationFn: verifiserBedriftMotBronnoysund
    });

    const orgnummer = watch("organisasjonsnummer");

    useEffect(() => {
        if (errors.error && orgnummer.length < 9) {
            clearErrors();
        }
    }, [orgnummer, errors.error, clearErrors]);

    const onSubmit = async (formValues: FormValues) => {
        if (mutation.isPending || !tilgangerQuery.data || Object.values(errors).length) {
            return;
        }

        if (valgtOrganisasjon?.organisasjonsnummer === formValues.organisasjonsnummer) {
            setError("organisasjonsnummer", {
                type: "brreg_check",
                message: `${formatOrganisasjonsnummer(
                    valgtOrganisasjon.organisasjonsnummer
                )} er ditt eget organisasjonsnummer|Du kan ikke gi tilgang til din egen organisasjon.`
            });
            return;
        }

        const duplikat = tilgangerQuery.data.organisasjonsTilganger.find(
            ({ organisasjon }) => organisasjon.organisasjonsnummer === formValues.organisasjonsnummer
        );

        if (duplikat) {
            setError("organisasjonsnummer", {
                type: "brreg_check",
                message: BronnoysundFeilmelding.ORGANISASJONSNUMMER_EKSISTERER
            });
            return;
        }

        const organisasjonsnummer = formValues.organisasjonsnummer.replace(/\s/g, "");

        mutation.mutate(organisasjonsnummer, {
            onSuccess: (data) => {
                if (data?.aktiv === true) {
                    setValue("organisasjonsnummer", organisasjonsnummer);
                    setValue("navn", data?.organisasjon.navn);
                    save({
                        ...formValues,
                        organisasjonsnummer: organisasjonsnummer,
                        navn: data?.organisasjon.navn,
                        aktiv: data?.aktiv
                    });
                    goForward();
                } else {
                    setError("organisasjonsnummer", { type: "brreg_check" });
                }
            },
            onError: (err) => {
                if (err.body.clientErrorId) {
                    setError("error", { type: err.body.clientErrorId });
                } else {
                    setError("error", { type: "generell" });
                }
            }
        });
    };

    if (!tilgangerQuery.data) {
        return <Loader textDescription="Henter tilganger" />;
    }

    return (
        <form className="gi-tilganger-side" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="jkl-spacing-xl--bottom">Velg bedrift</h2>
            <fieldset className="jkl-field-group jkl-spacing-l--bottom">
                <Controller
                    defaultValue=""
                    render={({ field }) => {
                        return (
                            <PatternFormat
                                customInput={TextInput}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    return field.onChange(e.target.value.replace(/\s/g, ""));
                                }}
                                valueIsNumericString
                                value={field.value}
                                type="text"
                                format="### ### ###"
                                placeholder="9 siffer"
                                label="Organisasjonsnummer"
                                data-testid="gi-tilgang-bedrift-orgnummer"
                                dataTestautoid="gi-tilgang-bedrift-orgnummer"
                                errorLabel={
                                    errors.organisasjonsnummer?.type !== "brreg_check"
                                        ? errors.organisasjonsnummer?.message
                                        : undefined
                                }
                            />
                        );
                    }}
                    name="organisasjonsnummer"
                    control={control}
                    rules={{
                        required: "Du må fylle inn et organisasjonsnummer",
                        minLength: {
                            value: 9,
                            message: BronnoysundFeilmelding.ORGANISASJONSNUMMER_LENGDE
                        },
                        maxLength: {
                            value: 9,
                            message: BronnoysundFeilmelding.ORGANISASJONSNUMMER_LENGDE
                        },
                        pattern: {
                            value: /(?!^)(?=(?:\d{3})+(?:\.|$))/gm,
                            message: BronnoysundFeilmelding.ORGANISASJONSNUMMER_KUN_TALL
                        }
                    }}
                />
            </fieldset>
            {errors.organisasjonsnummer?.type === "brreg_check" ? (
                <ErrorMessageBox title={errors.organisasjonsnummer.message?.split("|")[0]}>
                    {errors.organisasjonsnummer.message?.split("|")[1]}
                </ErrorMessageBox>
            ) : typeof errors.error?.type === "string" ? (
                <ErrorMessageBox title={getFeilmelding(errors.error.type)?.title}>
                    {getFeilmelding(errors.error.type)?.message}
                </ErrorMessageBox>
            ) : errors.error ? (
                <ErrorMessageBox title={BronnoysundFeilmelding.ORGANISASJONSNUMMER_UKJENT.split("|")[0]}>
                    {BronnoysundFeilmelding.ORGANISASJONSNUMMER_UKJENT.split("|")[1]}
                </ErrorMessageBox>
            ) : null}
            <SkjemaFooter>
                <PrimaryButton
                    dataTestautoid="gi-tilgang-bedrift-neste"
                    data-testid="gi-tilgang-bedrift-neste"
                    track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "gi-tilgang-bedrift-neste" }}
                    loader={{
                        showLoader: mutation.isPending,
                        textDescription: "Verifiserer organisasjonsnummer"
                    }}
                    onClick={() => clearErrors()}
                >
                    Velg tilgang →
                </PrimaryButton>

                <SecondaryLinkButton to="/tilganger" className="jkl-spacing-l--right">
                    Avbryt
                </SecondaryLinkButton>
            </SkjemaFooter>
        </form>
    );
};

import React from "react";

import { TextInput as JklTextInput, TextInputProps as JklTextInputProps } from "@fremtind/jkl-text-input-react";

export interface TextInputProps extends JklTextInputProps {
    dataTestautoid: string;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(({ dataTestautoid, ...rest }, ref) => {
    return <JklTextInput {...rest} ref={ref} data-testautoid={dataTestautoid} />;
});

TextInput.displayName = "TextInput";

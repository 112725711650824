import { useLayoutEffect, useState } from "react";

import { formatFodselsnummer, registerWithMasks } from "@fremtind/jkl-formatters-util";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { InfoTag } from "@fremtind/jkl-tag-react";
import { isValidName } from "@fremtind/jkl-validators-util";
import { useQueryClient } from "@tanstack/react-query";

import { useFolkeregisterSjekk } from "../../../../common/hooks/useFolkeregisterSjekk";
import { PrimaryButton, SecondaryButton } from "../../../../components/Button";
import { TextInput } from "../../../../components/TextInput";
import { Toast } from "../../../../components/Toast";
import { Typography } from "../../../../components/Typography";
import { ReellRettighetshaverDto, ReelleRettighetshavereDto } from "../../../../model/gen";
import { Trackingkey, track } from "../../../../tracking";
import {
    reelleRettighetshavereQueryKey,
    useAddReellRettighetshaver,
    useDeleteReellRettighetshaver
} from "../../queries";
import "./ReelleRettighetshavereTabell.scss";

interface PropTypes {
    rows: ReellRettighetshaverDto[];
}

export function ReelleRettighetshavereTabell({ rows }: PropTypes) {
    const queryClient = useQueryClient();

    const addMutation = useAddReellRettighetshaver();
    const delMutation = useDeleteReellRettighetshaver();

    const [isAddingRRH, setIsAddingRRH] = useState(false);

    const [addedPerson, setAddedPerson] = useState<ReellRettighetshaverDto>();

    track([
        {
            hendelse: Trackingkey.SeListe,
            type: "reelle rettighetshavere",
            antall: rows.length
        },
        {
            hendelse: Trackingkey.SeReelleRettighetshavere,
            antallTotalt: rows.length,
            antallAutomatiske: rows.filter((rrh) => rrh.automatisk).length
        }
    ]);

    const {
        form,
        error,
        clearError,
        onSubmit,
        mutation: dsfMutation
    } = useFolkeregisterSjekk({
        validerPerson: (person) => {
            if (rows.some((rrh) => rrh.fodselsnummer === person.fodselsnummer)) {
                return {
                    title: `${person.fornavn} ${person.etternavn} ble ikke lagt til listen.`,
                    message: `Personen er allerede registrert som reell rettighetshaver.`
                };
            }

            return undefined;
        },
        onSuccess: (person) => {
            addMutation.mutate(person, {
                onSuccess: (rrh) => {
                    // OPTIMISTIC UPDATE
                    queryClient.setQueryData(
                        reelleRettighetshavereQueryKey.all,
                        (prev: ReelleRettighetshavereDto | undefined) => {
                            if (!prev) {
                                return {
                                    bekreftet: false,
                                    reelleRettighetshavere: []
                                };
                            }

                            return {
                                ...prev,
                                reelleRettighetshavere: [
                                    ...prev.reelleRettighetshavere,
                                    {
                                        automatisk: false,
                                        fornavn: person.fornavn,
                                        etternavn: person.etternavn,
                                        fodselsnummer: person.fodselsnummer,
                                        fulltNavn: `${person.fornavn} ${person.etternavn}`,
                                        id: "temp",
                                        indeks: person.indeks,
                                        opprettetAv: "temp"
                                    }
                                ]
                            };
                        }
                    );
                    // FETCH FRESH DATA
                    queryClient.invalidateQueries({ queryKey: reelleRettighetshavereQueryKey.all });

                    setAddedPerson(rrh);

                    resetForm();
                }
            });
        }
    });

    const { registerWithFodselsnummerMask } = registerWithMasks(form);

    const [fjernetPerson, setFjernetPerson] = useState<ReellRettighetshaverDto>();
    const [clickedRow, setClickedRow] = useState<number | undefined>();

    useLayoutEffect(() => {
        if (isAddingRRH) {
            form.setFocus("fornavn");
        }
    }, [isAddingRRH, form]);

    function onDelete(rettighetshaver: ReellRettighetshaverDto) {
        delMutation.mutate(rettighetshaver, {
            onSuccess: () => {
                setClickedRow(undefined);
                setFjernetPerson(rettighetshaver);
            }
        });
    }

    function resetForm() {
        form.setValue("fornavn", "");
        form.setValue("etternavn", "");
        form.setValue("fodselsnummer", "");
        setIsAddingRRH((prev) => !prev);
    }

    return (
        <>
            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                Registrerte reelle rettighetshavere
            </Typography>
            <Table fullWidth collapseToList className="jkl-spacing-xl--bottom">
                <TableHead>
                    <TableRow>
                        <TableHeader density="compact" bold={false}>
                            Navn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Fødselsnummer
                        </TableHeader>
                        <TableHeader />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                        .sort((a, b) => a.fulltNavn.localeCompare(b.fulltNavn))
                        .map((person, index) => (
                            <TableRow key={person.indeks}>
                                <TableCell className="rrh-table__navn">
                                    {person.fulltNavn}
                                    {person.automatisk && (
                                        <InfoTag style={{ marginLeft: 24 }}>Hentet automatisk</InfoTag>
                                    )}
                                </TableCell>
                                <TableCell className="rrh-table__fodselsnummer">
                                    {person.fodselsnummer && formatFodselsnummer(person.fodselsnummer)}
                                </TableCell>
                                <TableCell align="right">
                                    {clickedRow === index ? (
                                        <div className="rrh-table__buttons">
                                            <SecondaryButton
                                                type="button"
                                                density="compact"
                                                className="jkl-button"
                                                onClick={() => setClickedRow(undefined)}
                                                dataTestautoid="bedrift-oversikt-knapp-fjern-reel-rettighetshaver"
                                                track={{
                                                    hendelse: Trackingkey.Knappetrykk,
                                                    knappeId: "bedrift-oversikt-knapp-fjern-reel-rettighetshaver"
                                                }}
                                            >
                                                Avbryt
                                            </SecondaryButton>

                                            <PrimaryButton
                                                type="button"
                                                density="compact"
                                                onClick={() => {
                                                    onDelete(person);
                                                }}
                                                dataTestautoid="bedrift-oversikt-knapp-bekreft-fjern-reel-rettighetshaver"
                                                track={{
                                                    hendelse: Trackingkey.Knappetrykk,
                                                    knappeId:
                                                        "bedrift-oversikt-knapp-bekreft-fjern-reel-rettighetshaver"
                                                }}
                                                loader={{
                                                    showLoader: delMutation.isPending,
                                                    textDescription: "fjerner reell rettighetshaver"
                                                }}
                                            >
                                                Bekreft
                                            </PrimaryButton>
                                        </div>
                                    ) : person.fodselsnummer ? (
                                        <SecondaryButton
                                            type="button"
                                            density="compact"
                                            onClick={() => {
                                                setClickedRow(index);
                                            }}
                                            dataTestautoid="bedrift-oversikt-knapp-fjern-reel-rettighetshaver"
                                            track={{
                                                hendelse: Trackingkey.Knappetrykk,
                                                knappeId: "bedrift-oversikt-knapp-fjern-reel-rettighetshaver"
                                            }}
                                        >
                                            Fjern
                                        </SecondaryButton>
                                    ) : (
                                        <InfoTag>Må fjernes av rådgiver</InfoTag>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}

                    {isAddingRRH && (
                        <TableRow className="rrh-table__add-rrh-row">
                            <TableCell className="rrh-table__add-rrh-name">
                                <TextInput
                                    label="Fornavn"
                                    dataTestautoid="fnr-input-fornavn"
                                    {...form.register("fornavn", {
                                        required: "Du må fylle inn et fornavn",
                                        validate: (value) => isValidName(value) || "Du må fylle inn et gyldig fornavn"
                                    })}
                                    errorLabel={form.formState.errors.fornavn?.message}
                                />
                                <TextInput
                                    label="Etternavn"
                                    dataTestautoid="fnr-input-etternavn"
                                    {...form.register("etternavn", {
                                        required: "Du må fylle inn et etternavn",
                                        validate: (value) => isValidName(value) || "Du må fylle inn et gyldig etternavn"
                                    })}
                                    errorLabel={form.formState.errors.etternavn?.message}
                                />
                            </TableCell>

                            <TableCell>
                                <TextInput
                                    label="Fødselsnummer"
                                    dataTestautoid="fnr-input-fodelsnummer"
                                    {...registerWithFodselsnummerMask("fodselsnummer", {
                                        required: "Du må fylle inn et fødselsnummer",
                                        minLength: {
                                            value: 11,
                                            message: "Fødselsnummeret må bestå av 11 siffer"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Fødselsnummeret må bestå av 11 siffer"
                                        },
                                        pattern: {
                                            value: /^\d+$/,
                                            message: "Fødselsnummeret kan kun inneholde tall"
                                        }
                                    })}
                                    errorLabel={form.formState.errors.fodselsnummer?.message}
                                />
                            </TableCell>

                            <TableCell>
                                <div className="rrh-table__add-rrh-buttons">
                                    <SecondaryButton
                                        onClick={resetForm}
                                        density="compact"
                                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "ny-rrh-avbryt" }}
                                        dataTestautoid="ny-rrh-btn-avbryt"
                                    >
                                        Avbryt
                                    </SecondaryButton>
                                    <PrimaryButton
                                        loader={{
                                            showLoader: dsfMutation.isPending || addMutation.isPending,
                                            textDescription: "Legger til reell rettighetshaver"
                                        }}
                                        onClick={form.handleSubmit(onSubmit)}
                                        density="compact"
                                        track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "ny-rrh-ferdig" }}
                                        dataTestautoid="ny-rrh-btn-ferdig"
                                    >
                                        Legg til
                                    </PrimaryButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <SecondaryButton
                type="button"
                onClick={() => setIsAddingRRH((prev) => !prev)}
                hidden={isAddingRRH}
                track={{ hendelse: Trackingkey.Knappetrykk, knappeId: "ny-rrh-start" }}
                dataTestautoid="ny-rrh-btn-start"
            >
                Legg til rettighetshaver
            </SecondaryButton>
            <Toast
                severity="success"
                open={addMutation.isSuccess}
                onClose={addMutation.reset}
                title="Lagt til i listen"
            >
                {addedPerson?.fulltNavn} er lagt til som reell rettighetshaver.
            </Toast>
            <Toast severity="error" open={!!error} onClose={clearError} title={error?.title}>
                {error?.message}
            </Toast>
            <Toast
                severity="success"
                open={delMutation.isSuccess}
                onClose={delMutation.reset}
                title="Fjernet fra listen"
            >
                {fjernetPerson?.fulltNavn} er fjernet som reell rettighetshaver.
            </Toast>
            <Toast severity="error" open={delMutation.isError} onClose={delMutation.reset} title="Vi har gjort en feil">
                Beklager, vi har en feil i våre systemer. Prøv igjen senere eller kontakt oss på telefon.
            </Toast>
        </>
    );
}

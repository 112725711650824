import React from "react";

import { ScrollRestoration } from "react-router-dom";

import { CookieConsentProvider } from "@fremtind/jkl-cookie-consent-react";
import { ErrorBoundary } from "@sentry/react";

import { UventetFeil } from "./components/Feil";
import { HeaderContextProvider } from "./context/HeaderContext";

interface Props {
    children: React.ReactNode;
}

export const Root = ({ children }: Props) => {
    return (
        <CookieConsentProvider statistics>
            <HeaderContextProvider>
                <ScrollRestoration />
                <ErrorBoundary fallback={<UventetFeil errorToLog={null} />}>{children}</ErrorBoundary>
            </HeaderContextProvider>
        </CookieConsentProvider>
    );
};

import React from "react";

import { Bruker, BrukerRolle } from "src/model/bruker.typer";
import { Distributor } from "src/model/theme.typer";

import { model as avtaleModel } from "@features/agreements";

type FunctionalContent<T> = ({ avtaler, bruker }: { avtaler: avtaleModel.Avtale[]; bruker?: Bruker }) => T;

export const FaqCategory = {
    ANSATTE_OG_MEDLEMMER: "Ansatte og medlemmer",
    DOKUMENTER_OG_FORSIKRINGSBEVIS: "Dokumenter og forsikringsbevis",
    FAKTURA: "Faktura",
    KJORETOY: "Kjøretøy",
    MITT_FORSIKRINGSFORHOLD: "Mitt forsikringsforhold",
    TILGANGER: "Tilganger",
    PRIVAT: "Privatforsikringer",
    SKADESAKER: "Skadesaker"
} as const;

export type FaqCategory = (typeof FaqCategory)[keyof typeof FaqCategory];

export interface FaqSchema {
    heading: FaqCategory;
    items: FaqSchemaItem[];
}

export interface FaqSchemaItem {
    category: FaqCategory;
    question: string;
    answer: string | React.ReactNode;
    requiresRoles?: Array<BrukerRolle>;
    excludeRoles?: Array<BrukerRolle>;
    requiresDistributor?: Distributor;
    excludeDistributor?: Distributor;
    detaljKrav?: FunctionalContent<boolean>;
}

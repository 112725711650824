import { AnsattBrosjyreAvtaleRequest } from "src/sider/ansattbrosjyre";

import { http } from "@features/core";
import { useMutation, useQuery } from "@tanstack/react-query";

import { model } from ".";
import { EmployeeBrochureProduct } from "../../model/gen";
import { useBruker } from "../bruker/queries";
import { transformAnsattbrosjyreData } from "./avtale.utils";

const avtaleQueryKey = {
    all: ["avtaler"] as const,
    detail: (indeks: string) => [...avtaleQueryKey.all, indeks] as const,
    ansattbrosjyre: ["ansattbrosjyre"]
};

// ANSATTBROSJYRE
function fetchAnsattbrosjyreAvtaler({ orgnr }: { orgnr: string }) {
    const res = http.get<EmployeeBrochureProduct[]>(`/${orgnr}/ansattbrosjyre/avtaler`);

    return res;
}

export function useAnsattbrosjyreAvtaler() {
    const { valgtOrganisasjon } = useBruker();

    return useQuery({
        queryKey: avtaleQueryKey.ansattbrosjyre,
        queryFn: () => fetchAnsattbrosjyreAvtaler({ orgnr: valgtOrganisasjon!.organisasjonsnummer }),

        staleTime: 10 * 60 * 1000,
        select: (data) => transformAnsattbrosjyreData(data)
    });
}

async function fetchAnsattbrosjyrePdf({ orgnr, body }: { orgnr: string; body: AnsattBrosjyreAvtaleRequest[] }) {
    return http.postRaw(`/${orgnr}/ansattbrosjyre/pdf`, body);
}

export function useAnsattbrosjyrePdf() {
    const { valgtOrganisasjon } = useBruker();
    return useMutation({
        mutationFn: (body: AnsattBrosjyreAvtaleRequest[]) =>
            fetchAnsattbrosjyrePdf({ orgnr: valgtOrganisasjon!.organisasjonsnummer, body }),
        onSuccess: (res) => {
            res.blob().then((blob) => window.open(URL.createObjectURL(blob)));
        }
    });
}

// MODEL

export interface AvtaleOversiktResponse {
    ansatteForsikringer: model.Avtale[];
    kjoretoyForsikringer: model.Avtale[];
    driftOgEiendomForsikringer: model.Avtale[];
    foreningForsikringer: model.Avtale[];
}

export interface AnsattbrosjyreAvtale {
    navn: string;
    avtaleId: string;
    grupper: Array<{
        produktId: string;
        avtaleId: string;
        gruppeId: string;
        gruppeNavn: string;
    }>;
}

import { useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Await } from "react-router-dom";

import { SecondaryLinkButton } from "@components/Button";
import { FlowFooter } from "@components/SkjemaFlyt";
import type { StepFunctions } from "@components/SkjemaFlyt/Flow";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { formatNumber, parseNumber } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox, InfoMessageBox } from "@fremtind/jkl-message-box-react";

import "./Endre.scss";
import { FieldValues } from "./EndreAntall";
import { EndreAntallRad } from "./EndreAntallRad";

type Props = StepFunctions & {
    defaultValues?: FieldValues;
};

const hasChangedFields = (data: FieldValues) =>
    data.avtaler.flatMap((avtale) => avtale.grupper).some((gruppe) => gruppe.newNumber !== 0 && !!gruppe.newNumber);

export function Endre(props: Props) {
    const form = useFormContext<FieldValues>();

    const { getValues, handleSubmit } = form;

    form.setValue("avtaler", props.defaultValues?.avtaler ? props.defaultValues.avtaler : []);

    const visibleAvtaler = useMemo(() => getValues().avtaler, [getValues]);
    const [showNoChangedFieldsError, setShowNoChangedFieldsError] = useState(false);
    const [maxNyttAntallExceededError, setMaxNyttAntallExceededError] = useState("");

    const checkEmptyFields = () => {
        const values = form.getValues();
        const isChanged = hasChangedFields(values);

        if (showNoChangedFieldsError && isChanged) {
            setShowNoChangedFieldsError(false);
        }

        if (maxNyttAntallExceededError && isChanged) {
            setMaxNyttAntallExceededError("");
        }
    };

    function onSubmit(formData: FieldValues) {
        if (hasChangedFields(formData)) {
            form.setValue("avtaler", formData.avtaler);

            const avtaleExceedsMaksNyttAntall = formData.avtaler.find((avtale) => {
                if (avtale.maksNyttAntall !== undefined) {
                    return avtale.grupper.some((gruppe) => {
                        if (gruppe.newNumber) {
                            return parseNumber(gruppe.newNumber) > avtale.maksNyttAntall!;
                        } else {
                            return false;
                        }
                    });
                }

                return false;
            });

            if (avtaleExceedsMaksNyttAntall) {
                setMaxNyttAntallExceededError(
                    `Du kan ikke ha mer enn ${formatNumber(avtaleExceedsMaksNyttAntall.maksNyttAntall!)} ${
                        avtaleExceedsMaksNyttAntall.antallType === "AARSVERK" ? "årsverk" : "antall"
                    } per gruppe.`
                );
            } else {
                props.onNext();
            }
        } else {
            setShowNoChangedFieldsError(true);
        }
    }

    // show this after everything is loaded:

    <Await resolve={form}>
        {form.getValues().avtaler.length === 0 ? (
            <InfoMessageBox title="Ingen avtaler til endring" fullWidth={true}>
                Det er ingen avtaler som kan endres.
            </InfoMessageBox>
        ) : null}
    </Await>;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="endre-antall-flyt--endre">
            {form.getValues().avtaler.map((avtale, index) => (
                <EndreAntallRad
                    onBlur={checkEmptyFields}
                    avtale={avtale}
                    avtaleIndex={index}
                    editable
                    key={avtale.avtalenummer}
                    hidden={!visibleAvtaler.includes(avtale)}
                />
            ))}

            {showNoChangedFieldsError && (
                <ErrorMessageBox fullWidth={true} className="jkl-spacing-xl--top">
                    Du må fylle ut endringer for minst én gruppe.
                </ErrorMessageBox>
            )}

            {!!maxNyttAntallExceededError && (
                <ErrorMessageBox fullWidth={true} className="jkl-spacing-xl--top">
                    {maxNyttAntallExceededError}
                </ErrorMessageBox>
            )}

            <FlowFooter>
                <PrimaryButton>Neste</PrimaryButton>
                <SecondaryLinkButton
                    to="/ansatte-antall"
                    type="button"
                    onClick={() => {
                        props.onCancel();
                    }}
                >
                    Avbryt
                </SecondaryLinkButton>
            </FlowFooter>
        </form>
    );
}

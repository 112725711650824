import { http } from "@features/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ReellRettighetshaverDto, ReelleRettighetshavereDto } from "../../model/gen";
import { IHttpError } from "../../model/http.typer";
import { useBruker } from "../bruker/queries";
import { PersonEntity } from "../tilganger-tredje-part/models";

export const reelleRettighetshavereQueryKey = {
    all: ["reelle-rettighetshavere"]
};

// LIST //
const fetchReelleRettighetshavere = (orgnummer: string) =>
    http.get<ReelleRettighetshavereDto>(`${orgnummer}/reellerettighetshavere/`);

export const useReelleRettighetshavere = () => {
    const { valgtOrganisasjon } = useBruker();

    return useQuery<ReelleRettighetshavereDto, IHttpError>({
        queryKey: reelleRettighetshavereQueryKey.all,
        queryFn: () => fetchReelleRettighetshavere(valgtOrganisasjon!.organisasjonsnummer)
    });
};

// CREATE //
type CreateArgs = {
    orgnummer: string;
    person: {
        fodselsnummer: string;
        fornavn: string;
        etternavn: string;
    };
};

const addReellRettighetshaver = ({ orgnummer, person }: CreateArgs) =>
    http.post<ReellRettighetshaverDto>(orgnummer + "/reellerettighetshavere/", person);

export const useAddReellRettighetshaver = () => {
    const { valgtOrganisasjon } = useBruker();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (person: PersonEntity) =>
            addReellRettighetshaver({ orgnummer: valgtOrganisasjon!.organisasjonsnummer, person }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: reelleRettighetshavereQueryKey.all });
        }
    });
};

// DELETE //
type DeleteArgs = { orgnummer: string; person: ReellRettighetshaverDto };

const deleteReellRettighetshaver = ({ orgnummer, person }: DeleteArgs) =>
    http.del(`${orgnummer}/reellerettighetshavere/slett`, {
        id: person.id,
        fodselsnummer: person.fodselsnummer,
        fulltNavn: person.fulltNavn
    });

export const useDeleteReellRettighetshaver = () => {
    const { valgtOrganisasjon } = useBruker();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (person: ReellRettighetshaverDto) =>
            deleteReellRettighetshaver({ orgnummer: valgtOrganisasjon!.organisasjonsnummer, person }),

        onSuccess: (_data, person) => {
            // Optimistic update
            const queryData = queryClient.getQueryData<ReelleRettighetshavereDto>(reelleRettighetshavereQueryKey.all);

            if (queryData) {
                queryClient.setQueryData(reelleRettighetshavereQueryKey.all, {
                    ...queryData,
                    reelleRettighetshavere: queryData.reelleRettighetshavere.filter(
                        (rrh) => rrh.fodselsnummer !== person.fodselsnummer
                    )
                });
            }

            queryClient.invalidateQueries({ queryKey: reelleRettighetshavereQueryKey.all });
        }
    });
};

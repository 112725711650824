import { useFormContext } from "react-hook-form";
import { isDefined } from "src/common/utils";
import { UpdateNumberBasedAgreementRequest } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { FlowFooter } from "@components/SkjemaFlyt";
import { Toast } from "@components/Toast";
import { PrimaryButton, SecondaryButton } from "@fremtind/jkl-button-react";
import { parseNumber } from "@fremtind/jkl-formatters-util";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";

import { FieldValues, type StepProps } from "./EndreAntall";
import { EndreAntallRad, resolveGroupName } from "./EndreAntallRad";
import { onlyEdited } from "./utils";

export function Oppsummering({ mutation, onNext, onPrevious, updateEndringerState, endringerState }: StepProps) {
    const form = useFormContext<FieldValues>();
    const { getValues, handleSubmit } = form;

    form.getValues().avtaler.forEach((avtale) => {
        avtale.grupper.forEach(() => {
            track({
                hendelse: Trackingkey.SeListe,
                type: "avtaler som kan endres selvbetjent",
                antall: avtale.grupper.length,
                liste: avtale.grupper.map((gruppe) => gruppe.groupId)
            });
        });
    });

    const infoMessageBoxDefaultTxt = "Endringen trer i kraft umiddelbart og vil generere en ny faktura.";
    const infoMessageBoxLoadingTxt = "Oppdateringen kan ta litt tid. Takk for at du venter!";

    async function onSubmit(data: FieldValues) {
        const editedAvtaler = data.avtaler.reduce(onlyEdited, []);
        const tilEndring = editedAvtaler.reduce<UpdateNumberBasedAgreementRequest[]>((previousValue, avtale) => {
            const grupper: UpdateNumberBasedAgreementRequest[] = avtale.grupper
                .filter(
                    (gruppe) =>
                        isDefined(gruppe.newNumber) && gruppe.numberedValue.value !== parseNumber(gruppe.newNumber)
                )

                .map((gruppe) => ({
                    id: avtale.id,
                    newNumber: parseNumber(gruppe.newNumber!),
                    groupId: gruppe.groupId
                }));

            return [...previousValue, ...grupper];
        }, []);

        // iterér over endringer synkront
        await tilEndring.reduce(async (promise, endring) => {
            await promise;

            const commitEndringState = updateEndringerState(endring.id, endring.groupId);
            commitEndringState("PENDING");

            try {
                const res = await mutation.mutateAsync(endring);

                if (!res) {
                    commitEndringState("REJECTED");
                } else {
                    commitEndringState("RESOLVED");
                }
            } catch {
                commitEndringState("REJECTED");
            }
        }, Promise.resolve());
        onNext();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {form
                .getValues()
                .avtaler.reduce(onlyEdited, [])
                .map((avtale, index) => (
                    <EndreAntallRad
                        avtale={avtale}
                        avtaleIndex={index}
                        key={avtale.id}
                        showStatus
                        endringerStatus={endringerState[avtale.id]}
                    />
                ))}

            <InfoMessageBox fullWidth={true}>
                {mutation.isPending ? infoMessageBoxLoadingTxt : infoMessageBoxDefaultTxt}
            </InfoMessageBox>

            <FlowFooter>
                <PrimaryButton
                    loader={{ showLoader: mutation.isPending, textDescription: "Lagrer endringer" }}
                    onClick={() => {
                        getValues().avtaler.forEach((avtale) => {
                            avtale.grupper.forEach((gruppe) => {
                                track({
                                    hendelse: Trackingkey.Endre,
                                    type: "endre antall ansatte",
                                    avtale: avtale.productCode ? avtale.productCode : avtale.avtalenavn,
                                    felt: resolveGroupName(gruppe.gruppenavn, gruppe.groupId, avtale.grupper.length),
                                    tidligereVerdi: gruppe.antall,
                                    nyVerdi: parseNumber(gruppe.newNumber!) ?? undefined
                                });
                            });
                        });
                    }}
                >
                    Bekreft og lagre
                </PrimaryButton>
                <SecondaryButton
                    onClick={() => {
                        onPrevious();
                    }}
                    type="button"
                >
                    Tilbake
                </SecondaryButton>
            </FlowFooter>

            <Toast
                open={mutation.isError}
                onClose={mutation.reset}
                severity="error"
                title="Oi, litt tekniske problemer her..."
            >
                Prøv en gang til eller kom tilbake senere.
            </Toast>
        </form>
    );
}

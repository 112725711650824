import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FolkeregisterSjekk } from "../../../../components/FolkeregisterSjekk";
import { StegProps } from "../../../../components/SkjemaFlyt";
import { Trackingkey, track } from "../../../../tracking";
import { useGetTilganger } from "../../queries";
import { FormDataPerson } from "./SkjemaKontroller";

export const Folkeregister = (props: StegProps) => {
    const { setValue, getValues } = useFormContext<FormDataPerson>();
    const navigate = useNavigate();
    const tilgangerQuery = useGetTilganger();

    return (
        <FolkeregisterSjekk
            classNames="gi-tilganger-side"
            header="Hvem skal få tilgang?"
            onAvbryt={() => {
                props.onAvbryt();
                navigate("/tilganger");
            }}
            avbrytButtonText="Avbryt"
            fremButtonText="Velg tilgang →"
            defaultValues={{
                fornavn: getValues("fornavn"),
                etternavn: getValues("etternavn"),
                fodselsnummer: getValues("fodselsnummer")
            }}
            onBekreftet={({ fornavn, etternavn, fodselsnummer }) => {
                track({
                    hendelse: Trackingkey.Knappetrykk,
                    knappeId: "gi-tilgang-person-neste"
                });
                setValue("fornavn", fornavn);
                setValue("etternavn", etternavn);
                setValue("fodselsnummer", fodselsnummer);
                props.onFrem();
            }}
            validerPerson={({ fodselsnummer }) => {
                const duplikat = tilgangerQuery.data!.personTilganger.find(
                    ({ person }) => person.fodselsnummer === fodselsnummer
                );

                if (duplikat) {
                    return {
                        title: `${duplikat.person.fornavn} ${duplikat.person.etternavn} har allerede tilgang`,
                        message: "Du kan ikke gi tilgang til samme person flere ganger"
                    };
                }

                return undefined;
            }}
        />
    );
};

import { useState } from "react";

import { handleCopyToClipboard } from "src/common/utils";
import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { Button } from "@fremtind/jkl-button-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@fremtind/jkl-tooltip-react";

import "./CopyToClipboardButton.scss";

interface Props {
    textToCopy: string;
    actionLabel: string;
    actionAsLabel?: boolean;
}

export const CopyToClipboardButton = ({ textToCopy, actionLabel, actionAsLabel }: Props) => {
    const [copied, setCopied] = useState(false);

    const handleCopying = () => {
        setCopied(true);
        handleCopyToClipboard(textToCopy);
        track({
            hendelse: Trackingkey.KopierKnapp,
            knappeId: actionAsLabel ? actionLabel : textToCopy,
            side: window.location.pathname,
            tekst: ""
        });
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <div className="copy-to-clipboard">
            <Typography>{actionAsLabel ? actionLabel : textToCopy}</Typography>
            <Tooltip triggerOn="hover" delay={30}>
                <TooltipTrigger onClick={() => handleCopying()}>
                    <Button variant="ghost" className="trigger">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                        >
                            <path d="M362.31-260Q332-260 311-281q-21-21-21-51.31v-455.38Q290-818 311-839q21-21 51.31-21h335.38Q728-860 749-839q21 21 21 51.31v455.38Q770-302 749-281q-21 21-51.31 21H362.31Zm0-60h335.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H362.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm-140 200Q192-120 171-141q-21-21-21-51.31v-515.38h60v515.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h395.38v60H222.31ZM350-320v-480 480Z" />
                        </svg>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>{copied ? "Kopiert" : actionLabel}</TooltipContent>
            </Tooltip>
        </div>
    );
};

import { queryClient } from "src/common/queryClient";
import type { ClaimOverview } from "src/model/gen";

import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

const claimsQueryKey = {
    overview: [`claims-overview`]
};

/**
 * Dersom du skal jobbe med dette lokalt,
 * returner
 * claimsOverviewMockData as ClaimOverview[];
 * fra `getClaims`-funksjonen
 */
export const getClaims = async () => {
    return http.get<ClaimOverview>(`/claims/overview`);
};

export const claimsListQuery = () => {
    return queryClient.fetchQuery({ queryKey: claimsQueryKey.overview, queryFn: getClaims });
};

export function useClaimsList() {
    const query = useQuery({
        queryKey: claimsQueryKey.overview,
        queryFn: getClaims,
        staleTime: Infinity,
        enabled: location.pathname !== "/feil"
    });

    return {
        query,
        data: query.data
    };
}

import { useContext } from "react";

import ThemeContext from "../context/ThemeContext";
import { useBruker } from "../features/bruker/queries";
import { BrukerRolle } from "../model/bruker.typer";

export type ExternalLink = {
    href: string;
    lenkeId: LenkeId;
    label: string;
    showInMegameny?: boolean;
    dataTestautoid?: string;
    showInSnarveier?: boolean;
    hideFromRoller?: BrukerRolle[];
};

const rolleFilter = (roller: BrukerRolle[]) => (link: ExternalLink) => {
    if (link.hideFromRoller) {
        if (roller.filter((rolle) => !link.hideFromRoller?.includes(rolle)).length) {
            return true;
        }

        return false;
    }

    return true;
};

export enum LenkeId {
    kjopForsikring = "forsikring-kjop",
    meldSkade = "meld-skade",
    privatforsikring = "se-privatforsikring",
    pensjon = "pensjon",
    ansvarsforsikring = "ansvarsforsikring",
    yrkesskadeforsikring = "yrkesskadeforsikring",
    bilnaering = "bilnaering",
    trafikkforsikringsavgift = "trafikkforsikringsavgift",
    lastebilforsikring = "lastebilforsikring",
    bussforsikring = "bussforsikring",
    tilhengerforsikring = "tilhengerforsikring",
    batforsikring = "batforsikring",
    boligbyggforsikring = "boligbyggforsikring",
    prosjektforsikring = "prosjektforsikring",
    kriminalitetsforsikring = "kriminalitetsforsikring",
    reisenaering = "reisenaering",
    transportforsikring = "transportforsikring",
    cyberforsikring = "cyberforsikring",
    spesialforsikring = "spesialforsikring",
    ulykkesforsikring = "ulykkesforsikring",
    ulykkebarn = "ulykkebarn",
    behandlingsforsikring = "behandlingsforsikring",
    annensykdom = "annensykdom",
    sykelonn = "sykelonn",
    gruppelivsforsikring = "gruppelivsforsikring",
    landbruksbygg = "landbruksbygg",
    avlingsskade = "avlingsskade",
    arbeidsmaskin = "arbeidsmaskin",
    naeringsbygg = "naeringsbygg",
    eiendelsforsikring = "eiendelsforsikring",
    driftstapforsikring = "driftstapforsikring",
    smaelektriskekjoretoy = "smalektriskekjoretoy",
    faktura = "faktura",
    dokumenter = "dokumenter",
    kollektivulykke = "ulykke",
    bil = "bil",
    billofavor = "billofavor",
    dugnadsveileder = "dugnadsveileder",
    boretslagsameieforsikring = "boretslagsameieforsikring",
    kompanjongforsikring = "kompanjongforsikring",
    nokkelpersonforsikring = "nokkelpersonforsikring",
    bygninglandbruk = "bygninglandbruk",
    losorelandbruk = "losorelandbruk",
    produksjonlandbruk = "produksjonlandbruk",
    produksjondyrlandbruk = "produksjondyrlandbruk",
    ulykkebonde = "ulykkebonde",
    proveskilt = "proveskilt",
    fritidsulykke = "fritidsulykke"
}

export const useExternalLinks = () => {
    const { bruker } = useBruker();
    const { distributor } = useContext(ThemeContext);

    if (!bruker) {
        return { links: [], getLinkByLinkId: () => undefined };
    }

    const sb1CommonLinks: ExternalLink[] = [
        {
            href: `${bruker.privatForsikringUrl}`,
            lenkeId: LenkeId.privatforsikring,
            label: "Logg inn som privatperson"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/kundeservice/verktoy/meld-skade-for-bedrifter.html",
            lenkeId: LenkeId.meldSkade,
            label: "Meld skade",
            dataTestautoid: "melde-skade",
            showInMegameny: true
        },
        {
            href: `${bruker.pensjonsUrl}/kfb/frontend/kundeklient/${bruker.gjeldendeOrganisasjonsnummer}/oversikt`,
            lenkeId: LenkeId.pensjon,
            label: "Gå til pensjon",
            showInMegameny: true,
            dataTestautoid: "ga-til-pensjon"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/ansvarsforsikring.html",
            lenkeId: LenkeId.ansvarsforsikring,
            label: "Les mer om ansvarsforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/yrkesskadeforsikring.html",
            lenkeId: LenkeId.yrkesskadeforsikring,
            label: "Les mer om yrkesskadeforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/bilforsikring-bedrift.html",
            lenkeId: LenkeId.bilnaering,
            label: "Les mer om bilforsikring for bedrift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/privat/info/trafikkforsikringsavgift.html",
            lenkeId: LenkeId.trafikkforsikringsavgift,
            label: "Les om trafikkforsikringsavgift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/lastebilforsikring.html",
            lenkeId: LenkeId.lastebilforsikring,
            label: "Les mer om lastebilforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/buss-forsikring.html",
            lenkeId: LenkeId.bussforsikring,
            label: "Les mer om bussforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/tilhengerforsikring-bedrift.html",
            lenkeId: LenkeId.tilhengerforsikring,
            label: "Les mer om tilhengerforsikring for bedrift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/batforsikring-bedrift.html",
            lenkeId: LenkeId.batforsikring,
            label: "Les mer om båtforsikring for bedrift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/boligbyggforsikring.html",
            lenkeId: LenkeId.boligbyggforsikring,
            label: "Les mer om boligbyggforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/borettslag-sameie-forsikring.html",
            lenkeId: LenkeId.boretslagsameieforsikring,
            label: "Les mer om boretslag-/sameieforsikring"
        },

        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/prosjektforsikring.html",
            lenkeId: LenkeId.prosjektforsikring,
            label: "Les mer om prosjektforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/reiseforsikring.html",
            lenkeId: LenkeId.reisenaering,
            label: "Les mer om reiseforsikring for bedrift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/transportforsikring.html",
            lenkeId: LenkeId.transportforsikring,
            label: "Les mer om transportforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/cyberforsikring.html",
            lenkeId: LenkeId.cyberforsikring,
            label: "Les mer om cyberforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/spesialforsikring.html",
            lenkeId: LenkeId.spesialforsikring,
            label: "Les mer om spesialforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/ulykkesforsikring.html",
            lenkeId: LenkeId.ulykkesforsikring,
            label: "Les mer om ulykkesforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/ulykkesforsikring-barn.html",
            lenkeId: LenkeId.ulykkebarn,
            label: "Les mer om ulykkesforsikring for barn"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/behandlingsforsikring.html",
            lenkeId: LenkeId.behandlingsforsikring,
            label: "Les mer om behandlingsforsikring for bedrift"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/yrkesskadeforsikring/utvidet-annen-sykdom.html",
            lenkeId: LenkeId.annensykdom,
            label: "Les mer om annen sykdom"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/sykelonnsforsikring.html",
            lenkeId: LenkeId.sykelonn,
            label: "Les mer om sykelønnsforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/gruppelivsforsikring.html",
            lenkeId: LenkeId.gruppelivsforsikring,
            label: "Les mer om gruppelivsforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/landbruksforsikring/landbruksbygg.html",
            lenkeId: LenkeId.landbruksbygg,
            label: "Les mer om forsikring av driftsbygninger"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/landbruksforsikring/avlingsskade-svikt.html",
            lenkeId: LenkeId.avlingsskade,
            label: "Les mer om avlingsskade"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/arbeidsmaskin-forsikring.html",
            lenkeId: LenkeId.arbeidsmaskin,
            label: "Les mer om arbeidsmaskinforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/naringsbyggforsikring.html",
            lenkeId: LenkeId.naeringsbygg,
            label: "Les mer om næringsbyggforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/eiendelsforsikring.html",
            lenkeId: LenkeId.eiendelsforsikring,
            label: "Les mer om eiendelsforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/produkter/driftstapforsikring.html",
            lenkeId: LenkeId.driftstapforsikring,
            label: "Les mer om driftstapforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/privat/forsikring/elsparkesykkel.html",
            lenkeId: LenkeId.smaelektriskekjoretoy,
            label: "Les mer om små elektriske kjøretøy"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/privat/forsikring/ulykkesforsikring.html",
            lenkeId: LenkeId.kollektivulykke,
            label: "Les mer om ulykkesforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/bilforsikring-bedrift.html",
            lenkeId: LenkeId.bil,
            label: "Les mer om bilforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/bilforsikring-bedrift.html",
            lenkeId: LenkeId.bilnaering,
            label: "Les mer om bilforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/privat/forsikring/bilforsikring.html#lofavor",
            lenkeId: LenkeId.billofavor,
            label: "Les mer om LOfavør Bilforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/borettslag-sameie-forsikring/dugnadsveileder.html",
            lenkeId: LenkeId.dugnadsveileder,
            label: "Dugnadsveileder"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/kriminalitetsforsikring.html",
            lenkeId: LenkeId.kriminalitetsforsikring,
            label: "Les mer om kriminalitetsforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/kompanjongforsikring.html",
            lenkeId: LenkeId.kompanjongforsikring,
            label: "Les mer om kompanjongforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/nokkelpersonforsikring.html",
            lenkeId: LenkeId.nokkelpersonforsikring,
            label: "Les mer om nøkkelpersonforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/landbruksforsikring/driftsbygning.html",
            lenkeId: LenkeId.bygninglandbruk,
            label: "Les mer om bygning- og landbruksforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/landbruksforsikring/driftslosore-utstyr-redskap.html",
            lenkeId: LenkeId.losorelandbruk,
            label: "Les mer om Løsøre, avling for landbruk"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/landbruksforsikring/planteproduksjon.html",
            lenkeId: LenkeId.produksjonlandbruk,
            label: "Les mer om produksjon landbruk"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/landbruksforsikring/produksjonsdyr.html",
            lenkeId: LenkeId.produksjondyrlandbruk,
            label: "Les mer om produksjonsdyr landbruk"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/landbruksforsikring/ulykkesforsikring-bonde.html",
            lenkeId: LenkeId.ulykkebonde,
            label: "Les mer om ulykkesforsikring for bonde"
        },
        {
            href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/borettslagforsikring-sameieforsikring",
            lenkeId: LenkeId.boligbyggforsikring,
            label: "Les mer om boligbyggforsikring"
        },
        {
            href: "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/fritidsulykkesforsikring.html ",
            lenkeId: LenkeId.fritidsulykke,
            label: "Les mer om friidsulykkesforsikring"
        }
    ];

    const links: {
        dnb: ExternalLink[];
        sb1: ExternalLink[];
        meg: ExternalLink[];
    } = {
        dnb: [
            {
                href: "https://www.dnb.no/bedrift/forsikring/meld-skade",
                lenkeId: LenkeId.meldSkade,
                label: "Meld skade",
                dataTestautoid: "melde-skade"
            },
            {
                href: `${bruker.privatForsikringUrl}`,
                lenkeId: LenkeId.privatforsikring,
                label: "Logg inn som privatperson"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/ansvarsforsikring",
                lenkeId: LenkeId.ansvarsforsikring,
                label: "Les mer om ansvarsforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/bilforsikring",
                lenkeId: LenkeId.bilnaering,
                label: "Les mer om bilforsikring for bedrift"
            },
            {
                href: "https://www.dnb.no/forsikring/bilforsikring/trafikkforsikringsavgift",
                lenkeId: LenkeId.trafikkforsikringsavgift,
                label: "Les om trafikkforsikringsavgift"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/lastebilforsikring",
                lenkeId: LenkeId.lastebilforsikring,
                label: "Les mer om lastebilforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/tilhengerforsikring",
                lenkeId: LenkeId.tilhengerforsikring,
                label: "Les mer om tilhengerforsikring for bedrift"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/batforsikring",
                lenkeId: LenkeId.batforsikring,
                label: "Les mer om båtforsikring for bedrift"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/borettslagforsikring-sameieforsikring",
                lenkeId: LenkeId.boligbyggforsikring,
                label: "Les mer om boligbyggforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/prosjektforsikring",
                lenkeId: LenkeId.prosjektforsikring,
                label: "Les mer om prosjektforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/kriminalitetsforsikring",
                lenkeId: LenkeId.kriminalitetsforsikring,
                label: "Les mer om kriminalitetsforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/personalforsikring/reiseforsikring",
                lenkeId: LenkeId.reisenaering,
                label: "Les mer om reiseforsikring for bedrift"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/transportforsikring",
                lenkeId: LenkeId.transportforsikring,
                label: "Les mer om transportforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/cyberforsikring",
                lenkeId: LenkeId.cyberforsikring,
                label: "Les mer om cyberforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/spesialforsikring",
                lenkeId: LenkeId.spesialforsikring,
                label: "Les mer om spesialforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/arbeidsmaskinforsikring",
                lenkeId: LenkeId.arbeidsmaskin,
                label: "Les mer om arbeidsmaskinforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/naringsbyggforsikring",
                lenkeId: LenkeId.naeringsbygg,
                label: "Les mer om næringsbyggforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/eiendelsforsikring",
                lenkeId: LenkeId.eiendelsforsikring,
                label: "Les mer om eiendelsforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/driftstapforsikring",
                lenkeId: LenkeId.driftstapforsikring,
                label: "Les mer om driftstapforsikring"
            },
            {
                href: "https://www.dnb.no/forsikring/kjoretoy/sma-elektriske-kjoretoy",
                lenkeId: LenkeId.smaelektriskekjoretoy,
                label: "Les mer som små elektriske kjøretøy"
            },
            {
                href: "https://www.dnb.no/forsikring/personforsikring/ulykkesforsikring",
                lenkeId: LenkeId.kollektivulykke,
                label: "Les mer om ulykkesforsikring"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/borettslagforsikring-sameieforsikring",
                lenkeId: LenkeId.dugnadsveileder,
                label: "Dugnadsveileder"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/personalforsikring/reiseforsikring",
                lenkeId: LenkeId.reisenaering,
                label: "Les mer om reiseforsikring for bedrift"
            },
            {
                href: "https://www.dnb.no/bedrift/forsikring/skadeforsikring/proveskiltforsikring",
                lenkeId: LenkeId.proveskilt,
                label: "Les mer om prøveskiltforsikring"
            }
        ],
        sb1: sb1CommonLinks,
        meg: sb1CommonLinks
    };

    const getLinkByLinkId = (linkId: LenkeId) => {
        return links[distributor].find((link) => link.lenkeId === linkId);
    };

    return { links: links[distributor].filter(rolleFilter(bruker!.roller)), getLinkByLinkId };
};

/**
 * Schema.ts - Modul for håndtering av meny-skjemaer
 *
 * Inneholder meny-skjemaene for både sidemeny og megameny.
 * Menyene har mange like punkter som er duplisert i varierende grad.
 * Derfor er det meget viktig at om kravene til menypunktene skal endres,
 * så må BEGGE skjemaene oppdateres.
 */
import { useContext } from "react";

import { brukerHarTilgang, visAnsattLenke } from "src/common/rolle";
import ThemeContext from "src/context/ThemeContext";
import { Bruker, BrukerRolle } from "src/model/bruker.typer";
import { AgreementOverview, Organisasjon, TilbudResponse } from "src/model/gen";
import { Distributor } from "src/model/theme.typer";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { isMedlemAgreement } from "@features/agreements/avtale.utils";
import { useBruker } from "@features/bruker/queries";
import { onlyFlateAvtaler } from "@features/kjoretoy/kjoretoy.utils";
import { useGetTilbud } from "@features/signering/queries";

import { Flag, validateFeatureflag } from "../../common/featureflag";
import { isMedlemAvtale, isNavneAvtale } from "../../common/utils";

export interface MenySchema {
    heading: string;
    class?: string;
    items: MenySchemaItem[];
}

interface MenySchemaItem {
    to: string;
    label: string;
    roller?: Array<BrukerRolle>;
    kreverAvtaler?: boolean;
    kreverDistributor?: Distributor;
    kreverMedlemAvtaler?: boolean;
    kreverAnsattAvtaler?: boolean;
    featureflag?: Flag;
    detaljKrav?: ({
        avtaler,
        tilbud,
        bruker
    }: {
        avtaler: AgreementOverview[];
        tilbud: TilbudResponse[];
        bruker?: Bruker;
    }) => boolean;
}

const ikkeBareYrkesskade = (avtaler: AgreementOverview[]) => {
    const ansattForsikring = avtaler.filter((avtale) => avtale.category.includes("ANSATT"));
    return visAnsattLenke(ansattForsikring);
};

const getSideMenySchema = (valgtOrganisasjon?: Organisasjon): Array<MenySchema> => {
    return [
        {
            heading: "Forsikring",
            class: "forsikring",
            items: [
                {
                    to: "/hjem",
                    label: "Hjem",
                    kreverAvtaler: false
                },
                {
                    to: "/forsikringer",
                    label: "Forsikringer",
                    kreverAvtaler: true
                },
                {
                    to: "/forsikringer/kjop-forsikring",
                    label: "Kjøp forsikring",
                    kreverAvtaler: false
                },
                {
                    to: "/faktura",
                    label: "Faktura",
                    kreverAvtaler: true
                },
                {
                    to: "/dokumenter",
                    label: "Dokumenter",
                    kreverAvtaler: true
                },
                {
                    to: "/skadesaker",
                    label: "Skadesaker",
                    kreverAvtaler: true
                }
            ]
        },
        {
            heading: "Ansatte",
            items: [
                {
                    to: "/ansatte-navn",
                    label: "Meld inn/ut ansatte",
                    kreverAvtaler: true,
                    roller: [BrukerRolle.PERSONAL, BrukerRolle.RADGIVER],
                    kreverDistributor: Distributor.SB1,
                    detaljKrav: ({ avtaler }) =>
                        avtaler
                            .filter((avtale) => !isMedlemAvtale(avtale) && isNavneAvtale(avtale))
                            .some((avtale) => avtale.category === "ANSATT" && ikkeBareYrkesskade(avtaler)),
                    kreverAnsattAvtaler: true
                },

                {
                    to: "/ansatte-antall",
                    label: "Endre antall ansatte",
                    kreverAvtaler: true,
                    roller: [
                        BrukerRolle.PERSONAL,
                        BrukerRolle.RADGIVER,
                        BrukerRolle.ADMINISTRATOR_DNB,
                        BrukerRolle.RADGIVER_DNB
                    ],
                    kreverAnsattAvtaler: true,
                    detaljKrav: ({ avtaler }) => {
                        return avtaler
                            .filter((avtale) => !isMedlemAvtale(avtale) && !isNavneAvtale(avtale))
                            .some(
                                (avtale) => avtale.category === "ANSATT" && avtale.groupInsuranceType === "NUMBER_BASED"
                            );
                    }
                },
                {
                    to: "/medlemmer",
                    label: "Meld inn/ut medlemmer",
                    kreverAvtaler: true,
                    roller: [BrukerRolle.FORENING, BrukerRolle.RADGIVER],
                    kreverDistributor: Distributor.SB1,

                    kreverMedlemAvtaler: true,
                    detaljKrav: ({ avtaler }) => {
                        return avtaler
                            .filter((avtale) => isMedlemAvtale(avtale))
                            .some(
                                (avtale) =>
                                    avtale.category === "FORENING_FORBUND" && avtale.groupInsuranceType === "NAME_BASED"
                            );
                    }
                },
                {
                    to: "/tilganger",
                    label: valgtOrganisasjon ? `Tilganger - ${valgtOrganisasjon.navn}` : "Tilganger",
                    roller: [
                        BrukerRolle.ADMINISTRATOR,
                        BrukerRolle.ADMINISTRATOR_DNB,
                        BrukerRolle.RADGIVER,
                        BrukerRolle.RADGIVER_DNB
                    ]
                },
                {
                    to: "/tilganger-andre-bedrifter",
                    label: "Tilganger - Andre bedrifter",
                    roller: [
                        BrukerRolle.ADMINISTRATOR,
                        BrukerRolle.ADMINISTRATOR_DNB,
                        BrukerRolle.RADGIVER,
                        BrukerRolle.RADGIVER_DNB
                    ],
                    detaljKrav: ({ bruker }) => !!bruker?.harAndreBedrifter
                },
                {
                    to: "/forsikringer/kjoretoy",
                    label: "Se kjøretøy",
                    kreverAvtaler: true,
                    detaljKrav({ avtaler }) {
                        if (onlyFlateAvtaler(avtaler).length > 0) {
                            return true;
                        }

                        return false;
                    }
                }
            ]
        },
        {
            heading: "Din bedrift",
            items: [
                {
                    to: "/signering/gruppeliv",
                    label: "Signer tilbud",
                    kreverAvtaler: false,
                    roller: [BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER],
                    kreverDistributor: Distributor.SB1,
                    detaljKrav: ({ tilbud }) => tilbud.filter((tilbud) => !tilbud.signert).length > 0
                },
                {
                    to: "/reelle-rettighetshavere",
                    label: "Reelle rettighetshavere",
                    detaljKrav: ({ bruker }) => bruker?.dynamicMenu?.rrh.showMenuItem ?? false
                },
                {
                    to: "/kontakt-oss",
                    label: "Kontakt oss"
                }
            ]
        }
    ];
};

export function useMenySchema() {
    const { bruker, valgtOrganisasjon } = useBruker();
    const { distributor } = useContext(ThemeContext);

    const tilbudQuery = useGetTilbud();
    const avtaleQuery = useAgreementList();

    const avtaler = avtaleQuery.data?.agreements || [];
    const tilbud = tilbudQuery.data?.entity || [];

    function filteredSchema(schema: MenySchema[]) {
        return schema
            .map((menuBlock) => {
                const items = menuBlock.items.filter((menuItem) => {
                    if (!bruker) {
                        return false;
                    }

                    if (menuItem.kreverMedlemAvtaler && !avtaler.some(isMedlemAgreement)) {
                        return false;
                    }

                    if (menuItem.kreverAnsattAvtaler && avtaler.every(isMedlemAgreement)) {
                        return false;
                    }

                    if (menuItem.kreverAnsattAvtaler && avtaler.every(isMedlemAgreement)) {
                        return false;
                    }

                    if (menuItem.kreverAvtaler && !avtaler.length) {
                        return false;
                    }

                    if (menuItem.detaljKrav && !menuItem.detaljKrav({ avtaler: avtaler, tilbud, bruker })) {
                        return false;
                    }

                    if (menuItem.kreverDistributor && distributor !== menuItem.kreverDistributor) {
                        return false;
                    }

                    if (menuItem.featureflag !== undefined && !validateFeatureflag(menuItem.featureflag)) {
                        return false;
                    }

                    if (brukerHarTilgang(bruker, BrukerRolle.ADMINISTRATOR)) {
                        return true;
                    }

                    if (menuItem.roller && !brukerHarTilgang(bruker, menuItem.roller)) {
                        return false;
                    }

                    return true;
                });

                return {
                    ...menuBlock,
                    items
                };
            })
            .filter((menuBlock) => {
                return !!menuBlock.items.length;
            });
    }

    const schemaToRender = filteredSchema(getSideMenySchema(valgtOrganisasjon));
    const isLoading = avtaleQuery.isLoading || tilbudQuery.isLoading;

    const value: [typeof schemaToRender, boolean] = [schemaToRender, isLoading];

    return value;
}

import type React from "react";

import { SkeletonAnimation, SkeletonElement } from "@fremtind/jkl-loader-react";

interface Props {
    height?: number;
}

export const LoaderCard: React.FC<Props> = ({ height = 264 }) => {
    return (
        <SkeletonAnimation className="antall-card antall-card--loader">
            <SkeletonElement width="100%" height={height} />
        </SkeletonAnimation>
    );
};

import React from "react";

import { FieldValues, Path, RegisterOptions, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { formatNumber } from "@fremtind/jkl-formatters-util";
import { TextInput } from "@fremtind/jkl-text-input-react";

const regex = /(\D|\s+)/g;

interface CurrencyInputProps<TFormValues extends FieldValues> extends React.ComponentProps<typeof TextInput> {
    name: Path<TFormValues>;
    rules: RegisterOptions;
    register: UseFormRegister<TFormValues>;
    setValue: UseFormSetValue<TFormValues>;
}

export function CurrencyInput<TFormValues extends FieldValues>({
    register,
    setValue,
    name,
    rules,
    ...props
}: CurrencyInputProps<TFormValues>) {
    return (
        <TextInput
            {...props}
            // @ts-ignore Denne var vrien å få til. TS er bekymret for at typen vil value ikke stemmer overens med name.
            {...register(name, {
                ...rules,
                onChange: (e) => {
                    const num = formatNumber(e.target.value.replace(regex, ""));
                    // @ts-ignore Denne var vrien å få til. TS er bekymret for at typen vil value ikke stemmer overens med name.
                    setValue(name, num !== "0" ? num : "");
                },
                setValueAs: (value) => value.replace(regex, "")
            })}
        />
    );
}

import { FC } from "react";

import { asDate } from "src/common/formatting";
import { InvoiceDto, InvoiceReminderDto } from "src/model/gen";
import { VarselFaktura } from "src/tracking";

import { ErrorTag, InfoTag, SuccessTag, Tag, TagProps, WarningTag } from "@fremtind/jkl-tag-react";

export const calculateInvoiceReminderState = (invoiceReminder: InvoiceReminderDto): VarselFaktura["type"] | null => {
    if (invoiceReminder.invoiceEventType === "FAKTURA") {
        return "forfalt_faktura";
    }

    if (invoiceReminder.invoiceEventType === "PURRING") {
        // 28 dager etter forfall, forfaller purringen
        // purringer får ny due date, og daysPastDue === 0 er det samme som 28 dager etter forfall på faktura
        if (invoiceReminder.daysPastDue > 0) {
            return "purring_passert_forfallsdato";
        }

        return "purring";
    }

    if (["ETTERKRAV", "INKASSOVARSEL"].includes(invoiceReminder.invoiceEventType)) {
        return "inkassovarsel";
    }

    return null;
};

export const mapInvoiceReminderStateToInvoiceEvent = (
    status: VarselFaktura["type"]
): InvoiceReminderDto["invoiceEventType"] => {
    switch (status) {
        case "inkassovarsel":
            return "INKASSOVARSEL";
        case "purring":
            return "PURRING";
        case "purring_passert_forfallsdato":
            return "PURRING";
        case "forfalt_faktura":
            return "FAKTURA";
    }
};

const enabledInvoiceEventTypes: Array<InvoiceReminderDto["invoiceEventType"]> = [
    "FAKTURA",
    "PURRING",
    "ETTERKRAV",
    "INKASSOVARSEL"
];

const invoiceEventTypePriorityOrder: { [key in InvoiceReminderDto["invoiceEventType"]]?: number } = {
    FAKTURA: 0,
    PURRING: 1,
    ETTERKRAV: 2,
    INKASSOVARSEL: 2
};
interface SortableInvoice {
    invoiceEventType: InvoiceReminderDto["invoiceEventType"];
    daysPastDue: number;
}

export const sortInvoiceReminderByDueDate = (invoiceA: SortableInvoice, invoiceB: SortableInvoice) => {
    const priorityA = invoiceEventTypePriorityOrder[invoiceA.invoiceEventType] ?? 0;
    const priorityB = invoiceEventTypePriorityOrder[invoiceB.invoiceEventType] ?? 0;

    // sammenlign days past due date
    if (priorityA === priorityB) {
        return invoiceB.daysPastDue - invoiceA.daysPastDue;
    }

    return priorityB - priorityA;
};

export const getMostCriticalInvoice = (invoices: InvoiceReminderDto[]) => {
    const invoicesFilteredByImplementedTypes = invoices.filter((invoice) => {
        return enabledInvoiceEventTypes.includes(invoice.invoiceEventType);
    });

    const invoiceRemindersSortedByDaysFromDueDate =
        invoicesFilteredByImplementedTypes.sort(sortInvoiceReminderByDueDate);

    return invoiceRemindersSortedByDaysFromDueDate.at(0);
};

type ReturnType = [FC<TagProps>, string];

const DEFAULT: ReturnType = [(props) => <div {...props} />, ""];

export const getTagForStatus = (
    status: InvoiceDto["status"],
    dueDate: string | undefined,
    invoiceEventType: InvoiceDto["invoiceEventType"]
): ReturnType => {
    if (invoiceEventType === "KREDITNOTA") {
        return DEFAULT;
    }

    switch (status) {
        case "ANNULLERT":
            return [Tag, "Annullert"];
        case "BETALT":
            return [SuccessTag, "Betalt"];
        case "DELBETALT":
            return [InfoTag, "Delbetalt"];
        case "UBETALT":
            if (invoiceEventType !== "PURRING" && dueDate && asDate(dueDate) > new Date()) {
                return [WarningTag, "Ubetalt"];
            }

            return [ErrorTag, "Ubetalt"];
        default:
            return DEFAULT;
    }
};

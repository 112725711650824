import { Side, SideHeader, SideInnhold } from "src/components/Side";

import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { ProductAccordions } from "@features/kjop/components";

import "./AlleForsikringer.scss";

export function AlleForsikringer() {
    const ansatteProducts = {
        ANNEN_SYKDOM: AvtaleProduktID.ANNEN_SYKDOM,
        FRITIDSULYKKE: AvtaleProduktID.KOLLEKTIV_ULYKKE_BEDRIFT,
        PERSONALGRUPPELIV: AvtaleProduktID.PERSONALGRUPPELIV,
        BEHANDLINGSFORSIKRING: AvtaleProduktID.BEHANDLINGSFORSIKRING,
        REISE: AvtaleProduktID.REISE_NERING,
        SYKELONN: AvtaleProduktID.SYKELONN,
        ULYKKE_BARN: AvtaleProduktID.KOLLEKTIV_ULYKKE_BARN,
        YRKESSKADE: AvtaleProduktID.YRKESSKADE
    } as const;

    const ansatteSection = {
        name: "Ansatte",
        products: ansatteProducts
    };

    const kjoretoyProducts = {
        ARBEIDSMASKIN: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING,
        BIL: AvtaleProduktID.BIL_NAERING,
        BUSS: AvtaleProduktID.BUSS,
        BAT: AvtaleProduktID.NERINGSBAT,
        LASTEBIL: AvtaleProduktID.LASTEBIL,
        PROVESKILT: AvtaleProduktID.ARSPROVEKJENNEMERKE,
        TILHENGER: AvtaleProduktID.TILHENGER_NERING,
        TRAKTOR: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING
    } as const;

    const kjoretoySection = {
        name: "Kjøretøy",
        products: kjoretoyProducts
    };

    const driftProducts = {
        ANSVAR: AvtaleProduktID.ANSVAR,
        DRIFTSTAP: AvtaleProduktID.DRIFTSTAP,
        BOLIGBYGG: AvtaleProduktID.BOLIGBYGG_BORETTSLAG_SAMEIE,
        BORETTSLAG_SAMEIE: AvtaleProduktID.BOLIGBYGG_BORETTSLAG_SAMEIE,
        CYBER: AvtaleProduktID.CYBER,
        KRIMINALITETSFORSIKRING: AvtaleProduktID.KRIMINALITETSFORSIKRING,
        NAERINGSBYGG: AvtaleProduktID.NÆRINGSBYGG,
        EIEDELER: AvtaleProduktID.EIENDELER,
        PROSJEKT: AvtaleProduktID.PROSJEKT_KONTRAKTSARBEIDER,
        VERDISAK: AvtaleProduktID.SPESIALFORSIKRING_NERING,
        TRANSPORT: AvtaleProduktID.TRANSPORT_OMSETNING_VARER
    } as const;

    const driftSection = {
        name: "Drift og eiendom",
        products: driftProducts
    };

    // Disse produktene avventer vi svar på hvordan/om vi skal vise.
    //
    // LenkeId.avlingsskade, "Avling/innsatsmidler"
    // LenkeId.bygninglandbruk, "Bygning landbruk"
    // LenkeId.losorelandbruk, "Løsøre, avling for landbruk"
    // LenkeId.produksjonlandbruk, "Produksjon landbruk"
    // LenkeId.produksjondyrlandbruk, "Produksjonsdyr landbruk"
    // LenkeId.ulykkebonde, "Ulykke bonde/familie")

    const landbrukProducts = {
        AVLING: AvtaleProduktID.LOSORE_AVLING_FOR_LANDBRUK,
        BYGNING: AvtaleProduktID.BYGNING_LANDBRUK,
        LOSORE: AvtaleProduktID.LOSORE_AVLING_FOR_LANDBRUK
    } as const;

    const landbrukSection = {
        name: "Landbruk",
        products: landbrukProducts
    };

    const products = [ansatteSection, kjoretoySection, driftSection, landbrukSection];

    return (
        <Side>
            <SideHeader title="Alle forsikringer" />
            <SideInnhold className="bm-getinsurance">
                {products.map((category) => (
                    <section className="bm-getinsurance" key={category.name}>
                        <Typography component="h2" variant="heading-4" className="jkl-spacing-xs--bottom">
                            {category.name}
                        </Typography>
                        <ProductAccordions products={category.products} />
                    </section>
                ))}
            </SideInnhold>
        </Side>
    );
}

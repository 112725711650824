import React, { ReactNode } from "react";

import { CheckListItem, CrossListItem, List } from "@fremtind/jkl-list-react";
import {
    ErrorMessageBox,
    InfoMessageBox,
    MessageBoxProps,
    SuccessMessageBox,
    WarningMessageBox
} from "@fremtind/jkl-message-box-react";

import { SkjemaFooter } from "../SkjemaFooter";
import { Typography } from "../Typography";
import "./Kvittering.scss";

export type MessageVariant = "error" | "info" | "warning" | "success";

const messageMap: Record<MessageVariant, React.FC<MessageBoxProps>> = {
    error: ErrorMessageBox,
    info: InfoMessageBox,
    warning: WarningMessageBox,
    success: SuccessMessageBox
};
export interface KvitteringProps {
    heading: string;
    lead?: string | ReactNode;
    positives?: string[];
    negatives?: string[];
    message?: { title: string; body: string; variant: MessageVariant };
    children?: ReactNode;
    spacedBottom?: boolean;
    hidePayment?: boolean;
}

export const Kvittering = (props: KvitteringProps) => {
    const Message = props.message ? messageMap[props.message.variant] : () => null;

    return (
        <div className="bm-kvittering">
            <div className="bm-kvittering__heading">
                <Typography>{props.heading}</Typography>

                {props.lead && typeof props.lead === "string" ? <Typography>{props.lead}</Typography> : props.lead}
            </div>

            <div className="bm-kvittering__summary">
                <Typography variant="heading-4">Oppsummering</Typography>
                {props.positives && (
                    <List>
                        {props.positives.map((text) => (
                            <CheckListItem key={text}>{text}</CheckListItem>
                        ))}
                    </List>
                )}
                {props.negatives && (
                    <List>
                        {props.negatives.map((text) => (
                            <CrossListItem key={text}>{text}</CrossListItem>
                        ))}
                    </List>
                )}
            </div>

            <Message className="kvittering__error" title={props.message?.title} data-testid="bm-kvittering-message">
                {props.message?.body}
            </Message>

            {props.hidePayment && (
                <div className="bm-kvittering__payment">
                    <Typography variant="heading-4">Betaling</Typography>
                    <Typography>Legges til på eksisterende betalingsavtale.</Typography>
                </div>
            )}
            {props.children && <SkjemaFooter>{props.children}</SkjemaFooter>}
        </div>
    );
};

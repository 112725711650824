import type React from "react";

import { type AgreementDetailObject } from "src/model/gen";

import { AgreementCoverageCard } from "./AgreementCoverageCard";
import "./AgreementCoverageList.scss";
import { coverageInfo } from "./CoverageInfo";

type Props = {
    objects: AgreementDetailObject[];
};

export const AgreementCoverageList: React.FC<Props> = ({ objects }) => {
    if (!objects) return null;

    return (
        <ul className="agreement-coverage-list">
            {objects.map(
                (object) =>
                    object.coverageList &&
                    object.coverageList.map((coverage) => (
                        <AgreementCoverageCard
                            key={coverage.id}
                            description={coverageInfo[coverage.name?.toLowerCase().replace(/\s/g, "")]?.description}
                            coverage={coverage}
                        />
                    ))
            )}
        </ul>
    );
};

import { useCallback, useEffect, useState } from "react";

import { endOfDay, isValid, startOfDay } from "date-fns";
import { useFormContext } from "react-hook-form";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { model, utils } from "@features/navneliste";
import { DatePicker, formatInput, parseDateString } from "@fremtind/jkl-datepicker-react";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { Loader } from "@fremtind/jkl-loader-react";
import { ErrorMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import { useScreen } from "@fremtind/jkl-react-hooks";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { isValidEpost } from "@fremtind/jkl-validators-util";

import { FormState, MinMaxDate, StegProps } from ".";
import { toBackendDateFormat } from "../../../../common/formatting";
import { PrimaryButton, SecondaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { CurrencyInput } from "../../../../components/CurrencyInput/CurrencyInput";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Trackingkey, track } from "../../../../tracking";
import { DEFAULT_INNMELDING_DATO } from "../../navneliste.constants";

interface MoreInfoProps extends StegProps {
    oversiktLink: string;
}

export function MoreInfo({ mutation, ...props }: MoreInfoProps) {
    const [showSykelonnWarning, setShowSykelonnWarning] = useState(false);

    const screen = useScreen();
    const density = screen.isSmallDevice ? "compact" : undefined;

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<FormState>();

    const { avtaler, isArbeidsdyktig, lonn, insured } = watch();
    const chosenAvtaleProduktkoder = avtaler.map((avtale) => avtale.avtaleProdukt.produktkode);
    const maxAlderList = [...new Set(avtaler.map((avtale) => avtale.maximumAlder))] as number[];

    const getAldersgrenseText = () => {
        if (!insured) {
            return false;
        }

        const aldersgrenseBehandlingsforsikring =
            avtaler.find((avtale) => avtale.avtaleProdukt.produktkode === AvtaleProduktID.BEHANDLINGSFORSIKRING)
                ?.minimumAlder || 16;

        if (
            chosenAvtaleProduktkoder.includes(AvtaleProduktID.BEHANDLINGSFORSIKRING) &&
            insured.alder < aldersgrenseBehandlingsforsikring
        ) {
            return `Nedre aldersgrense for behandlingsforsikring er ${aldersgrenseBehandlingsforsikring} år. `;
        } else if (maxAlderList?.some((aldersGrense) => insured.alder > aldersGrense && aldersGrense !== 0)) {
            return `Øvre aldersgrense for forsikringen er ${maxAlderList.find(
                (aldersGrense) => insured.alder > aldersGrense
            )} år. `;
        }
        return true;
    };

    const insuredName = utils.resolvePersonName(insured);

    const showEpostPrompt = avtaler.some((avtale) => avtale.epostKreves);

    const showArbeidsdyktighetPrompt = avtaler.some((avtale) => avtale.arbeidsdyktighetKreves);

    const showLonnPrompt = avtaler.some((avtale) => avtale.lonnKreves);

    const showArbeidsdyktighetWarning = showArbeidsdyktighetPrompt ? isArbeidsdyktig === "Nei" : false;

    const innmeldingDato: MinMaxDate = (() => {
        const minInnmeldingDatoer = avtaler
            .map((avtale) => avtale.innmeldingsDato?.min)
            .filter((date) => date && isValid(date))
            .map((date) => Number(startOfDay(date!)));

        const maxInnmeldingDatoer = avtaler
            .map((avtale) => avtale.innmeldingsDato?.max)
            .filter((date) => date && isValid(date))
            .map((date) => Number(endOfDay(date!)));

        const min = new Date(Math.max(...minInnmeldingDatoer, Number(DEFAULT_INNMELDING_DATO.min)));
        const max = new Date(Math.min(...maxInnmeldingDatoer, Number(DEFAULT_INNMELDING_DATO.max)));

        return {
            min,
            max
        };
    })();

    const minimumLonn =
        avtaler
            .map((avtale) => avtale.minimumLonn || 0)
            .sort((a, b) => b - a)
            .shift() || 0;

    useEffect(() => {
        if (lonn && lonn.length > 0 && !errors.lonn && (Number(lonn) <= minimumLonn || Number(lonn) > 10000000)) {
            const timer = setTimeout(() => {
                setShowSykelonnWarning(true);
            }, 777);
            return () => clearTimeout(timer);
        }
        return setShowSykelonnWarning(false);
    }, [lonn, errors, minimumLonn]);

    const hasForLavLonn = Number(lonn) <= minimumLonn;

    const hasForHoyLonn = Number(lonn) > 10000000;

    const hasChosenOnlySykelonn =
        avtaler.length === 1 && avtaler[0].avtaleProdukt.produktkode === AvtaleProduktID.SYKELONN;

    const hasChosenOnlyReiseNering =
        avtaler.length === 1 && avtaler[0].avtaleProdukt.produktkode === AvtaleProduktID.REISE_NERING;

    const hasChosenReiseNering = () => {
        return avtaler.some((avtale) => avtale.avtaleProdukt.produktkode === AvtaleProduktID.REISE_NERING);
    };

    const onSubmit = useCallback(
        (data: FormState) => {
            // Fjerne Sykelønn fra valgte forsikringer dersom lønn ikke er i henhold til regler
            const avtaler =
                hasForLavLonn || hasForHoyLonn
                    ? data.avtaler.filter((avtale) => avtale.avtaleProdukt.produktkode !== AvtaleProduktID.SYKELONN)
                    : data.avtaler;

            const innmeldingData: model.OppdateringRequest = {
                innmeldinger: [
                    {
                        indeks: data.insured!.indeks,
                        fodselsnummer: data.insured!.fodselsnummer,
                        email: data.email || "",
                        innmeldtDato: data.innmeldtDato ? toBackendDateFormat(data.innmeldtDato) : "",
                        arbeidsDyktig: utils.resolveArbeidsdyktighet(data.isArbeidsdyktig),
                        lonn: Number(data.lonn),
                        avtaler: avtaler.map(({ avtaleId, gruppeId, fagsystem }) => ({
                            avtaleId,
                            fagsystem,
                            gruppeId: gruppeId!
                        }))
                    }
                ]
            };

            mutation.mutate(innmeldingData, {
                onSuccess: () => {
                    props.onMutationSuccess();
                    props.onFrem();
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasForHoyLonn, hasForLavLonn]
    );

    useEffect(() => {
        if (hasChosenOnlyReiseNering) {
            handleSubmit(onSubmit)();
        }
    }, [handleSubmit, hasChosenOnlyReiseNering, onSubmit]);

    const cannotMeldesInn = showArbeidsdyktighetWarning || showSykelonnWarning;

    if (hasChosenOnlyReiseNering) {
        if (mutation.error) {
            return (
                <ErrorMessageBox title="Vi har gjort en feil">
                    Beklager, vi har en feil i våre systemer. Prøv igjen senere eller kontakt oss på telefon.
                </ErrorMessageBox>
            );
        }

        if (mutation.isPending) {
            return <Loader textDescription={`Melder inn ${insuredName}`} />;
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {showArbeidsdyktighetPrompt && (
                <RadioButtonGroup
                    legend={`Er ${insuredName} 100% arbeidsdyktig?`}
                    labelProps={{
                        variant: "medium"
                    }}
                    helpLabel="100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                    errorLabel={errors.isArbeidsdyktig?.message}
                >
                    <RadioButton
                        value="Ja"
                        label="Ja"
                        {...register("isArbeidsdyktig", {
                            required:
                                "Du må svare “Ja” eller “Nei”. 100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                        })}
                    />
                    <RadioButton
                        value="Nei"
                        label="Nei"
                        {...register("isArbeidsdyktig", {
                            required:
                                "Du må svare “Ja” eller “Nei”. 100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                        })}
                    />
                </RadioButtonGroup>
            )}
            {showArbeidsdyktighetWarning && (
                <WarningMessageBox className="jkl-spacing-2xl--top" title="Advarsel">
                    {insuredName} kan ikke meldes inn fordi vedkommende ikke er 100% arbeidsdyktig.
                </WarningMessageBox>
            )}
            <div hidden={showArbeidsdyktighetWarning}>
                <div className="jkl-spacing-l--top jkl-spacing-l--bottom">
                    <DatePicker
                        density={density}
                        label="Startdato"
                        labelProps={{ variant: "medium" }}
                        {...register("innmeldtDato", {
                            validate: (startdato: any) => {
                                const _startdato = parseDateString(startdato as unknown as string | undefined);

                                // antagelig ugyldig dato
                                if (startdato && !_startdato) {
                                    return "Du må velge en gyldig dato.";
                                }

                                if (!_startdato) {
                                    return;
                                }

                                if (
                                    _startdato.getTime() > innmeldingDato.max.getTime() ||
                                    _startdato.getTime() < innmeldingDato.min.getTime()
                                ) {
                                    return `Startdatoen kan ikke være mer enn 30 dager frem eller tilbake i tid.`;
                                }

                                return getAldersgrenseText();
                            },
                            required: `Du må velge datoen du ønsker at ${insuredName} skal være forsikret fra.`
                        })}
                        errorLabel={errors.innmeldtDato?.message}
                        disableBeforeDate={formatInput(innmeldingDato.min)}
                        disableAfterDate={formatInput(innmeldingDato.max)}
                        data-testautoid="merinfo-meldinn-startdato"
                        helpLabel={`Dagen du ønsker at ${insuredName} skal være forsikret fra${
                            hasChosenReiseNering()
                                ? ", unntatt for Reise Næring. Startdato for Reise Næring er automatisk neste arbeidsdag etter i dag."
                                : "."
                        }`}
                    />
                </div>

                {showEpostPrompt && (
                    <div data-testid="merinfo-epost">
                        <TextInput
                            className="skjema__content__controller jkl-spacing-l--bottom"
                            {...register("email", {
                                required: `Du må fylle inn en gyldig e-post. ${insuredName} må svare på et helsevurderingsskjema for å bli forsikret.`,
                                validate: (value) =>
                                    (value && isValidEpost(value)) ||
                                    `Du må fylle inn en gyldig e-post. ${insuredName} må svare på et helsevurderingsskjema for å bli forsikret.`
                            })}
                            data-testautoid="meldinn-merinfo-epost"
                            label={`${insuredName} sin e-post`}
                            placeholder="E-post"
                            type="email"
                            labelProps={{ variant: "medium" }}
                            density={density}
                            errorLabel={errors.email?.message}
                            width={"420px"}
                        />
                    </div>
                )}

                {showLonnPrompt && (
                    <div>
                        <CurrencyInput
                            data-testid="merinfo-lonn"
                            className="skjema__content__controller"
                            {...{ register, setValue }}
                            name="lonn"
                            rules={{
                                required: "Du må fylle inn lønn.",
                                pattern: {
                                    value: /^\d+$/,
                                    message: "Du må fylle inn en gyldig lønn."
                                }
                            }}
                            data-testautoid="meldinn-merinfo-lonn"
                            label="Årslønn"
                            labelProps={{ variant: "medium" }}
                            errorLabel={errors.lonn?.message}
                            helpLabel="Årslønnen du fyller inn må være den ansattes utbetalte lønn før skatt."
                            type="text"
                            density={density}
                        />
                    </div>
                )}

                {showSykelonnWarning && (
                    <WarningMessageBox className="jkl-spacing-2xl--top">
                        {hasForLavLonn ? (
                            <>
                                <b>For lav lønn for Sykelønn</b>
                                <br />
                                <span>
                                    Forsikringen Sykelønn hjelper dere med å dekke lønn over 6G (
                                    {formatValuta(minimumLonn)} kr) ved sykdom. NAV dekker lønn opptil 6G og dermed
                                    trenger ikke bedriften å forsikre ansatte med lønn under 6G i Sykelønnsforsikringen.
                                    {!hasChosenOnlySykelonn && (
                                        <>
                                            <br />
                                            <br /> Du kan fortsatt melde inn {insuredName} på andre forsikringer.
                                        </>
                                    )}
                                </span>
                            </>
                        ) : hasForHoyLonn ? (
                            <>
                                <b>For høy lønn for Sykelønn</b>
                                <br />
                                <span>
                                    Hvis du allikevel ønsker å melde inn {insuredName} på Sykelønn, ta kontakt med en
                                    rådgiver.
                                    {!hasChosenOnlySykelonn && (
                                        <>
                                            <br />
                                            <br /> Du kan fortsatt melde inn {insuredName} på andre forsikringer.
                                        </>
                                    )}
                                </span>
                            </>
                        ) : null}
                    </WarningMessageBox>
                )}
            </div>
            {mutation.isError && (
                <ErrorMessageBox className="jkl-spacing-xl--bottom jkl-spacing-2xl--top" title="Vi har gjort en feil">
                    Beklager, vi har en feil i våre systemer. Prøv igjen senere eller kontakt oss på telefon.
                </ErrorMessageBox>
            )}
            <SkjemaFooter>
                <div>
                    {cannotMeldesInn ? (
                        <SecondaryLinkButton
                            to={props.oversiktLink}
                            data-testautoid="meld-inn-knapp-mer-info-til-oversikt"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "meld-inn-knapp-mer-info-til-oversikt"
                                });
                            }}
                        >
                            Tilbake til oversikten
                        </SecondaryLinkButton>
                    ) : (
                        <PrimaryButton
                            dataTestautoid="meld-inn-knapp-mer-info-meldinn"
                            track={[
                                {
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-meld-inn-knapp-mer-info-meldinn"
                                }
                            ]}
                            loader={{
                                showLoader: mutation.isPending,
                                textDescription: "Melder inn person"
                            }}
                        >
                            Meld inn
                        </PrimaryButton>
                    )}
                </div>
                <SecondaryButton
                    onClick={() => props.onTilbake()}
                    dataTestautoid="meld-inn-knapp-mer-info-forrige"
                    track={{
                        hendelse: Trackingkey.Knappetrykk,
                        knappeId: "meld-inn-knapp-mer-info-forrige"
                    }}
                >
                    ← Forrige
                </SecondaryButton>
            </SkjemaFooter>
        </form>
    );
}

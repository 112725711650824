import { useMemo } from "react";

import { AgreementOverview } from "src/model/gen";

import { model } from "@features/agreements";

import { useBruker } from "../features/bruker/queries";
import { TilgjengeligTilgang } from "../features/tilganger/models";
import { Rolle } from "../model/Rolle";
import { Bruker, BrukerRolle } from "../model/bruker.typer";

export const brukerHarRolle = (bruker: Bruker | undefined, rolle: BrukerRolle | BrukerRolle[]) => {
    if (!bruker) {
        return false;
    }

    // bare sjekk om en bruker har roller i det hele tatt
    if (rolle === BrukerRolle.ANY) {
        return !!bruker.roller.length;
    }

    if (Array.isArray(rolle)) {
        // bare sjekk om en bruker har roller i det hele tatt
        if (rolle.includes(BrukerRolle.ANY)) {
            return !!bruker.roller.length;
        }

        return rolle.some((r) => bruker.roller.includes(r));
    }

    return bruker.roller.includes(rolle);
};

export const brukerHarTilgang = (bruker: Bruker | undefined, rolle: BrukerRolle | BrukerRolle[]) => {
    if (!bruker) {
        return false;
    }

    // admin har lov til alt
    if (bruker.roller.includes(BrukerRolle.ADMINISTRATOR)) {
        return true;
    }

    return brukerHarRolle(bruker, rolle);
};

export const useBrukerHarTilgang = (rolle: BrukerRolle | BrukerRolle[]) => {
    const { bruker } = useBruker();

    const harTilgang = useMemo(() => {
        if (!bruker) {
            return null;
        }

        return brukerHarTilgang(bruker, rolle);
    }, [bruker, rolle]);

    return harTilgang;
};

export const getRolleFromTilgjengeligeTilganger = (rolle: Rolle, tilgjengeligeTilganger: TilgjengeligTilgang[]) => {
    return tilgjengeligeTilganger.find((tilgang) => tilgang.rolle === rolle);
};

export const adminRoller: Rolle[] = ["ADMINISTRATOR", "Administrator", "ADMINISTRATOR_DNB"];

export const findAdminRolle = (roller: Rolle[]) => {
    return roller.find((rolle) => adminRoller.includes(rolle));
};

export const visAnsattLenke = (avtale: Array<AgreementOverview>) => {
    const ansattForsikring = avtale.filter((avtale) => avtale.category.includes("ANSATT"));
    const bareEnForsikring =
        ansattForsikring !== undefined && ansattForsikring.filter((avtale) => avtale.productCode).length === 1;
    const harYrkesskade = ansattForsikring.some((avtale) =>
        avtale.productCode.includes(model.AvtaleProduktID.YRKESSKADE)
    );

    if (bareEnForsikring && harYrkesskade) {
        return false;
    }
    return true;
};

export const isRadgiver = (bruker?: Bruker) =>
    bruker
        ? brukerHarRolle(bruker, [BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_KUNDETILGANGER, BrukerRolle.RADGIVER_DNB])
        : false;

import { endOfDay, isAfter, isBefore, isSameDay, isValid } from "date-fns";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { model, utils } from "@features/navneliste";
import { DatePicker, formatInput, parseDateString } from "@fremtind/jkl-datepicker-react";
import { ListItem, UnorderedList } from "@fremtind/jkl-list-react";
import { ErrorMessageBox, InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import { useScreen } from "@fremtind/jkl-react-hooks";

import { FormState, StegProps } from ".";
import { toBackendDateFormat, toNorwegianDateFormat } from "../../../../common/formatting";
import { PrimaryButton, SecondaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Trackable, Trackingkey } from "../../../../tracking";
import { DEFAULT_UTMELDING_DATO } from "../../navneliste.constants";
import { MinMaxDate } from "../Innmelding";

const createUtmeldingsdato = (avtale: model.OppdateringStatusAvtale) => {
    let min = DEFAULT_UTMELDING_DATO.min;

    if (isAfter(endOfDay(new Date(avtale.avtaleEtablertDato)), min)) {
        min = new Date(avtale.avtaleEtablertDato);
    }

    if (avtale.innmeldtDato && isAfter(endOfDay(new Date(avtale.innmeldtDato)), min)) {
        min = new Date(avtale.innmeldtDato);
    }

    return {
        min: min,
        max: DEFAULT_UTMELDING_DATO.max
    };
};

interface MoreInfoProps extends StegProps {
    meldUtButtonProps: {
        track: Trackable | Trackable[];
    };
    oversiktLink: string;
}

export function MoreInfo({ availableAvtaler, onTilbake, onAvbryt, mutation, ...props }: MoreInfoProps) {
    const screen = useScreen();
    const { indeks } = useParams<"indeks">();

    const {
        handleSubmit,
        watch,
        formState: { errors },
        getValues,
        setValue,
        register
    } = useFormContext<FormState>();

    const arbeidsdyktig = watch("arbeidsdyktighet");
    const selectedAvtaler = watch("selectedAvtaler");

    const avtalerArbeidsdyktighetKreves = selectedAvtaler.filter((avtale) => avtale.utmeldingArbeidsdyktigKreves);

    const hasChosenReiseNering = selectedAvtaler.some((avtale) => avtale.produktKode === AvtaleProduktID.REISE_NERING);

    const showArbeidsdyktighetPrompt = avtalerArbeidsdyktighetKreves.length > 0;

    const showArbeidsdyktighetWarning = showArbeidsdyktighetPrompt && arbeidsdyktig === "Nei";

    const hasChosenOnlyAnnenSykdomAndOrGruppeliv =
        selectedAvtaler.filter(
            (avtale) =>
                avtale.produktKode !== AvtaleProduktID.ANNEN_SYKDOM &&
                avtale.produktKode !== AvtaleProduktID.PERSONALGRUPPELIV
        ).length === 0;

    const hasChosenOnlyReiseNering = hasChosenReiseNering && selectedAvtaler.length === 1;

    const showDatePicker = !(showArbeidsdyktighetWarning && hasChosenOnlyAnnenSykdomAndOrGruppeliv);

    const showNesteButton = !arbeidsdyktig
        ? true
        : arbeidsdyktig === "Ja"
          ? true
          : hasChosenOnlyAnnenSykdomAndOrGruppeliv
            ? true
            : false;

    const insured = utils.findPerson(indeks ?? getValues().insuredIndeks, props.insuredList);

    const insuredName = utils.resolvePersonName(insured);

    const muligeAvtalerForUtmelding = utils.getMuligeAvtalerForUtmelding(availableAvtaler || []);

    const utmeldingsDato: MinMaxDate = (() => {
        const utmeldingsDatoer = selectedAvtaler.map((avtale) => createUtmeldingsdato(avtale));

        const minUtmeldingsDatoer = utmeldingsDatoer
            .map((date) => date.min)
            .filter((date) => date && isValid(date))
            .map((date) => Number(date));

        const maxUtmeldingsDatoer = utmeldingsDatoer
            .map((date) => date.max)
            .filter((date) => date && isValid(date))
            .map((date) => Number(date));

        const min = new Date(Math.min(...minUtmeldingsDatoer));
        const max = new Date(Math.max(...maxUtmeldingsDatoer));

        return {
            min,
            max
        };
    })();

    const onSubmit = (data: FormState) => {
        const utmeldingData: model.OppdateringRequest = {
            utmeldinger: [
                {
                    indeks: indeks ?? data.insuredIndeks,
                    utmeldtDato: toBackendDateFormat(hasChosenOnlyReiseNering ? new Date() : data.sluttdato),
                    arbeidsDyktig: utils.resolveArbeidsdyktighet(data.arbeidsdyktighet),
                    avtaler: data.selectedAvtaler.map((avtale) => ({
                        avtaleId: avtale.avtaleId,
                        fagsystem: avtale.fagsystem
                    }))
                }
            ]
        };

        mutation.mutate(utmeldingData, {
            onSuccess: (data) => {
                setValue("utmeldtInsuredName", insuredName);
                props.onMutationSuccess(data);
                props.onFrem();
                props.onFerdig();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {showArbeidsdyktighetPrompt && (
                <RadioButtonGroup
                    legend={`Er ${insuredName} 100% arbeidsdyktig?`}
                    density="compact"
                    helpLabel="100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                    errorLabel={errors.arbeidsdyktighet?.message}
                    data-testid="merinfo-arbeidsdyktighet"
                >
                    <RadioButton
                        value="Ja"
                        label="Ja"
                        {...register("arbeidsdyktighet", {
                            required:
                                "Du må svare “Ja” eller “Nei”. 100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                        })}
                    />
                    <RadioButton
                        value="Nei"
                        label="Nei"
                        {...register("arbeidsdyktighet", {
                            required:
                                "Du må svare “Ja” eller “Nei”. 100% arbeidsdyktig betyr at den ansatte er frisk nok til å arbeide i full stilling, uavhengig av om man faktisk er ansatt i full stilling."
                        })}
                    />
                </RadioButtonGroup>
            )}

            {hasChosenOnlyReiseNering ? (
                <>
                    <h2 className="jkl-heading-3 jkl-spacing-xl--bottom jkl-spacing-xl--top">Meld ut {insuredName}</h2>
                    <InfoMessageBox className="jkl-spacing-2xl--top jkl-spacing-l--bottom" title="Utmeldingsdato">
                        <span data-testid="merinfo-reisenering-info">
                            {`${insuredName} meldes ut i dag og vil ikke være forsikret fra og med i morgen.`}
                        </span>
                    </InfoMessageBox>
                </>
            ) : (
                showDatePicker && (
                    <div data-testid="merinfo-sluttdato" className="jkl-spacing-l--top">
                        <DatePicker
                            density={screen.isSmallDevice ? "compact" : undefined}
                            label="Sluttdato"
                            {...register("sluttdato", {
                                validate: (sluttdato: any) => {
                                    const _sluttdato = parseDateString(sluttdato as string | undefined);

                                    if (!_sluttdato) {
                                        return;
                                    }
                                    if (isAfter(endOfDay(_sluttdato), utmeldingsDato.max)) {
                                        return `${insuredName} kan ikke meldes ut etter ${toNorwegianDateFormat(
                                            utmeldingsDato.max
                                        )}.`;
                                    } else if (
                                        isBefore(_sluttdato, utmeldingsDato.min) &&
                                        !isSameDay(_sluttdato, utmeldingsDato.min)
                                    ) {
                                        return `${insuredName} kan ikke meldes ut før ${toNorwegianDateFormat(
                                            utmeldingsDato.min
                                        )}.`;
                                    }

                                    return true;
                                },
                                required: `Du må velge den siste dagen du ønsker at ${insuredName} skal være forsikret.`
                            })}
                            labelProps={{ variant: "small" }}
                            errorLabel={errors.sluttdato?.message}
                            disableBeforeDate={formatInput(utmeldingsDato.min)}
                            disableAfterDate={formatInput(utmeldingsDato.max)}
                            data-testautoid="merinfo-meldut-sluttdato"
                            helpLabel={`Velg den siste dagen du ønsker at ${insuredName} skal være forsikret${
                                hasChosenReiseNering
                                    ? ", unntatt for Reise Næring. Sluttdato for Reise Næring er automatisk neste arbeidsdag etter i dag."
                                    : "."
                            }`}
                        />
                    </div>
                )
            )}

            {showArbeidsdyktighetWarning && (
                <ErrorMessageBox title="Kan ikke meldes ut" className="jkl-spacing-2xl--top">
                    <p data-testid="merinfo-arbeidsdyktighet-warning">
                        {insuredName} er ikke 100% arbeidsdyktig, og kan derfor ikke bli meldt ut av:
                        <UnorderedList>
                            {avtalerArbeidsdyktighetKreves.map((avtale) => (
                                <ListItem key={avtale.avtaleId}>{avtale.avtaleNavn}</ListItem>
                            ))}
                        </UnorderedList>
                    </p>
                    <p>Ønsker du å melde ut av de andre forsikringene må du gå tilbake og endre valgene dine.</p>
                </ErrorMessageBox>
            )}

            <SkjemaFooter>
                {showNesteButton ? (
                    <>
                        {!showArbeidsdyktighetWarning && (
                            <PrimaryButton
                                data-testid="merinfo-meldut-knapp"
                                dataTestautoid="meld-ut-knapp-mer-info-meld-ut"
                                loader={{
                                    showLoader: mutation.isPending,
                                    textDescription: "Melder ut"
                                }}
                                {...props.meldUtButtonProps}
                            >
                                Meld ut
                            </PrimaryButton>
                        )}
                    </>
                ) : (
                    <SecondaryLinkButton
                        data-testautoid="meld-ut-knapp-mer-info-til-oversikt"
                        onClick={() => onAvbryt()}
                        to={props.oversiktLink}
                    >
                        Tilbake til oversikten
                    </SecondaryLinkButton>
                )}

                {muligeAvtalerForUtmelding.length > 1 ? (
                    <SecondaryButton
                        data-testid="merinfo-forrige-knapp"
                        onClick={() => onTilbake(false, indeks || muligeAvtalerForUtmelding.length === 1 ? 0 : 1)}
                        type="button"
                        dataTestautoid="meld-ut-knapp-mer-info-forrige"
                        track={{
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "meld-ut-knapp-mer-info-forrige"
                        }}
                    >
                        ← Forrige
                    </SecondaryButton>
                ) : (
                    <SecondaryLinkButton onClick={() => onAvbryt()} to={props.oversiktLink}>
                        Avbryt
                    </SecondaryLinkButton>
                )}
            </SkjemaFooter>

            {mutation.isError && (
                <ErrorMessageBox className="jkl-spacing-xl--bottom jkl-spacing-2xl--top">
                    <span className="jkl-heading-4">Vi har gjort en feil</span>
                    <p data-testid="merinfo-arbeidsdyktighet-warning">
                        Vi kunne ikke melde ut {insuredName} akkurat nå. Prøv en gang til eller kom tilbake senere.
                    </p>
                </ErrorMessageBox>
            )}
        </form>
    );
}

import type { TagProps } from "@fremtind/jkl-tag-react";

import { InvoiceDto } from "../../../../model/gen";
import { getTagForStatus } from "../InvoiceReminder/utils";

type Status = InvoiceDto["status"];
type InvoiceEventType = InvoiceDto["invoiceEventType"];
interface Props extends TagProps {
    status: Status;
    dueDate: string | undefined;
    invoiceEventType: InvoiceEventType;
}

export const StatusTag = ({ status, dueDate, invoiceEventType, ...rest }: Props) => {
    const [T, body] = getTagForStatus(status, dueDate, invoiceEventType);

    if (!T) {
        return null;
    }

    return <T {...rest}>{body}</T>;
};

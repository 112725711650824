import { useContext } from "react";

import ThemeContext from "src/context/ThemeContext";
import { Distributor } from "src/model/theme.typer";
import { Trackingkey, track } from "src/tracking";

import { SecondaryLinkButton } from "@components/Button";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { Typography } from "@components/Typography";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { SelfServiceSection } from "@features/kjop/components";
import { ProductAccordions } from "@features/kjop/components/ProductAccordions/ProductAccordions";
import { NavCard } from "@fremtind/jkl-card-react";

import "./KjopForsikring.scss";

export const mostPopularProducts = {
    ANSVAR: AvtaleProduktID.ANSVAR,
    TRAKTOR_ARBEIDSMASKIN_NERING: AvtaleProduktID.TRAKTOR_ARBEIDSMASKIN_NERING,
    BEHANDLINGSFORSIKRING: AvtaleProduktID.BEHANDLINGSFORSIKRING,
    EIENDELER: AvtaleProduktID.EIENDELER,
    PERSONALGRUPPELIV: AvtaleProduktID.PERSONALGRUPPELIV,
    NÆRINGSBYGG: AvtaleProduktID.NÆRINGSBYGG,
    TRANSPORT_OMSETNING_VARER: AvtaleProduktID.TRANSPORT_OMSETNING_VARER,
    YRKESSKADE: AvtaleProduktID.YRKESSKADE
} as const;

export const KjopForsikring = () => {
    const { distributor } = useContext(ThemeContext);

    const formLink = "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/kontakt-meg.html";

    return (
        <Side>
            <SideHeader
                title="Kjøp forsikring"
                lead="Vi har noen forsikringer du kan kjøpe selv på nett og noen du må få hjelp fra rådgiver til. "
            />
            <SideInnhold className="bm-purchase">
                <SelfServiceSection />
                {distributor === Distributor.SB1 && (
                    <section className="bm-purchase__selfservice jkl-spacing-64--bottom">
                        <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                            Kjøp med hjelp av rådgiver
                        </Typography>
                        <div className="bm-purchase__selfservice__cards">
                            <NavCard
                                target="_blank"
                                onClick={() =>
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "kjopforsikring-be-om-tilbud"
                                    })
                                }
                                title="Be om tilbud"
                                description="Våre rådgivere hjelper deg med de forsikringene du trenger."
                                href={formLink}
                            />
                        </div>
                    </section>
                )}
                <section className="bm-purchase__selfservice jkl-spacing-64--bottom">
                    <Typography component="h2" variant="heading-2" className="jkl-spacing-l--bottom">
                        Mest populære forsikringer
                    </Typography>
                    <ProductAccordions products={mostPopularProducts} />
                    <SecondaryLinkButton
                        to="alle"
                        onClick={() =>
                            track({
                                hendelse: Trackingkey.LenkeTrykk,
                                lenkeId: "kjopforsikring-se-alle-forsikringer"
                            })
                        }
                    >
                        Se alle våre forsikringer
                    </SecondaryLinkButton>
                </section>
            </SideInnhold>
        </Side>
    );
};

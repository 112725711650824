import { LoaderFunction, redirect } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

import { agreementsListQuery } from "@features/agreements/agreements.queries";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";

export const loader: LoaderFunction = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });
    if (bruker.gjeldendeOrganisasjonsnummer) {
        const dataFetcher = agreementsListQuery(bruker.gjeldendeOrganisasjonsnummer);
        return {
            agreementOverviewResponse: dataFetcher
        };
    }
    return redirect("/feil");
};

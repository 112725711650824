import { brukerHarTilgang, isRadgiver } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import type { AgreementDetails } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { SecondaryLinkButton } from "@components/Button";
import { AvtaleProduktID, AvtaleProduktIDMedInnmelding } from "@features/agreements/avtale.model";
import { utils } from "@features/agreements/avtale.module";
import { useBruker } from "@features/bruker/queries";
import { MeldInnKjoretoyButton } from "@features/kjoretoy/components";

import "./AgreementDetailsButtons.scss";

interface AgreementButtonsProps {
    agreementDetails: AgreementDetails;
}

export const AgreementDetailsButtons = ({ agreementDetails }: AgreementButtonsProps) => {
    const { bruker } = useBruker();
    const canChangeSickPay = brukerHarTilgang(bruker, BrukerRolle.PERSONAL) || isRadgiver(bruker);

    const productId = utils.asAvtaleProduktId(agreementDetails.productCode);
    const isSykelonn = productId === AvtaleProduktID.SYKELONN;
    const isNumberBased = agreementDetails.groupInsuranceType === "NUMBER_BASED";
    const isNameBased = agreementDetails.groupInsuranceType === "NAME_BASED";
    const notInAcceptancePhase = agreementDetails.status !== "ACCEPT";
    const changeNumberBasedAgreement = agreementDetails.changeable && isNumberBased;
    const hasEmployeeAdministration = Object.values(AvtaleProduktIDMedInnmelding).includes(productId);
    const isFleetAgreement = utils.isFlateAvtale(productId);

    const changeEmployeeAmountButton = changeNumberBasedAgreement && notInAcceptancePhase;
    const changeEmployeesButton = hasEmployeeAdministration && isNameBased && notInAcceptancePhase;
    const sickPayButton = isSykelonn && canChangeSickPay;
    const fleetButton = isFleetAgreement;

    const showButtons = changeEmployeesButton || changeEmployeeAmountButton || sickPayButton || fleetButton;

    if (!showButtons) return null;

    return (
        <div className="agreement-details__actions">
            {changeEmployeeAmountButton && (
                <SecondaryLinkButton to={`/ansatte-antall/endre-antall/${agreementDetails.id}`}>
                    Endre antall ansatte
                </SecondaryLinkButton>
            )}

            {changeEmployeesButton && (
                <SecondaryLinkButton
                    to="/ansatte-navn"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "ansatte-meld-inn-ut-knapp"
                        });
                    }}
                    data-testautoid="detaljer-meld-inn-knapp"
                >
                    Meld inn/ut ansatte
                </SecondaryLinkButton>
            )}

            {sickPayButton && (
                <SecondaryLinkButton
                    to="/ansatte-navn/endre-sykelonn"
                    lenkeId="endre-sykelønn-oversikt"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "endre-sykelønn-oversikt"
                        });
                    }}
                    data-testautoid="detaljer-endre-sykelonn-knapp"
                >
                    Endre lønn på Sykelønn
                </SecondaryLinkButton>
            )}

            {fleetButton && (
                <>
                    <SecondaryLinkButton
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.LenkeTrykk,
                                lenkeId: "endre-flate-oversikt"
                            });
                        }}
                        to={`/forsikringer/kjoretoy?avtalenummer=${agreementDetails.agreementId}`}
                    >
                        Se kjøretøy
                    </SecondaryLinkButton>
                    <MeldInnKjoretoyButton />
                </>
            )}
        </div>
    );
};

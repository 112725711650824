import { BMRoute } from "src/sider";

import { TasksOverview } from "./views";

export const tasksRoutes: BMRoute[] = [
    {
        path: "varslinger",
        children: [
            {
                element: <TasksOverview />,
                breadcrumbLabel: "Varslinger",
                index: true
            }
        ]
    }
];

import { useMemo } from "react";

import { toNorwegianDateFormat } from "src/common/formatting";
import { brukerHarTilgang, isRadgiver } from "src/common/rolle";
import { SecondaryLinkButton } from "src/components/Button";
import { useScreen } from "src/context/UseScreenContext";
import { BrukerRolle } from "src/model/bruker.typer";
import { KundeFeilmelding } from "src/model/feilkoder.object";
import { Trackingkey, track } from "src/tracking";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { isParisAvtale } from "@features/agreements/avtale.utils";
import { useBruker } from "@features/bruker/queries";
import { model } from "@features/navneliste";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { Loader } from "@fremtind/jkl-loader-react";
import { Table, TableBody, TableCell, TableRow } from "@fremtind/jkl-table-react";
import { UseQueryResult } from "@tanstack/react-query";

interface InsuredTableDetailsProps {
    insured: model.Person;
    isOpen: boolean;
    headers: model.ListHeaderCell[];
    tableColumnWidths: Array<number | undefined>;
    useDetails: (
        indeks: string,
        enabled: boolean
    ) => UseQueryResult<{ entity: model.Detaljer; feilmelding?: KundeFeilmelding }>;
    meldUtButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
    meldInnButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
    kanMeldesInn: boolean;
}

enum ETableRow {
    MELDT_INN = "Meldt inn",
    GRUPPE = "Gruppe",
    SIST_ENDRET = "Sist endret",
    ARSLONN = "Årslønn",
    DAGPENGEBELOP = "Dagpengebeløp",
    ARLIG_PREMIE = "Årspris"
}

type TableRows = Record<AvtaleProduktID, Record<ETableRow, string | number | undefined>>;

export function InsuredTableDetails(props: InsuredTableDetailsProps) {
    const query = props.useDetails(props.insured.indeks, props.isOpen);
    const { isSmallDevice } = useScreen();
    const { bruker } = useBruker();

    const rows = useMemo(() => {
        const bedriftHarSykelonn = props.headers.some((header) => header.produktkode === AvtaleProduktID.SYKELONN);
        const bedriftHarPersonalgruppeliv = props.headers.some((header) =>
            isParisAvtale(header.produktkode as AvtaleProduktID)
        );
        const rowArr = [ETableRow.MELDT_INN, ETableRow.GRUPPE];

        if (bedriftHarPersonalgruppeliv) {
            rowArr.push(ETableRow.ARLIG_PREMIE);
        }

        if (bedriftHarSykelonn) {
            rowArr.push(ETableRow.SIST_ENDRET, ETableRow.ARSLONN, ETableRow.DAGPENGEBELOP);
        }

        return rowArr;
    }, [props.headers]);

    const canChangeSykelonn = props.insured.avtaler.some(
        (avtale) => avtale.innmeldingStatus === "INNMELDT" && avtale.produktkode === AvtaleProduktID.SYKELONN
    );

    const tableRows = props.headers.reduce<TableRows>(
        (prev, header) => {
            const avtale = query.data?.entity.avtaler.find((avtale) => avtale.produktkode === header.produktkode);

            prev[header.produktkode as AvtaleProduktID] = {
                [ETableRow.MELDT_INN]: avtale?.innmeldtDato
                    ? (toNorwegianDateFormat(avtale.innmeldtDato) ?? undefined)
                    : undefined,
                [ETableRow.GRUPPE]: avtale?.gruppeNavn,
                [ETableRow.SIST_ENDRET]: avtale?.endretDato
                    ? (toNorwegianDateFormat(avtale.endretDato) ?? undefined)
                    : undefined,
                [ETableRow.ARSLONN]: avtale?.arslonn ? formatValuta(avtale.arslonn) : undefined,
                [ETableRow.DAGPENGEBELOP]: avtale?.dagpengebelop ? formatValuta(avtale.dagpengebelop) : undefined,
                [ETableRow.ARLIG_PREMIE]: avtale?.arligPremie ? formatValuta(avtale.arligPremie) : undefined
            };

            return prev;
        },
        {} as unknown as TableRows
    );

    const SubLinks = () => {
        return (
            <div className="insured-table__sub-list-links">
                <SecondaryLinkButton
                    lenkeId="ansatte-meld-ut-knapp"
                    density="compact"
                    to={props.meldUtButtonProps.to(props.insured)}
                    onClick={props.meldUtButtonProps.tracking}
                >
                    Meld ut
                </SecondaryLinkButton>

                {query.data && props.meldInnButtonProps && props.kanMeldesInn && (
                    <SecondaryLinkButton
                        density="compact"
                        to={props.meldInnButtonProps.to(props.insured)}
                        onClick={props.meldInnButtonProps.tracking}
                    >
                        Meld inn
                    </SecondaryLinkButton>
                )}

                {query.data &&
                    canChangeSykelonn &&
                    (brukerHarTilgang(bruker, BrukerRolle.PERSONAL) || isRadgiver(bruker)) && (
                        <SecondaryLinkButton
                            lenkeId="endre-sykelønn-detaljer"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "ansatte-endre-sykelønn"
                                });
                            }}
                            density="compact"
                            to={`endre-sykelonn/${props.insured.indeks}`}
                        >
                            Endre lønn på sykelønn
                        </SecondaryLinkButton>
                    )}
            </div>
        );
    };

    return (
        <div className="insured-table__sub-list-wrapper">
            {query.isFetching && (
                <div className="insured-table__sub-list-loader">
                    <Loader textDescription="Henter ansattdetaljer" />
                </div>
            )}
            {isSmallDevice && <SubLinks />}
            <Table fullWidth className="insured-table__sub-list" collapseToList>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row}>
                            <TableCell width={`${(props.tableColumnWidths[0] ?? 0) - 104}px`}>{row}</TableCell>
                            {props.headers.map((header, i) => (
                                <TableCell
                                    key={header.produktkode}
                                    width={`${props.tableColumnWidths[i + 1]}px`}
                                    data-th={header.avtaleNavn}
                                >
                                    {tableRows[header.produktkode as AvtaleProduktID][row] ??
                                        (isSmallDevice ? <>&ndash;</> : null)}
                                </TableCell>
                            ))}
                            <TableCell aria-hidden></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {!isSmallDevice && <SubLinks />}
        </div>
    );
}

import { useEffect } from "react";

import { Link } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";
import type { ClaimOverview } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { DetailTable, DetailTableRow } from "@components/DetailTable/DetailTable";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { Typography } from "@components/Typography";
import { useAgreementList } from "@features/agreements/agreements.queries";
import { ClaimsFAQ } from "@features/claims/components/ClaimsFAQ/ClaimsFAQ";
import "@fremtind/jkl-description-list/description-list.min.css";
import { InfoTag, SuccessTag } from "@fremtind/jkl-tag-react";

import styles from "./ClaimDetails.module.scss";

interface Props {
    claimsDetails: ClaimOverview;
}

export const ClaimsDetails = ({ claimsDetails }: Props) => {
    const agreements = useAgreementList();
    const agreementID = agreements.data?.agreements.find(
        (agreement) => agreement.agreementId === claimsDetails.agreementNumber
    )?.id;

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeSkadesak,
            status: claimsDetails.status,
            beskrivelse: claimsDetails.claimEventDescription,
            produkt: claimsDetails.productCode,
            startet: claimsDetails.claimDate,
            tittel: claimsDetails.productName,
            avtaledetaljer: claimsDetails.agreementDetails
        });
    }, [claimsDetails]);

    return (
        <Side className={styles.claimDetailsPage}>
            <SideHeader title={claimsDetails.productName} />
            <SideInnhold className={styles.claimDetailsWrapper}>
                <div className={styles.claimDetails}>
                    <div className={styles.info}>
                        <div className={styles.details}>
                            <Typography component="h2" variant="heading-2">
                                Om skaden
                            </Typography>
                            <DetailTable>
                                {claimsDetails.claimEventDescription && (
                                    <DetailTableRow
                                        label="Beskrivelse"
                                        value={`${
                                            claimsDetails.claimEventDescription.charAt(0).toUpperCase() +
                                            claimsDetails.claimEventDescription.slice(1).toLocaleLowerCase()
                                        }.`}
                                    />
                                )}
                                <DetailTableRow
                                    label="Status"
                                    value={
                                        claimsDetails.status === "Avsluttet" ? (
                                            <SuccessTag>{claimsDetails.status}</SuccessTag>
                                        ) : (
                                            <InfoTag>{claimsDetails.status}</InfoTag>
                                        )
                                    }
                                />
                                <DetailTableRow
                                    label="Skadedato"
                                    value={toNorwegianDateFormat(claimsDetails.claimDate)}
                                />
                                <DetailTableRow label="Skadenummer" value={claimsDetails.claimNumber} />
                                <DetailTableRow
                                    label="Forsikring"
                                    value={
                                        <Link
                                            to={`/forsikringer/${agreementID}`}
                                            aria-label={claimsDetails.name}
                                            className="jkl-nav-link"
                                        >
                                            {claimsDetails.agreementDetails
                                                ? claimsDetails.agreementDetails
                                                : `Gå til forsikring (${claimsDetails.name})`}
                                        </Link>
                                    }
                                />
                            </DetailTable>
                        </div>

                        <div className={styles.faq}>
                            <Typography component="h2" variant="heading-4">
                                Vanlige spørsmål om skadesaker
                            </Typography>
                            <ClaimsFAQ />
                        </div>
                    </div>
                    <img
                        src="/bedrift/static/images/claim_details.webp"
                        alt="Kraftig regn over et hus"
                        className={styles.image}
                    />
                </div>
            </SideInnhold>
        </Side>
    );
};

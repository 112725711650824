import { Suspense, lazy } from "react";

import { FeedbackProps } from "@components/GlobalFeedback";

import "./SideFooter.scss";

export type FooterProps = {
    feedback?: boolean;
    feedbackProps?: FeedbackProps;
};

export const SideFooter = ({ feedback = true, feedbackProps }: FooterProps) => {
    const GlobalFeedback = lazy(() =>
        import("@components/GlobalFeedback/GlobalFeedback").then((module) => ({ default: module.GlobalFeedback }))
    );

    return (
        <Suspense fallback={<></>}>
            <footer className="side-footer">{feedback && <GlobalFeedback {...feedbackProps} />}</footer>
        </Suspense>
    );
};

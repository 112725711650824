import { queryClient } from "src/common/queryClient";
import { AgreementDetails, AgreementOverviewResponse, Vehicle } from "src/model/gen";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

const agreementsQueryKey = {
    list: (orgnr: string) => [`agreement-overview/${orgnr}`],
    detail: (indeks: string, orgnr: string) =>
        [...agreementsQueryKey.list(orgnr), `agreement-details/${indeks}`] as const,
    vehicles: (indeks: string, orgnr: string) => [...agreementsQueryKey.list(orgnr), `vehicles/${indeks}`] as const
};

export const getAgreementList = async (organisationNumber: string) => {
    const data = await http.get<AgreementOverviewResponse>(`avtale/oversikt/${organisationNumber}`);
    return data;
};
export const agreementsListQuery = (organisationNumber: string) => {
    return queryClient.fetchQuery({
        queryKey: [agreementsQueryKey.list(organisationNumber)],
        queryFn: () => getAgreementList(organisationNumber)
    });
};

export const useAgreementList = () => {
    const bruker = useBruker();

    return useQuery({
        queryKey: [agreementsQueryKey.list(bruker.valgtOrganisasjon?.organisasjonsnummer ?? "")],
        queryFn: () => getAgreementList(bruker.valgtOrganisasjon!.organisasjonsnummer),
        enabled: !!bruker.valgtOrganisasjon
    });
};

export const getAgreementDetails = async (organisationNumber: string, agreeementId: string) => {
    const data = await http.get<AgreementDetails>(`/avtale/detaljer/${organisationNumber}/${agreeementId}`);
    return data;
};

export const agreementDetailsQuery = (organisationNumber: string, agreeementId: string) => {
    return queryClient.fetchQuery({
        queryKey: [agreementsQueryKey.detail(agreeementId, organisationNumber)],
        queryFn: () => getAgreementDetails(organisationNumber, agreeementId)
    });
};

export const useAgreementDetails = (agreementId: string) => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [agreementsQueryKey.detail(agreementId, bruker!.gjeldendeOrganisasjonsnummer!)],
        queryFn: () => getAgreementDetails(bruker!.gjeldendeOrganisasjonsnummer!, agreementId),

        enabled: !!bruker?.gjeldendeOrganisasjonsnummer
    });
};

const getVehicleList = async (organisationNumber: string, agreementId: string) => {
    const data = await http.get<Array<Vehicle>>(`/avtale/flaate/${organisationNumber}/${agreementId}`);
    return data;
};

export const vehicleListQuery = (organisationNumber: string, agreementId: string) => {
    return queryClient.fetchQuery({
        queryKey: [agreementsQueryKey.vehicles(agreementId, organisationNumber)],
        queryFn: () => getVehicleList(organisationNumber, agreementId)
    });
};

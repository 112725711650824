import { useEffect } from "react";

import { FieldError, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { model, utils } from "@features/navneliste";
import { SecondaryButton } from "@fremtind/jkl-button-react";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { ListItem, UnorderedList } from "@fremtind/jkl-list-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { InfoMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";

import { FormState, StegProps } from ".";
import { PrimaryButton, SecondaryLinkButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Trackingkey } from "../../../../tracking";

interface ChooseForsikringProps extends StegProps {
    backLinkTo: string;
}

export function ChooseForsikring({ availableAvtaler, onFrem, onTilbake, onAvbryt, ...props }: ChooseForsikringProps) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext<FormState>();

    const { indeks } = useParams<"indeks">();
    const insured = utils.findPerson(getValues().insuredIndeks, props.insuredList);
    const selectedAvtaleIDs = watch("selectedAvtaleIDs");

    const muligeAvtalerForUtmelding = utils.getMuligeAvtalerForUtmelding(availableAvtaler || []);

    useEffect(() => {
        if (muligeAvtalerForUtmelding?.length === 1) {
            setValue("selectedAvtaler", muligeAvtalerForUtmelding);

            onFrem(true);
        }
    }, [availableAvtaler, muligeAvtalerForUtmelding, onFrem, selectedAvtaleIDs, setValue]);

    if (!availableAvtaler) {
        return (
            <Loader
                className="jkl-spacing-xl--top jkl-spacing-4xl--right"
                textDescription={"Henter innmeldingsstatus for den forsikrede"}
            />
        );
    }

    const apneSkadesakerAvtaler = availableAvtaler.filter(
        (avtale) => avtale.utmeldingStatusKode === model.UtmeldingStatusKode.APEN_SKADESAK
    );

    const hasOnlyApneSkadesaker = apneSkadesakerAvtaler.length === availableAvtaler.length;

    const showArbeidsdyktighetsInfo = (() => {
        if (!selectedAvtaleIDs) {
            return [];
        }

        return selectedAvtaleIDs.filter((avtaleID) =>
            availableAvtaler.find(
                (avtale) => avtale.avtaleId === avtaleID && avtale.utmeldingArbeidsdyktigKreves === true
            )
        );
    })();

    const insuredName = utils.resolvePersonName(insured);

    const onSubmit = async () => {
        setValue(
            "selectedAvtaler",
            availableAvtaler.filter((avtale) => selectedAvtaleIDs.includes(avtale.avtaleId))
        );

        onFrem();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {muligeAvtalerForUtmelding && muligeAvtalerForUtmelding.length === 0 && !hasOnlyApneSkadesaker ? (
                <>
                    <h2 className="jkl-heading-3 jkl-spacing-xl--top">Meld ut {insuredName}</h2>
                    <InfoMessageBox title="Ingen forsikringer å meldes ut av" className="jkl-spacing-xl--top ">
                        {`${insuredName} har ingen forsikringer som det er mulig å melde vedkommende ut av.`}
                    </InfoMessageBox>

                    {indeks ? (
                        <SecondaryLinkButton to={props.backLinkTo} className="jkl-spacing-l--right jkl-spacing-l--top">
                            Tilbake
                        </SecondaryLinkButton>
                    ) : (
                        <SecondaryButton
                            onClick={() => onTilbake()}
                            className="jkl-spacing-l--right jkl-spacing-l--top"
                        >
                            Tilbake
                        </SecondaryButton>
                    )}
                </>
            ) : (
                muligeAvtalerForUtmelding && (
                    <>
                        <FieldGroup
                            legend="Velg forsikringer"
                            errorLabel={(errors.selectedAvtaleIDs as FieldError | undefined)?.message}
                        >
                            {muligeAvtalerForUtmelding.map(({ avtaleId, avtaleNavn }) => (
                                <Checkbox
                                    key={"velgforsikring-checkbox-" + avtaleId}
                                    {...register("selectedAvtaleIDs", {
                                        required: model.Feilmelding.VELG_FORSIKRING_INGEN_FORSIKRING
                                    })}
                                    value={avtaleId}
                                >
                                    {avtaleNavn}
                                </Checkbox>
                            ))}
                        </FieldGroup>

                        {apneSkadesakerAvtaler.length > 0 && (
                            <WarningMessageBox title="Advarsel" className="jkl-spacing-2xl--top">
                                Følgende forsikringer har pågående skadesaker:
                                <span className="jkl-spacing-l--bottom jkl-spacing-l--top">
                                    <menu>
                                        {apneSkadesakerAvtaler.map((avtale) => (
                                            <span
                                                key={"velgforsikring-apen-skadesak-" + avtale.produktKode}
                                                data-testid={"velgforsikring-apen-skadesak-" + avtale.produktKode}
                                            >
                                                <li>{avtale.avtaleNavn}</li>
                                            </span>
                                        ))}
                                    </menu>
                                </span>
                                <span>{insuredName} kan derfor ikke meldes ut av disse.</span>
                            </WarningMessageBox>
                        )}

                        {showArbeidsdyktighetsInfo.length > 0 && (
                            <InfoMessageBox className="jkl-spacing-2xl--top">
                                {insuredName} må være 100% arbeidsdyktig for å meldes ut av:
                                <UnorderedList>
                                    {showArbeidsdyktighetsInfo.map((avtaleID) => (
                                        <ListItem key={avtaleID}>
                                            {
                                                availableAvtaler.find((avtale) => avtale.avtaleId === avtaleID)!
                                                    .avtaleNavn
                                            }
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </InfoMessageBox>
                        )}

                        <SkjemaFooter>
                            {muligeAvtalerForUtmelding?.length !== 0 && (
                                <PrimaryButton
                                    data-testid="velgforsikring-neste-knapp"
                                    dataTestautoid="meld-ut-knapp-velg-forsikring-neste"
                                    track={{
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "meld-ut-knapp-velg-forsikring-neste"
                                    }}
                                >
                                    Neste →
                                </PrimaryButton>
                            )}
                            {indeks ? (
                                <SecondaryLinkButton to={props.backLinkTo} onClick={() => onAvbryt()}>
                                    Avbryt
                                </SecondaryLinkButton>
                            ) : (
                                <SecondaryButton onClick={() => onTilbake()}>← Forrige</SecondaryButton>
                            )}
                        </SkjemaFooter>
                    </>
                )
            )}
        </form>
    );
}

import { ReactNode, useMemo, useState } from "react";

import { Kvittering } from "@components/Kvittering";
import { css } from "@emotion/react";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";

import { PrimaryLinkButton, SecondaryLinkButton } from "../../../../components/Button";
import { ChooseFromList, ChooseFromListProps } from "../../../../components/ChooseFromList";
import { Typography } from "../../../../components/Typography";
import { Trackingkey, track } from "../../../../tracking";
import { TTPKam } from "../../models";
import { useRemoveTilgangerTredjePart } from "../../queries";

type FjerneTilgangerError = {
    title: string;
    body: string;
};

const getError = (): FjerneTilgangerError => {
    return {
        title: "Oi, litt tekniske problemer her!",
        body: "Beklager, noe gikk galt. Vennligst prøv igjen senere, eller ta kontakt med rådgiver."
    };
};

const getFormProps = (options: TTPKam[]): Omit<ChooseFromListProps, "children" | "onSubmit" | "error"> => {
    const kamer = options as TTPKam[];

    return {
        title: `Hvem skal fjernes?`,
        instruction: "Søk og velg bedriftsansvarlig",
        labelprops: { variant: "medium", srOnly: false },
        options: kamer.map(({ kam }) => ({
            label: `${kam.fornavn} ${kam.etternavn}`,
            extraLabel: `(${kam.fodselsnummer})`,
            value: kam.fodselsnummer
        })),
        withSearch: {
            placeholder: "Navn eller fødselsdato",
            noMatchText: "Ingen bedriftsansvarlige passer søket ditt"
        },
        noOptionsContent: (
            <InfoMessageBox title="Infomelding">
                <Typography className="jkl-spacing-l--bottom">
                    Du har ikke opprettet noen bedriftsansvarlige. Velg <i>&quot;Ny bedriftsansvarlig&quot;</i> for å
                    legge til en ny.
                </Typography>
                <PrimaryLinkButton to="/tilganger-andre-bedrifter/ny-bedriftsansvarlig">
                    Ny bedriftsansvarlig
                </PrimaryLinkButton>
            </InfoMessageBox>
        )
    };
};

interface TTPFjernTilgangerProps {
    options: TTPKam[];
    flytnavn: string;
    backButton?: ReactNode;
    doneLinkTo?: string;
}

export const TTPFjernTilganger = (props: TTPFjernTilgangerProps) => {
    const [error, setError] = useState<{ title: string; body: string }>();
    const mutation = useRemoveTilgangerTredjePart();
    const formProps = useMemo(() => getFormProps(props.options), [props.options]);

    const [removedKam, setRemovedKam] = useState<TTPKam>();

    const handleSubmit = (
        checked: string | null | string[],
        kamer: TTPKam[],
        setError: (error: { title: string; body: string }) => void
    ) => {
        const checkedKam =
            props.options.length === 1 ? props.options[0] : kamer.find(({ kam }) => kam.fodselsnummer === checked);

        if (!checkedKam) return;

        const { bedrifter, kam } = checkedKam;

        const promises = bedrifter.map((bedrift) =>
            mutation.mutateAsync({
                fodselsnummer: kam.fodselsnummer,
                organisasjonsnummer: bedrift.bedrift.organisasjonsnummer
            })
        );

        try {
            Promise.all(promises);
            setRemovedKam(checkedKam);
        } catch {
            setError(getError());
        }
    };

    const { fornavn, etternavn } = removedKam?.kam || {};

    if (mutation.isSuccess && removedKam) {
        return (
            <div>
                <Kvittering
                    heading="Flott!"
                    lead={
                        <Typography
                            css={css`
                                margin-top: -2rem;
                            `}
                        >
                            {fornavn} {etternavn} er nå fjernet som bedriftsansvarlig.
                        </Typography>
                    }
                >
                    <SecondaryLinkButton
                        to="/tilganger-andre-bedrifter?tabIndex=1"
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.Skjemaflyt,
                                flytnavn: props.flytnavn,
                                handling: "ferdig",
                                stegnavn: "kvittering",
                                programmatisk: false,
                                omstart: false
                            });
                        }}
                    >
                        Ferdig
                    </SecondaryLinkButton>
                </Kvittering>
            </div>
        );
    }

    return (
        <ChooseFromList
            asSelect
            {...formProps}
            onSubmit={({ checked }) => handleSubmit(checked, props.options, setError)}
            error={error}
            required="Du må velge én bedriftsansvarlig for å kunne gå videre."
        >
            <PrimaryButton loader={{ showLoader: mutation.isPending, textDescription: "lagrer nye tilganger" }}>
                Fjern
            </PrimaryButton>
            {props.backButton || (
                <SecondaryLinkButton
                    to="/tilganger-andre-bedrifter?tabIndex=1"
                    type="secondary"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.Skjemaflyt,
                            flytnavn: props.flytnavn,
                            handling: "avbryt",
                            stegnavn: "fjern tilganger",
                            programmatisk: false
                        });
                    }}
                >
                    Avbryt
                </SecondaryLinkButton>
            )}
        </ChooseFromList>
    );
};

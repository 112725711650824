import { useBruker } from "@features/bruker/queries";

export const useABTest = (variant: "A" | "B") => {
    const { bruker } = useBruker();

    if (!bruker) return null;
    const organizationNumber = bruker.gjeldendeOrganisasjonsnummer;
    if (!organizationNumber) return null;

    const isEven = parseInt(organizationNumber) % 2 === 0;

    return variant === "A" ? isEven : !isEven;
};

import { useEffect, useRef, useState } from "react";

import type { AgreementDetailObject, AgreementDetails } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { utils } from "@features/agreements/avtale.module";
import { useBruker } from "@features/bruker/queries";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { ContextualMenu, ContextualMenuItem } from "@fremtind/jkl-contextual-menu-react";
import { ChevronDownIcon, ChevronUpIcon } from "@fremtind/jkl-icons-react";
import type { ModalInstance } from "@fremtind/jkl-modal-react";

import { AgreementChangesComposer } from "../../AgreementChanges";
import { GetFormType } from "../../AgreementChanges/GetFormType";
import { GetFormData } from "../../AgreementChanges/forms/cancel/GetFormData";
import styles from "./AgreementObjectCard.module.scss";
import { CoverageTable } from "./CoverageTable/CoverageTable";

interface Props {
    object: AgreementDetailObject;
    agreementDetails: AgreementDetails;
    objectNumber: number;
    objectsSum: number;
}

export const AgreementObjectCard = ({ object, agreementDetails, objectNumber, objectsSum }: Props) => {
    const dialogRef = useRef<ModalInstance | null>();
    const { bruker } = useBruker();
    const changable = bruker?.radgiverKontaktInfo?.navn === "KUNDESENTER SMB" && agreementDetails.coreSystem === "NICE";
    const changeAbleAgreementForm = changable ? GetFormType(agreementDetails.productCode) : null;

    const agreementSpecific = changable ? GetFormData(agreementDetails) : null;

    const [formAction, setFormAction] = useState<"cancel" | "change">("cancel");
    const [iconState, setIconState] = useState(false);
    const [objectName, setObjectName] = useState("");

    const handleFormClick = (objectName: string) => {
        setObjectName(objectName);
        dialogRef.current?.show();
    };

    useEffect(() => {
        handleFormClick(objectName);
    }, [formAction, objectName]);

    return (
        <div className={styles.card + " " + styles.objekt}>
            {changable && (
                <div className="agreement-changes-menu-wrapper">
                    {agreementSpecific?.showCancelForm ? (
                        <ContextualMenu
                            className={"agreement-changes-menu"}
                            initialPlacement="bottom-start"
                            triggerElement={
                                <PrimaryButton
                                    onClickCapture={() => {
                                        setIconState(!iconState);
                                        track({
                                            hendelse: Trackingkey.Knappetrykk,
                                            knappeId: "endre",
                                            dropdown: true
                                        });
                                    }}
                                    iconRight={iconState ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                    type="button"
                                >
                                    Endre
                                </PrimaryButton>
                            }
                        >
                            <ContextualMenuItem
                                className="agreement-changes-menu-item"
                                aria-label={`Bestill endring på ${object.name}`}
                                onClick={() => {
                                    handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "bestill endring"
                                    });

                                    if (formAction === "cancel") {
                                        setFormAction("change");
                                    } else {
                                        dialogRef.current?.show();
                                    }
                                }}
                            >
                                Bestill endring
                            </ContextualMenuItem>
                            <ContextualMenuItem
                                className="agreement-changes-menu-item"
                                aria-label={`Avslutt ${object.name}`}
                                onClick={() => {
                                    handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                    track({
                                        hendelse: Trackingkey.Knappetrykk,
                                        knappeId: "avslutt avtale"
                                    });
                                    if (formAction === "change") {
                                        setFormAction("cancel");
                                    } else {
                                        dialogRef.current?.show();
                                    }
                                }}
                            >
                                Avslutt avtale
                            </ContextualMenuItem>
                        </ContextualMenu>
                    ) : (
                        <PrimaryButton
                            title="Endre"
                            aria-label={`Bestill endring på ${object.name}`}
                            onClick={() => {
                                handleFormClick(object.name ?? `Objekt ${objectNumber} av ${objectsSum}`);
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "bestill endring",
                                    dropdown: false
                                });
                                if (formAction === "cancel") {
                                    setFormAction("change");
                                } else {
                                    dialogRef.current?.show();
                                }
                            }}
                        >
                            Bestill endring
                        </PrimaryButton>
                    )}
                    {changeAbleAgreementForm && (
                        <AgreementChangesComposer
                            chosenObject={objectNumber > 1 ? objectName : ""}
                            formAction={formAction}
                            formType={changeAbleAgreementForm}
                            avtaleDetaljer={agreementDetails}
                            dialogRef={dialogRef}
                        />
                    )}
                </div>
            )}
            <header className={styles.cardHeader}>
                <Typography variant="heading-3">{object.name ?? `Objekt ${objectNumber} av ${objectsSum}`}</Typography>
            </header>

            <dl className={styles.fields}>
                {object.fields.map(({ description, value, type }) => (
                    <div key={description} className={styles.fieldDetail}>
                        <dt>{description}</dt>
                        <dd>{utils.formatValue(value, type)}</dd>
                    </div>
                ))}
            </dl>

            <CoverageTable coverages={object.coverageTable} />
        </div>
    );
};

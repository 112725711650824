import { Children, ReactElement, cloneElement, isValidElement } from "react";

import { VariantProps } from "./Variant";

type ABTestProps = {
    testName: string;
    children: ReactElement<VariantProps> | ReactElement<VariantProps>[];
};

export const ABTest: React.FC<ABTestProps> = ({ testName, children }) => {
    const enrichedChildren = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { testName });
        }
        return child;
    });

    return <>{enrichedChildren}</>;
};

import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Trackingkey, track } from "src/tracking";

import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { PrimaryButton, TertiaryButton } from "@fremtind/jkl-button-react";
import { formatNumber } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { Select } from "@fremtind/jkl-select-react";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { TextArea } from "@fremtind/jkl-text-input-react";

import "../../AgreementChanges.scss";
import { ContactInfoInputs } from "../../ContactInfoInputs";
import { useEndreAvtaledetaljer } from "../../agreementchanges.queries";
import { FormProps } from "../FormProps";
import { FormDataChanges } from "../SkjemaKontroller";
import { GetFormData } from "./GetFormData";

export interface AvtaleTilEndring {
    nykmstand?: string;
    nykjorelengde?: string;
    annet?: string;
    epost: string;
    telefon?: string;
}

export type FieldValues = {
    avtale: AvtaleTilEndring;
};

export const VehicleForm = (props: FormProps) => {
    useEffect(() => {
        props.onFerdig();
    }, [props]);

    const { valgtOrganisasjon } = useBruker();

    //reset form if dialog is closed
    if (props.onClose === true) {
        cancelForm();
    }

    const [submitError, setSubmitError] = useState(false);
    const endringerMutation = useEndreAvtaledetaljer();
    const [isLoading, setIsLoading] = useState(false);
    const { register, formState, handleSubmit } = useFormContext<FormDataChanges>();

    const { errors } = formState;

    const agreementObjects = props.avtaleDetaljer?.objects.find((f) => f.fields);

    // find mileage and kmstand values and format them
    const gammelKjorelengde = agreementObjects?.fields.find((f) => f.description === "Kjørelengde")?.value;
    const gammelKjorelengdeAsNumber = returnNumber(gammelKjorelengde);

    const gammelKmStand = agreementObjects?.fields.find(
        (f) => f.description === "Kilometerstand" || f.description === "Km.stand"
    )?.value;
    const gammelKmStandAsNumber = returnNumber(gammelKmStand);

    function returnNumber(value: string | undefined) {
        if (value && value !== "Ubegrenset") {
            return formatNumber(value.replace(/\D/g, ""));
        } else {
            return value;
        }
    }

    const [kjorelengde, setKjorelengde] = useState(gammelKjorelengdeAsNumber);
    const [kmStand, setkmStand] = useState("");
    const [annet, setAnnet] = useState("");
    const [noChangesError, setNoChangesError] = useState(false);
    const [milageRequired, setMilageRequired] = useState(false);
    const [kmStandError, setKmStandError] = useState(false);
    const [kmStandErrorMsg, setKmStandErrorMsg] = useState("");

    // get the correct mileage selections based on product code
    const productode = props.avtaleDetaljer.productCode;
    const mileageSelections = GetFormData(productode);

    function checkForChanges(km?: string, a?: string) {
        if (km !== gammelKmStand || km !== "" || a !== "") {
            setNoChangesError(false);
        }
    }

    function checkIfMilageIsRequired(k: string | undefined = kjorelengde, km: string | undefined) {
        if (k === gammelKjorelengdeAsNumber) {
            setMilageRequired(false);
        }
        if (km) {
            setMilageRequired(false);
        } else {
            setMilageRequired(true);
        }
    }

    function validateKilometerstand(value: string | undefined) {
        if (value) {
            if (value?.toString().match(/\b(\d+)\b/) === null) {
                setKmStandErrorMsg("Kilometerstand må være et tall");
                setKmStandError(true);
            } else if (value?.replace(/\s/g, "").length > 10) {
                setKmStandErrorMsg("Ny kilometerstand er for høy");
                setKmStandError(true);
            } else {
                setKmStandError(false);
            }
        }
    }

    // format the form values to a string
    function formatFormValues(formValues: FormDataChanges) {
        let returnstring = `Organisasjonsnummer: ${valgtOrganisasjon?.organisasjonsnummer}\nOrganisasjonsnavn: ${valgtOrganisasjon?.navn}\nAvtalenummer: ${props.avtaleDetaljer.agreementId}`;
        if (kjorelengde !== gammelKjorelengdeAsNumber && kjorelengde !== "") {
            returnstring += `\nKjørelengde: ${kjorelengde} km årlig`;
        }
        Object.entries(formValues).map(([key, value]) => {
            if (key === "kilometerstand" && value !== "") {
                returnstring += `\nKilometerstand: ${value} km`;
            }
            if (key === "annet" && value !== "") {
                returnstring += `\nFritekst: ${value}`;
            }
        });
        return returnstring;
    }

    function cancelForm() {
        props.dialogRef.current.hide();
        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: "Endre avtale",
            handling: "avbryt",
            stegnavn: "Endre avtale"
        });
    }

    const onSubmit = async (formValues: FormDataChanges) => {
        if (formValues.kilometerstand || formValues.annet) {
            if (props.avtaleDetaljer.id && !kmStandError) {
                setIsLoading(true);
                try {
                    const res = await endringerMutation.mutateAsync({
                        id: props.avtaleDetaljer.id,
                        email: formValues.email,
                        phone: formValues.telefon,
                        message: formatFormValues(formValues) as string
                    });

                    if (!res) {
                        // commitEndringState("REJECTED");
                    } else {
                        // commitEndringState("RESOLVED");
                        props.onFrem();
                        setSubmitError(false);
                        setIsLoading(false);
                    }
                } catch {
                    setSubmitError(true);
                }
            }
        } else {
            setNoChangesError(true);
        }

        Object.keys(formValues).map((key) => {
            switch (key) {
                case "telefon":
                case "email":
                    break;

                case "kjorelengde":
                    track({
                        hendelse: Trackingkey.Endre,
                        type: "endre avtale",
                        avtale: props.avtaleDetaljer.productCode ? props.avtaleDetaljer.productCode : "ukjent",
                        felt: key,
                        tidligereVerdi: gammelKjorelengdeAsNumber,
                        nyVerdi: formValues.kjorelengde ? formValues.kjorelengde : undefined
                    });
                    break;

                case "kilometerstand":
                    track({
                        hendelse: Trackingkey.Endre,
                        type: "endre avtale",
                        avtale: props.avtaleDetaljer.productCode ? props.avtaleDetaljer.productCode : "ukjent",
                        felt: key,
                        tidligereVerdi: gammelKmStandAsNumber,
                        nyVerdi: formValues.kilometerstand ? formValues.kilometerstand : undefined
                    });
                    break;

                default:
                    track({
                        hendelse: Trackingkey.Endre,
                        type: "endre avtale",
                        avtale: props.avtaleDetaljer.productCode ? props.avtaleDetaljer.productCode : "ukjent",
                        felt: key,
                        nyVerdi: formValues[key as keyof FormDataChanges]
                            ? formValues[key as keyof FormDataChanges]
                            : undefined,
                        kommentar: formValues.annet
                    });
                    break;
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="agreement-changes-form">
            <Typography className={"agreement-changes-form__heading"} variant={"heading-3"}>
                Hva vil du endre?
            </Typography>
            <div className="input-fields-horizontal">
                <TextInput
                    {...register("kilometerstand", {
                        required: milageRequired ? "Du må oppgi kilometerstand" : false
                    })}
                    value={kmStand}
                    label={"Ny kilometerstand"}
                    errorLabel={kmStandError ? kmStandErrorMsg : ""}
                    helpLabel={`Dagens kilometerstand: ${gammelKmStandAsNumber}`}
                    onChange={(e) => {
                        setkmStand(formatNumber(e.target.value));
                        checkForChanges(e.target.value, annet);
                        checkIfMilageIsRequired(kjorelengde, e.target.value);
                        validateKilometerstand(e.target.value);
                    }}
                />

                {mileageSelections && (
                    <Select
                        {...register("kjorelengde")}
                        className="jkl-spacing-l--top"
                        name="kjørelengde"
                        value={kjorelengde}
                        label={"Årlig kjørelengde (valgfri)"}
                        defaultPrompt={kjorelengde}
                        items={mileageSelections.map((item) => ({
                            value: `${returnNumber(item)}`,
                            label: `${returnNumber(item)}`
                        }))}
                        onChange={(e) => {
                            setKjorelengde(e.target.value);
                            checkIfMilageIsRequired(e.target.value, kmStand);
                        }}
                    />
                )}
            </div>
            <TextArea
                className="jkl-spacing-32--bottom"
                {...register("annet", {
                    required: false,
                    maxLength: {
                        value: 1000,
                        message: "Meldingen kan ikke være lengre enn 1000 tegn"
                    }
                })}
                startOpen
                label="Andre endringer (valgfri)"
                labelProps={{ variant: "medium" }}
                placeholder={"Hva ønsker du å endre på?"}
                onChange={(e) => {
                    setAnnet(e.target.value);
                    checkForChanges(kmStand, e.target.value);
                }}
                errorLabel={errors.annet?.message}
            />

            <ContactInfoInputs />

            {milageRequired ? (
                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                    <ErrorMessageBox>For å endre kjørelengde må du oppgi kilometerstand.</ErrorMessageBox>
                </div>
            ) : null}

            {noChangesError && (
                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                    <ErrorMessageBox title="">Du må gjøre minst én endring</ErrorMessageBox>
                </div>
            )}

            {submitError && (
                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                    <ErrorMessageBox title="Endringen ble ikke lagret">
                        En teknisk feil har oppstått. Beklager!
                    </ErrorMessageBox>
                </div>
            )}

            <SkjemaFooter>
                <PrimaryButton type="submit" loader={{ showLoader: isLoading, textDescription: "Lagrer endringer" }}>
                    Bekreft og lagre
                </PrimaryButton>
                <TertiaryButton type="button" aria-label="Avbryt endring" onClick={() => cancelForm()}>
                    Avbryt
                </TertiaryButton>
            </SkjemaFooter>
        </form>
    );
};

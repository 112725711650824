import { AgreementDetails } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { Typography } from "@components/Typography";
import { NavCard } from "@fremtind/jkl-card-react";

import "./AgreementDetailsDocuments.scss";

interface Props {
    agreement: AgreementDetails;
}

export const AgreementDocumentCard = ({ agreement }: Props) => {
    const showAgreementPDF = agreement.coreSystem !== "PARIS" && agreement.coreSystem !== "PROLIFE";

    if (!showAgreementPDF) return null;

    return (
        <>
            <NavCard
                title="Avtaledokument"
                description="Inneholder forsikringsbevis og vilkår"
                href={`/bedrift/api/dokumenter/avtale/pdf/${agreement.id}`}
                target="_blank"
                external
                onClick={() => {
                    track({
                        hendelse: Trackingkey.LastNed,
                        enkelt: false,
                        dokumenttype: "Avtaledokument"
                    });
                }}
            />
        </>
    );
};

export const AgreementDetailsDocuments = ({ agreement }: Props) => {
    const showAgreementPDF = agreement.coreSystem !== "PARIS" && agreement.coreSystem !== "PROLIFE";

    if (!showAgreementPDF) return null;

    return (
        <section className="agreement-details-documents">
            <Typography variant="heading-2">Dokumenter</Typography>
            <AgreementDocumentCard agreement={agreement} />
        </section>
    );
};

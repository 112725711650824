import { useContext } from "react";

import ThemeContext from "src/context/ThemeContext";

import { model } from "@features/faq";

export const useFaqItem = (item: model.FaqSchemaItem) => {
    // const avtaler = useAvtaler();
    // const bruker = useBruker();
    const { distributor } = useContext(ThemeContext);

    // todo sjekk rollekrav
    // todo sjekk distributørkrav
    // todo sjekk detaljkrav
    // todo returner content og heading i et objekt

    if (item.requiresDistributor && item.requiresDistributor !== distributor) {
        return undefined;
    }

    return { heading: item.question, content: item.answer };
};

import { useState } from "react";

import { IHttpError } from "src/model/http.typer";

import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";

export type Vehicle = {
    registrationNumber: string;
    make: string;
    model: string;
    registrationYear: number;
    purchased: boolean;
    registrationAge: number;
};

const fetchVehicles = async () => {
    const res = await http.get<Vehicle[]>("kjop/customer/vehicles/V05");
    return res;
};

export const useVehicles = () => {
    const [shouldRunQuery, setShouldRunQuery] = useState(false);
    const query = useQuery({
        queryKey: ["kjop-vehicles"],
        queryFn: fetchVehicles,
        staleTime: 1000 * 60 * 5,
        enabled: shouldRunQuery
    });

    return {
        vehicles: query.data,
        isLoadingVehicles: query.isLoading,
        error: query.error as unknown as IHttpError,
        query,
        setShouldRunQuery
    };
};

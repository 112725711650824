import { useContext } from "react";

import { isRadgiver } from "../../common/rolle";
import { Side, SideHeader, SideInnhold } from "../../components/Side";
import ThemeContext from "../../context/ThemeContext";
import { useBruker } from "../../features/bruker/queries";
import { SamtykkeForm } from "../../features/samtykke/components/SamtykkeForm";
import { useSamtykke } from "../../features/samtykke/queries";
import { Distributor } from "../../model/theme.typer";
import "./Samtykke.scss";

export default function Samtykke() {
    const { bruker, valgtOrganisasjon } = useBruker();
    const { distributor } = useContext(ThemeContext);
    const query = useSamtykke();

    return (
        <Side compact>
            <SideHeader
                title="Samtykke"
                lead="Her kan du administrere samtykke. Du kan når som helst endre samtykket."
            />
            <SideInnhold loadingText="Laster inn samtykke" query={query}>
                {query.data && (
                    <SamtykkeForm
                        isRadgiver={!!isRadgiver(bruker)}
                        isDNB={distributor === Distributor.DNB}
                        orgNavn={valgtOrganisasjon!.navn}
                        samtykke={query.data.samtykke}
                    />
                )}
            </SideInnhold>
        </Side>
    );
}

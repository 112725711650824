import { useBruker } from "@features/bruker/queries";

import { useInvoiceReminders } from "../../invoice.queries";
import { InvoiceReminder } from "./InvoiceReminder";

export const InvoiceReminderDataWrapper = () => {
    const { data } = useInvoiceReminders();
    const { bruker } = useBruker();

    if (!bruker?.valgtOrganisasjon) {
        return null;
    }

    return <InvoiceReminder invoiceReminders={data?.entity.invoiceReminders ?? []} />;
};

import { useEffect, useState } from "react";

import { sanitizeLoginUrl } from "src/common/utils";
import { Trackingkey, track } from "src/tracking";

import { Loader } from "@fremtind/jkl-loader-react";

import { Feil } from "../components/Feil/Feil";
import { Side, SideInnhold } from "../components/Side";
import { useBruker, useSignOut } from "../features/bruker/queries";
import { IHttpError } from "../model/http.typer";
import "./LoggUt.scss";

export function LoggUt() {
    const { bruker, error } = useBruker();
    const signOutMutation = useSignOut();
    const [didLogout, setDidLogout] = useState(false);

    useEffect(() => {
        if (!didLogout && bruker?.loggetInn) {
            setDidLogout(true);
            signOutMutation.mutate();
        }
    }, [bruker, didLogout, signOutMutation]);

    if (signOutMutation.isPending) {
        return <Loader textDescription="Logger ut" />;
    }

    if (signOutMutation.error && (signOutMutation.error as unknown as IHttpError).status !== 401) {
        return <Feil error={error!} />;
    }

    // bruk url returnert fra utloggingskall. hvis ikke bruk kjent bankid-url fra 401 feil om mulig.
    // hvis ikke returner "" for å sende brukeren til forsiden og trigge en tvungen redirect til forsiden
    const authUrl = (() => {
        const loginUrl = signOutMutation.data?.loginurl;

        if (loginUrl) {
            const url = new URL(loginUrl);

            url.searchParams.set("goto", window.location.origin + "/bedrift");
            return url.toString();
        }

        const loginCompleteUrl: string = error?.body?.["login-completeurl"] ?? "";

        if (loginCompleteUrl) {
            const url = sanitizeLoginUrl(new URL(loginCompleteUrl));

            return url.toString();
        }

        return loginCompleteUrl;
    })();

    return (
        <Side>
            <SideInnhold loadingText="Logger ut" className="logg-ut">
                <h1 className="jkl-title logg-ut__header">Takk for nå!</h1>
                <a
                    onClick={() => {
                        track({ hendelse: Trackingkey.Knappetrykk, knappeId: "utlogget-loggin" });
                    }}
                    className="sidemeny__loggut jkl-button jkl-button--primary"
                    href={authUrl}
                >
                    Logg inn
                </a>
            </SideInnhold>
        </Side>
    );
}

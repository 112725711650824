import { useContext, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { useBruker } from "@features/bruker/queries";

import ThemeContext from "../context/ThemeContext";
import { useSamtykke } from "../features/samtykke/queries";
import { Distributor } from "../model/theme.typer";
import { isRadgiver } from "./rolle";

// En hook som ved endring returnerer forrige verdi
export const useForrigeVerdi = <T>(verdi: T): T | undefined => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = verdi;
    });

    return ref.current;
};

export const useEnsureSamtykke = () => {
    const navigate = useNavigate();
    const avtaleQuery = useAgreementList();
    const { distributor } = useContext(ThemeContext);
    const { bruker } = useBruker();

    const samtykkeQuery = useSamtykke();
    const samtykke = samtykkeQuery.data?.samtykke;

    useEffect(() => {
        if (avtaleQuery.data?.agreements.length && samtykke) {
            if (distributor !== Distributor.DNB && samtykke.delingAvData === undefined && !isRadgiver(bruker)) {
                navigate("/samtykke");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avtaleQuery.data?.agreements.length, distributor, samtykke]);
};

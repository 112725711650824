import { useEffect } from "react";

import { ABTest } from "src/tracking";

import { useABTest } from "./useABTest";

export type VariantProps = {
    variantName: string;
    variant: "A" | "B";
    children: React.ReactNode;
    testName?: string;
};

export const Variant: React.FC<VariantProps> = ({ variantName, variant, children, testName }) => {
    const shouldShow = useABTest(variant);

    useEffect(() => {
        if (!shouldShow || !testName) return;

        ABTest(testName, variantName);
    });

    if (!shouldShow) return null;

    return <>{children}</>;
};

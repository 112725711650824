import { BrukerRolle } from "src/model/bruker.typer";
import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import {
    fjernTilgangerBedriftsansvarlig,
    giTilgangBedrift,
    giTilgangBedriftsansvarlig,
    giTilgangNyBedriftsansvarlig,
    tilgangerTredjePart
} from "./views";

export const ttpRoutes: BMRoute[] = [
    {
        path: "tilganger-andre-bedrifter",
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute
                        requiredRolle={[
                            BrukerRolle.ADMINISTRATOR,
                            BrukerRolle.ADMINISTRATOR_DNB,
                            BrukerRolle.RADGIVER,
                            BrukerRolle.RADGIVER_DNB
                        ]}
                    >
                        <tilgangerTredjePart.TilgangerTredjePart />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Tilganger - andre bedrifter"
            },
            {
                path: "gi-tilgang-bedrift/:orgnummer",
                element: (
                    <ProtectedRoute
                        requiredRolle={[
                            BrukerRolle.ADMINISTRATOR,
                            BrukerRolle.ADMINISTRATOR_DNB,
                            BrukerRolle.RADGIVER,
                            BrukerRolle.RADGIVER_DNB
                        ]}
                    >
                        <giTilgangBedrift.GiTilgangBedrift />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Gi tilgang til bedrift"
            },
            {
                path: "gi-tilgang-bedriftsansvarlig/:fodselsnummer",
                element: (
                    <ProtectedRoute
                        requiredRolle={[
                            BrukerRolle.ADMINISTRATOR,
                            BrukerRolle.ADMINISTRATOR_DNB,
                            BrukerRolle.RADGIVER,
                            BrukerRolle.RADGIVER_DNB
                        ]}
                    >
                        <giTilgangBedriftsansvarlig.GiTilgangBedriftsansvarlig />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Gi tilgang til bedriftsansvarlig"
            },
            {
                path: "ny-bedriftsansvarlig",
                element: (
                    <ProtectedRoute
                        requiredRolle={[
                            BrukerRolle.ADMINISTRATOR,
                            BrukerRolle.ADMINISTRATOR_DNB,
                            BrukerRolle.RADGIVER,
                            BrukerRolle.RADGIVER_DNB
                        ]}
                    >
                        <giTilgangNyBedriftsansvarlig.GiTilgangNyBedriftsansvarlig />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Ny bedriftsansvarlig"
            },
            {
                path: "fjern-bedriftsansvarlig",
                element: (
                    <ProtectedRoute
                        requiredRolle={[
                            BrukerRolle.ADMINISTRATOR,
                            BrukerRolle.ADMINISTRATOR_DNB,
                            BrukerRolle.RADGIVER,
                            BrukerRolle.RADGIVER_DNB
                        ]}
                    >
                        <fjernTilgangerBedriftsansvarlig.Composer />
                    </ProtectedRoute>
                ),
                errorElement: <fjernTilgangerBedriftsansvarlig.Error />,
                loader: fjernTilgangerBedriftsansvarlig.loader,
                breadcrumbLabel: "Fjerne bedriftsansvarlig"
            }
        ]
    }
];

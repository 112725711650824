import { Bruker } from "src/model/bruker.typer";

import { InvoiceReminder } from "@features/invoice/components";
import { useInvoiceReminders } from "@features/invoice/invoice.queries";
import { WarningMessageBox } from "@fremtind/jkl-message-box-react";

interface AlertSectionProps {
    bruker: Bruker;
    children?: React.ReactNode;
}

export function AlertSection({ bruker, children }: AlertSectionProps) {
    const { data } = useInvoiceReminders();
    let showInvoiceReminder = false;
    let showDynamicMenuError = false;

    const invoiceReminders = data?.entity?.invoiceReminders ?? [];
    if (invoiceReminders && invoiceReminders.length > 0) {
        showInvoiceReminder = true;
    }
    if (bruker?.dynamicMenu?.rrh.error === true) {
        showDynamicMenuError = true;
    }
    if (showInvoiceReminder || showDynamicMenuError) {
        return (
            <section>
                {showInvoiceReminder && <InvoiceReminder />}
                {showDynamicMenuError && (
                    <WarningMessageBox className="bm-global-alert-message" fullWidth>
                        Behandling av reelle rettighetshavere er for tiden utilgjengelig på grunn av problemer hos
                        underleverandør. Dette gjør også at signering av tilbud er utilgjengelig.
                    </WarningMessageBox>
                )}
                {children}
            </section>
        );
    }
    return null;
}

import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FormState, StegProps } from ".";
import { FolkeregisterSjekk } from "../../../../components/FolkeregisterSjekk";
import { Trackingkey, track } from "../../../../tracking";

interface ChooseInsuredProps extends StegProps {
    oversiktLink: string;
    heading?: string;
}

export const ChooseInsured = (props: ChooseInsuredProps) => {
    const { setValue, watch } = useFormContext<FormState>();
    const navigate = useNavigate();

    const insured = watch("insured");

    return (
        <FolkeregisterSjekk
            header={props.heading ?? "Navn og fødselsnummer"}
            fremButtonText="Neste →"
            onAvbryt={() => navigate(props.oversiktLink)}
            onBekreftet={({ indeks, fodselsnummer, fornavn, etternavn, fodselsdato, alder }) => {
                track({
                    hendelse: Trackingkey.Knappetrykk,
                    knappeId: "meld-inn-knapp-folkeregister-velg-forsikring"
                });

                setValue("insured", { indeks, fodselsnummer, fornavn, etternavn, alder, fodselsdato, avtaler: [] });

                props.onFrem();
            }}
            defaultValues={{
                fodselsnummer: insured?.fodselsnummer || "",
                fornavn: insured?.fornavn || "",
                etternavn: insured?.etternavn || ""
            }}
        />
    );
};

import { type FC, type ReactNode } from "react";

import "./Flow.scss";

interface FlowFooterProps {
    children?: ReactNode;
}

export const FlowFooter: FC<FlowFooterProps> = ({ children }) => {
    return <footer className="flow__footer">{children}</footer>;
};

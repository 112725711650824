import { useContext } from "react";

import { ABTest, Variant } from "src/common/experiments/ABTest";
import { useMarkdown } from "src/common/hooks/useMarkdown";
import ThemeContext from "src/context/ThemeContext";
import { Distributor } from "src/model/theme.typer";
import { Trackingkey, track } from "src/tracking";

import { PrimaryLinkButton } from "@components/Button";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useAgreementInfo } from "@features/agreements/hooks/useAgreementInfo";
import { AccordionItem } from "@fremtind/jkl-accordion-react";

export const ProductAccordion: React.FC<{
    code: AvtaleProduktID;
}> = ({ code }) => {
    const productObject = useAgreementInfo(code);
    const { distributor } = useContext(ThemeContext);

    const DescriptionAsMarkdown = useMarkdown(productObject?.description);
    if (!productObject || !productObject.productInfo) return null;

    const { productInfo } = productObject;

    const distributorHasProduct = !!productInfo.href[distributor];

    if (!distributorHasProduct) return null;

    const { label } = productInfo;

    // Dette er en uggo måte å fikse dette på,
    // vi burde oppdatere @common/products med produktnavn, og ikke bare "Lær mer om/Les mer om XYZ"
    let productNameOnly = label.replace("Lær mer om ", "");

    if (productNameOnly === label) {
        productNameOnly = label.replace("Les mer om ", "");
    }

    const formattedLabel = productNameOnly.charAt(0).toUpperCase() + productNameOnly.slice(1);

    const formLink =
        distributor === Distributor.SB1
            ? "https://www.sparebank1.no/nb/bank/bedrift/bedriftsforsikring/kontakt-meg.html"
            : productInfo.href[distributor];

    return (
        <AccordionItem
            onClick={(e, isOpen) => {
                if (!isOpen) return;

                track({
                    hendelse: Trackingkey.KjopMostPopular,
                    produkt: code,
                    element: "ACCORDION"
                });
            }}
            title={formattedLabel}
        >
            <div className="jkl-spacing-l--bottom">{DescriptionAsMarkdown}</div>
            <PrimaryLinkButton
                onClick={() => {
                    track({
                        hendelse: Trackingkey.KjopMostPopular,
                        produkt: code,
                        element: "BESTILL_KNAPP"
                    });
                }}
                to={formLink || ""}
                target="_blank"
            >
                <ABTest testName="Navn på knapp på ny kjøpsside">
                    <Variant variantName="Be om tilbud" variant="A">
                        Be om tilbud
                    </Variant>

                    <Variant variantName="Bestill forsikring" variant="B">
                        Bestill forsikring
                    </Variant>
                </ABTest>
            </PrimaryLinkButton>
        </AccordionItem>
    );
};

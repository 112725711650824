/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useState } from "react";

import classNames from "classnames";

import { AccordionItem } from "@fremtind/jkl-accordion-react";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { useQueryClient } from "@tanstack/react-query";

import { APIResponse } from "../../../../common/api";
import { toFormattedOrganisasjonsnummer } from "../../../../common/formatting";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../../../../components/Button";
import { Typography } from "../../../../components/Typography";
import { Trackingkey } from "../../../../tracking";
import { TTPBedrift, TTPKam, TTPKamWithoutBedrifter, TTPOversiktResponse } from "../../models";
import { removeTilgangFromCache, ttpQueryKeys, useRemoveTilgangerTredjePart } from "../../queries";
import "./TTPAccordionItem.scss";

interface TTPAccordionItemTitleProps {
    title: string;
    subtitle: string;
    endText: string;
    itemCount: number;
    onClick: () => void;
}

const TTPAccordionItemTitle = (props: TTPAccordionItemTitleProps) => {
    return (
        <div className="ttp-accordion-item__title" onClick={props.onClick}>
            <div>
                <Typography className="ttp-accordion-item__title__title">{props.title}</Typography>
                <Typography variant="small" className="ttp-accordion-item__title__subtitle">
                    {props.subtitle}
                </Typography>
            </div>
            <Typography className="ttp-accordion-item__title__end-text">{props.endText}</Typography>
        </div>
    );
};

interface TTPAccordionItemListHeaderProps {
    title: "Bedrifter" | "Bedriftsansvarlige";
    action?: () => any;
    itemCount: number;
}

const TTPAccordionItemListHeader = (props: TTPAccordionItemListHeaderProps) => {
    const headerClassNames = classNames("ttp-accordion-item__list-header", {
        "ttp-accordion-item__list-header--empty-list": !props.itemCount
    });

    return (
        <div>
            <div className={headerClassNames}>
                <Typography variant="heading-4">{props.title}</Typography>
                {props.action && (
                    <PrimaryButton
                        dataTestautoid={`ny-tilgang-${props.title.toLowerCase()}`}
                        track={{
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: `tilgang på ${props.title === "Bedrifter" ? "bedriftsansvarlig" : "bedrift"}`
                        }}
                        density="compact"
                        onClick={props.action}
                    >
                        Ny tilgang
                    </PrimaryButton>
                )}
            </div>
            {props.itemCount === 0 && (
                <Typography variant="small">
                    Bedriften har ingen bedriftsansvarlige. Velg “Ny tilgang” for å legge til en som er i systemet,
                    eller bytt visning og velg “Ny bedriftsansvarlig” for å legge til en ny.
                </Typography>
            )}
        </div>
    );
};

const getListItemProps = (entity: TTPBedrift | TTPKam | TTPKamWithoutBedrifter) => {
    let title = "";
    let subtitle = "";
    if ("bedrift" in entity) {
        title = entity.bedrift.navn;
        subtitle = toFormattedOrganisasjonsnummer(entity.bedrift.organisasjonsnummer);
    } else {
        title = `${entity.kam.fornavn} ${entity.kam.etternavn}`;
        subtitle = formatFodselsnummer(entity.kam.fodselsnummer);
    }

    return { title, subtitle };
};

interface TTPAccordionItemListProps {
    bedrift: TTPBedrift;
    kam: TTPKam | TTPKamWithoutBedrifter;
    visning: "bedrift" | "bedriftsansvarlig";
    open: boolean;
}

function TTPAccordionItemList(props: TTPAccordionItemListProps) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [hasDismissed, setHasDismissed] = useState(false);
    const queryClient = useQueryClient();
    const mutation = useRemoveTilgangerTredjePart();

    const updateCache = useCallback(
        () =>
            queryClient.setQueryData<APIResponse<TTPOversiktResponse>>(ttpQueryKeys.all, (data) =>
                removeTilgangFromCache(data!, props.bedrift.bedrift.organisasjonsnummer, props.kam.kam.fodselsnummer)
            ),
        [props.bedrift.bedrift, props.kam.kam, queryClient]
    );

    const handleFjernTilgang = () => {
        mutation.mutate({
            fodselsnummer: props.kam.kam.fodselsnummer,
            organisasjonsnummer: props.bedrift.bedrift.organisasjonsnummer
        });

        updateCache();
        setHasDismissed(true);
    };

    useEffect(() => {
        if (mutation.isSuccess && !hasDismissed && !props.open) {
            updateCache();
        }
    }, [hasDismissed, mutation.isSuccess, props.open, updateCache]);

    const { title, subtitle } = getListItemProps(props.visning === "bedrift" ? props.kam : props.bedrift);

    if (hasDismissed && mutation.isSuccess) {
        return null;
    }

    return (
        <li className="ttp-accordion-item__list-item" data-testid="ttp-accordion-item__list-item">
            <>
                <div className="ttp-accordion-item__list-item__content">
                    <div className="ttp-accordion-item__list-item__text-group">
                        <Typography className="ttp-accordion-item__list-item__text-group__title">{title}</Typography>
                        <Typography variant="small">{subtitle}</Typography>
                    </div>

                    {showConfirmation ? (
                        <div className="ttp-accordion-item__list-item__button-group">
                            <SecondaryButton
                                density="compact"
                                onClick={() => setShowConfirmation(false)}
                                dataTestautoid="tredjepart-fjern-tilgang-avbryt"
                                track={{
                                    hendelse: Trackingkey.TredjepartFjernFlyt,
                                    type: props.visning,
                                    handling: "avbryt"
                                }}
                            >
                                Avbryt
                            </SecondaryButton>
                            <PrimaryButton
                                density="compact"
                                onClick={handleFjernTilgang}
                                loader={{ showLoader: mutation.isPending, textDescription: "Fjerner tilgang" }}
                                dataTestautoid="tredjepart-fjern-tilgang-bekreft"
                                track={{
                                    hendelse: Trackingkey.TredjepartFjernFlyt,
                                    type: props.visning,
                                    handling: "bekreft"
                                }}
                            >
                                Bekreft
                            </PrimaryButton>
                        </div>
                    ) : (
                        <TertiaryButton
                            density="compact"
                            onClick={() => setShowConfirmation(true)}
                            dataTestautoid="tredjepart-fjern-tilgang-start"
                            track={{
                                hendelse: Trackingkey.TredjepartFjernFlyt,
                                type: props.visning,
                                handling: "start"
                            }}
                        >
                            Fjern
                        </TertiaryButton>
                    )}
                </div>

                {mutation.error && (
                    <ErrorMessageBox
                        className="ttp-accordion-item__list-item__error"
                        title="Fjerning mislyktes"
                        dismissAction={{ handleDismiss: () => mutation.reset() }}
                    >
                        Vi kunne ikke fjerne tilgang nå. Prøv en gang til eller kom tilbake senere.
                    </ErrorMessageBox>
                )}
            </>
        </li>
    );
}

interface TTPAccordionItemProps {
    titleProps: Omit<TTPAccordionItemTitleProps, "onClick">;
    listHeader: Omit<TTPAccordionItemListHeaderProps, "itemCount">;
    listItems: Omit<TTPAccordionItemListProps, "open">[];
}

export function TTPAccordionItem({ titleProps, listHeader, listItems }: TTPAccordionItemProps) {
    const [open, setOpen] = useState(false);

    return (
        <AccordionItem
            className="ttp-accordion-item"
            // @ts-ignore: Jøkul støtter ikke custom tittel
            title={
                <TTPAccordionItemTitle
                    title={titleProps.title}
                    subtitle={titleProps.subtitle}
                    endText={titleProps.endText}
                    itemCount={titleProps.itemCount}
                    onClick={() => setOpen((prev) => !prev)}
                />
            }
        >
            <TTPAccordionItemListHeader
                title={listHeader.title}
                action={listHeader.action}
                itemCount={listItems.length}
            />
            <ul className="ttp-accordion-item__list">
                {listItems.map((item) => (
                    <TTPAccordionItemList
                        key={[item.bedrift.bedrift.organisasjonsnummer, item.kam.kam.fodselsnummer].join(",")}
                        bedrift={item.bedrift}
                        kam={item.kam}
                        visning={item.visning}
                        open={open}
                    />
                ))}
            </ul>
        </AccordionItem>
    );
}

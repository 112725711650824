import { Bruker } from "../model/bruker.typer";
import { Distributor } from "../model/theme.typer";
import { MixpanelBruker, TrackerMixpanel } from "./mixpanel";
import { Trackable, Tracker, Trackingkey } from "./tracking.model";

const trackers: Tracker[] = [TrackerMixpanel];

export function initTracking(user: Bruker) {
    trackers.forEach((tracker) => tracker.init(user));
}

export function track(trackable: Trackable | Trackable[]) {
    trackers.forEach((tracker) => tracker.track(trackable));
}

export function registerTrackingProps(props: Record<string, any>) {
    trackers.forEach((tracker) => tracker.register(props));
}

export function trackTime(key: Trackingkey) {
    trackers.forEach((tracker) => tracker.trackTime(key));
}

export function optInTracking(user: MixpanelBruker, distributor: Distributor) {
    trackers.forEach((tracker) => tracker.optIn(user, distributor));
}

export function optOutTracking() {
    trackers.forEach((tracker) => tracker.optOut());
}

export function useNavigationTracking(active: boolean) {
    trackers.forEach((tracker) => tracker.useTrackNavigation(active));
}

export function ABTest(testName: string, variantName: string) {
    trackers.forEach((tracker) => tracker.ABTest(testName, variantName));
}

export function preview(previewName: string, currentName: string) {
    trackers.forEach((tracker) => tracker.preview(previewName, currentName));
}

import { useContext } from "react";

import { toNorwegianDateFormat } from "src/common/formatting";
import { Typography } from "src/components/Typography";
import ThemeContext from "src/context/ThemeContext";
import { AgreementDetails } from "src/model/gen";
import { Trackingkey, track } from "src/tracking";

import { PrimaryLinkButton } from "@components/Button";

import "./TravelInsuranceCard.scss";

type AvtaleDetaljerProps = {
    agreementDetails: AgreementDetails;
};

export function TravelInsuranceCard({ agreementDetails }: Readonly<AvtaleDetaljerProps>) {
    const { distributor } = useContext(ThemeContext);
    const isSb1 = distributor === "sb1";
    const isDnb = distributor === "dnb";

    return (
        <div className="travel-insurance-card">
            <Typography variant="heading-4">Travel insurance</Typography>
            <Typography variant="small">Unlimited medical expenses and repatriation</Typography>
            <div className="agreement-info">
                <div>
                    <Typography variant="heading-5">Policy number</Typography>
                    <Typography variant="small">{agreementDetails.agreementId}</Typography>
                </div>
                <div>
                    <Typography variant="heading-5">Expires</Typography>
                    <Typography variant="small">{toNorwegianDateFormat(agreementDetails.endDate)}</Typography>
                </div>
            </div>
            {isSb1 && (
                <PrimaryLinkButton
                    to={`/dokumenter?avtaleFilter=${agreementDetails.agreementId}&dokumentFilter=Reisekort`}
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LenkeTrykk,
                            lenkeId: "Se reisekort"
                        });
                    }}
                >
                    Se dokument
                </PrimaryLinkButton>
            )}
            {isDnb && (
                <a
                    className="jkl-button jkl-button--primary link-empty-style jkl-spacing-l--top"
                    href={`api/dokumenter/avtale/reisekort/${agreementDetails.id}`}
                    download
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.LastNed,
                            dokumenttype: "Reisekort",
                            enkelt: true
                        });
                    }}
                >
                    Last ned
                </a>
            )}
        </div>
    );
}

import { useMemo, useState } from "react";

import { useAgreementList } from "@features/agreements/agreements.queries";

import { Rolle } from "../../../../model/Rolle";
import { Bedrift } from "./Bedrift";
import { KvitteringBedrift } from "./KvitteringBedrift";
import { TilgangerLeggTilBedrift } from "./TilgangerLeggTilBedrift";

interface FormDataBedrift {
    organisasjonsnummer: string;
    navn: string;
    roller: Rolle[];
    eksisterer: boolean;
    error: undefined;
    aktiv: boolean;
}

export const SkjemaKontroller = () => {
    const [formStateBedrift, setFormStateBedrift] = useState<FormDataBedrift>({} as FormDataBedrift);
    const [step, setStep] = useState(0);
    const avtaleQuery = useAgreementList();

    // lag en oversikt over hvilke unike avtaletyper kunden har
    const avtaleTyper = useMemo(
        () => [...new Set(avtaleQuery.data?.agreements.map((a) => a.category))].flat(),
        [avtaleQuery.data?.agreements]
    );

    const goBack = () => step > 0 && setStep(step - 1);
    const goForward = () => step < 2 && setStep(step + 1);
    const goToStartBedrift = () => {
        setFormStateBedrift({} as FormDataBedrift);
        setStep(0);
    };

    const updateFormStateBedrift = (FormDataBedrift: Partial<FormDataBedrift>) => {
        setFormStateBedrift({
            ...formStateBedrift,
            ...FormDataBedrift
        });
    };
    return (
        <>
            {step === 0 && <Bedrift values={formStateBedrift} save={updateFormStateBedrift} goForward={goForward} />}
            {step === 1 && (
                <TilgangerLeggTilBedrift
                    values={formStateBedrift}
                    save={updateFormStateBedrift}
                    goForward={goForward}
                    goBack={goBack}
                    avtalerFetchState={avtaleQuery.data ? "resolved" : "pending"}
                    avtaleTyper={avtaleTyper}
                />
            )}
            {step === 2 && <KvitteringBedrift goBack={goToStartBedrift} values={formStateBedrift} />}
        </>
    );
};

import { Trackingkey, track } from "src/tracking";

import { useBruker } from "@features/bruker/queries";

export function MeldInnKjoretoyButton() {
    const href = useMailHref();

    return (
        <a
            className="jkl-button jkl-button--secondary"
            href={href}
            onClick={() => {
                track([
                    { hendelse: Trackingkey.Endre, handling: "meld inn kjøretøy", type: "endre flåte" },
                    {
                        hendelse: Trackingkey.LenkeTrykk,
                        lenkeId: "legg-til-kjoretoy"
                    }
                ]);
            }}
        >
            Meld inn kjøretøy
        </a>
    );
}

function useMailHref() {
    const { valgtOrganisasjon } = useBruker();

    const subject = "Flåte endring";

    const body =
        "Innmelding av kjøretøy flåte orgnr. " +
        valgtOrganisasjon!.organisasjonsnummer +
        "\n\n" +
        "For å forsikre kjøretøyet trenger vi litt informasjon, vennligst fyll ut alle spørsmål.\n\n" +
        "Kontaktinformasjon\n" +
        "------------------------\n" +
        "Navn:\n" +
        "Epost:\n" +
        "Telefon:\n\n\n" +
        "Har du registreringsnummer på kjøretøyet, start på punkt A.\n" +
        "Er det nyregistrering, start på punkt B.\n\n" +
        "Ønsket startdato:\n" +
        "Når du ønsker å flytte forsikringen din fra et annet selskap er startdato som regel 1 måned frem i tid. Ved nyregistrering settes dagens dato som start. Datoen oppdateres med korrekt start når skiltene er registrert.\n\n\n\n" +
        "A. Med registreringsnummer\n" +
        "-------------------------\n" +
        "1. Registreringsnummer:\n\n" +
        "2. Har du forsikring i et annet selskap?\n" +
        "- Hvilket selskap?\n\n" +
        "3. Hvem eier kjøretøyet?\n" +
        "- Ved leasing, hvilket selskap?\n\n" +
        "4. Har du lån med pant i kjøretøyet?\n" +
        "- Hvilket selskap?\n\n" +
        "5. Ønsket startdato:\n\n" +
        "6. Avtalenummer på flåteavtalen (ved flere avtaler): \n\n\n\n" +
        "B. Ved nyregistrert kjøretøy\n" +
        "------------------------\n" +
        "1. Understellsnummer:\n" +
        "Merke:\n" +
        "Registreringsår:\n\n" +
        "2. Har du forsikring i et annet selskap?\n" +
        "- Hvilket selskap?\n\n" +
        "3. Hvem eier kjøretøyet?\n" +
        "- Ved leasing, hvilket selskap?\n\n" +
        "4. Har du lån med pant i kjøretøyet?\n" +
        "- Hvilket selskap?\n\n" +
        "5. Ønsket startdato:\n\n" +
        "6. Avtalenummer på flåteavtalen (ved flere avtaler):\n\n\n\n" +
        "5. Kommentar:\n" +
        "------------------------\n\n\n\n";

    const message = `subject=${subject}&body=${body}`;

    return `mailto:forsikring.bedrift@fremtind.no?${encodeURI(message)}`;
}

import React, { type ElementType, MouseEvent } from "react";

import * as Button from "@fremtind/jkl-button-react";

import { track } from "../../tracking/tracking";
import { Trackable } from "../../tracking/tracking.model";

type Props = Button.ButtonProps<ElementType> & {
    onClick?: (event: React.MouseEvent) => void;
    track: Trackable | Trackable[];
    dataTestautoid: string;
    type?: "submit" | "button";
};

const _onClick =
    (
        hendelse: Trackable | Trackable[],
        onClick?: (event: React.MouseEvent) => void
    ): ((event: MouseEvent<HTMLButtonElement>) => void) =>
    (event) => {
        if (hendelse) {
            track(hendelse);
        }
        if (onClick) {
            onClick(event);
        }
    };

export const PrimaryButton: React.FC<Props> = ({ track, onClick, dataTestautoid, ...props }) => (
    <Button.PrimaryButton {...props} data-testautoid={dataTestautoid} onClick={_onClick(track, onClick)} />
);

export const SecondaryButton: React.FC<Props> = ({ track, onClick, dataTestautoid, ...props }) => (
    <Button.SecondaryButton {...props} data-testautoid={dataTestautoid} onClick={_onClick(track, onClick)} />
);

export const TertiaryButton: React.FC<Props> = ({ track, onClick, dataTestautoid, ...props }) => (
    <Button.TertiaryButton {...props} data-testautoid={dataTestautoid} onClick={_onClick(track, onClick)} />
);

import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import { AlleForsikringer, KjopForsikring } from "./views";

export const kjopRoutes: BMRoute[] = [
    {
        path: "forsikringer/kjop-forsikring",
        children: [
            {
                element: (
                    <ProtectedRoute>
                        <KjopForsikring />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Kjøp forsikring",
                index: true
            },
            {
                path: "alle",
                breadcrumbLabel: "Alle forsikringer",
                element: (
                    <ProtectedRoute>
                        <AlleForsikringer />
                    </ProtectedRoute>
                )
            }
        ]
    }
];

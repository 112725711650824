import { Link } from "react-router-dom";

import { formatValuta } from "@fremtind/jkl-formatters-util";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow
} from "@fremtind/jkl-table-react";

import { toNorwegianDateFormat } from "../../../../common/formatting";
import { isDefined } from "../../../../common/utils";
import { Typography } from "../../../../components/Typography";
import { InvoiceDto, InvoiceLineDto } from "../../../../model/gen";

interface Props {
    invoice: InvoiceDto;
    invoiceLines: InvoiceLineDto[];
    includedInvoices?: InvoiceDto[];
}

export const InvoiceContent = ({ includedInvoices, invoiceLines, invoice }: Props) => {
    const EmptyTableCell = () => <TableCell style={{ padding: 0 }} />;

    const invoiceLineKey = (invoiceLine: InvoiceLineDto) => {
        const keys = Object.keys(invoiceLine) as (keyof InvoiceLineDto)[];
        const keyString = keys.reduce((acc, curr) => {
            return acc + invoiceLine[curr];
        }, "");

        return keyString;
    };

    return (
        <>
            <Typography component="h2" variant="heading-3" className="jkl-spacing-8--bottom">
                Fakturainnhold
            </Typography>
            <Table fullWidth collapseToList>
                <TableCaption>Fakturainnhold</TableCaption>
                <TableHead>
                    <TableRow>
                        <TableHeader bold={false}>Beskrivelse</TableHeader>
                        <TableHeader bold={false}>Fra</TableHeader>
                        <TableHeader bold={false}>Til</TableHeader>
                        <TableHeader bold={false} align="right">
                            Beløp
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoiceLines.map((invoiceLine) => (
                        <TableRow key={invoiceLineKey(invoiceLine)}>
                            <TableCell data-th="Beskrivelse">{invoiceLine.text}</TableCell>
                            <TableCell data-th="Fra">{toNorwegianDateFormat(invoiceLine.fromDate, true)}</TableCell>
                            <TableCell data-th="Til">{toNorwegianDateFormat(invoiceLine.toDate, true)}</TableCell>
                            <TableCell data-th="Beløp" align="right">
                                {formatValuta(invoiceLine.amount)}
                            </TableCell>
                        </TableRow>
                    ))}

                    {includedInvoices?.map((includedInvoice) => (
                        <TableRow key={includedInvoice.id}>
                            <TableCell data-th="Beskrivelse">
                                <Link to={`/faktura/${includedInvoice.id}`} className="jkl-link">
                                    Faktura {includedInvoice.fakturaId}-{includedInvoice.versjon}
                                </Link>
                            </TableCell>
                            <EmptyTableCell />
                            <EmptyTableCell />
                            <TableCell data-th="Beløp" align="right">
                                {formatValuta(includedInvoice.belop)}
                            </TableCell>
                        </TableRow>
                    ))}

                    {isDefined(invoice.betaltBelop) && invoice.betaltBelop > 0 && (
                        <TableRow>
                            <TableCell data-th="Beskrivelse">Innbetalt beløp</TableCell>
                            <EmptyTableCell />
                            <EmptyTableCell />
                            <TableCell data-th="Beløp" align="right">
                                {formatValuta(invoice.betaltBelop)}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className="bm-invoice-details__table__total-amount">
                        Totalbeløp: {formatValuta(invoice.belop)}
                    </TableCell>
                </TableFooter>
            </Table>
        </>
    );
};

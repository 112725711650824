import { asDate, toCapitalizedLowerCase } from "src/common/formatting";

import { css } from "@emotion/react";
import { formatDate, formatValuta } from "@fremtind/jkl-formatters-util";

import { isDefined } from "../../../../common/utils";
import { InvoiceDto } from "../../../../model/gen";

interface Props {
    invoice: InvoiceDto;
    maxHeight: string | number;
}

export const InvoiceDetails = ({ invoice, maxHeight: height }: Props) => {
    const styles = css`
        max-height: ${height}px;
    `;

    const paymentType = invoice?.ehf ? "EHF" : invoice.betalingsType;

    return (
        <dl css={styles} className={`bm-invoice-details__header__details`}>
            <div>
                <dt>Fakturert beløp</dt>
                <dd>{invoice.initialAmount ?? "Se dokumenter"}</dd>
            </div>
            <div>
                <dt>Type</dt>
                <dd>{toCapitalizedLowerCase(invoice.invoiceEventType.toLocaleLowerCase())}</dd>
            </div>
            <div>
                <dt>Fakturanummer</dt>
                <dd>
                    {invoice.fakturaId}
                    {invoice.versjon ? `-${invoice.versjon}` : ""}
                </dd>
            </div>
            {/* Fakturadatoen kan være undefined på fakturaer fra prolife */}
            {invoice.fakturaDato && (
                <div>
                    <dt>
                        <span>Fakturadato</span>
                    </dt>
                    <dd>{formatDate(asDate(invoice.fakturaDato || ""))}</dd>
                </div>
            )}
            {isDefined(invoice.betaltBelop) && (
                <div>
                    <dt>Betalt beløp</dt>
                    <dd>{formatValuta(Math.abs(invoice.betaltBelop))}</dd>
                </div>
            )}
            {invoice.betalingsType && (
                <div>
                    <dt>Betalingsmåte</dt>
                    <dd>{paymentType}</dd>
                </div>
            )}
        </dl>
    );
};

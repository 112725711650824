import React, { ReactNode, useEffect } from "react";

import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { useBruker } from "../../features/bruker/queries";
import "./Side.scss";

const onlyDefined = (props: Record<string, any>) =>
    Object.fromEntries(Object.entries(props).filter(([key, value]) => key !== "children" && value !== undefined));

interface Props {
    indented?: boolean;
    compact?: boolean;
    compactTextOnly?: boolean;
    children: ReactNode;
    className?: string;
}
export const Side = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { bruker } = useBruker();

    useEffect(() => {
        if (!bruker?.loggetInn && !["loggut", "feil", "bytt-konsesjon"].some((l) => location.pathname.includes(l))) {
            navigate("/loggut");
        }
    }, [bruker, location.pathname, navigate]);

    const classes = classNames("side", props.className);

    return (
        <div className={classes}>
            {React.Children.map(props.children, (child) => {
                if (!React.isValidElement(child)) return;

                return React.cloneElement(child, onlyDefined(props));
            })}
        </div>
    );
};

import React, { ReactNode, createContext, useEffect, useMemo, useState } from "react";

import { matchPath, useLocation } from "react-router-dom";

import { isRadgiver } from "../common/rolle";
import { useBruker } from "../features/bruker/queries";

interface HeaderContextType {
    menuIsOpen: boolean;
    setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    visLoggUt: boolean;
    setVisLoggUt: React.Dispatch<React.SetStateAction<boolean>>;
    visOrganisasjon: boolean;
}

export const HeaderContext = createContext<HeaderContextType>(null as unknown as HeaderContextType);

interface HeaderContextProviderProps {
    children: ReactNode;
}

// definerer hvilke sider lenke til "bytt bedrift" og organisasjonen _ikke_ skal vises
const hideOrganisationPaths = ["/filvisningsfeil", "/bytt-organisasjon/:orgnummer"];

export function HeaderContextProvider({ children }: HeaderContextProviderProps) {
    const { bruker } = useBruker();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [visLoggUt, setVisLoggUt] = useState(true);
    const { pathname } = useLocation();
    const visOrganisasjon = useMemo(() => {
        if (hideOrganisationPaths.some((p) => matchPath(p, pathname))) {
            return false;
        }

        if (!bruker?.valgtOrganisasjon) {
            return false;
        }

        return true;
    }, [pathname, bruker]);

    useEffect(() => {
        if (isRadgiver(bruker)) {
            setVisLoggUt(false);
        }
    }, [bruker]);

    const context = {
        menuIsOpen,
        setMenuIsOpen,
        visLoggUt,
        setVisLoggUt,
        visOrganisasjon
    };

    return <HeaderContext.Provider value={context}>{children}</HeaderContext.Provider>;
}

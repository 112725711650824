import type { AgreementDetails, ClaimOverview } from "src/model/gen";

import { Typography } from "@components/Typography";
import { useClaimsList } from "@features/claims/claims.queries";
import { ClaimCard } from "@features/claims/components/ClaimCard";

import styles from "./AgreementClaimsSection.module.scss";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementClaimsSection = ({ agreementDetails }: Props) => {
    const { data } = useClaimsList();

    const onGoingClaims = ((data as unknown as ClaimOverview[]) ?? []).filter(
        (d) => d.agreementNumber.includes(agreementDetails.agreementId) && d.status !== "Avsluttet"
    );

    if (onGoingClaims.length === 0) return null;

    return (
        <section className={styles.claims}>
            <Typography variant="heading-2" className="jkl-spacing-l--bottom">
                Pågående skadesaker ({onGoingClaims.length})
            </Typography>
            <div className={styles.ongoingClaims}>
                {onGoingClaims.map((claim) => (
                    <ClaimCard key={claim.claimNumber} {...claim} />
                ))}
            </div>
        </section>
    );
};

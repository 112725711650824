import { useFormContext } from "react-hook-form";

import { NavCard } from "@fremtind/jkl-card-react";
import { formatValuta } from "@fremtind/jkl-formatters-util";

import { SigneringFormData, SigneringStegProps } from ".";
import { Typography } from "../../../components/Typography";
import { TilbudResponse } from "../../../model/gen";
import "./Forside.scss";

export const Forside = ({ tilbud, onFrem }: SigneringStegProps) => {
    const form = useFormContext<SigneringFormData>();

    function handleOnFrem(tilbud: TilbudResponse) {
        form.setValue("tilbud", tilbud);

        onFrem();
    }

    return (
        <div className="signering-forside">
            <Typography variant="heading-1" component="h2" className="jkl-spacing-xl--bottom">
                Velg tilbudet du vil signere
            </Typography>

            {tilbud.map((tilbud, i) => (
                <NavCard
                    title={tilbud.beskrivelse}
                    className="jkl-bgcolor-hvit jkl-spacing-2xl--bottom"
                    key={tilbud.tilbudsId}
                    onClick={() => handleOnFrem(tilbud)}
                    style={{ maxWidth: 562 }}
                    data-testautoid={`kort-tilbud-${i}`}
                    data-testid={`kort-tilbud-${i}`}
                >
                    <dl className="signering-forside__card-details">
                        <div>
                            <Typography variant="small" component="dt">
                                Tilbud opprettet
                            </Typography>
                            <Typography variant="small" component="dd">
                                {tilbud.tilbudDato}
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="small" component="dt">
                                Tilbudet går ut
                            </Typography>
                            <Typography variant="small" component="dd">
                                {tilbud.utlopDato}
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="small" component="dt">
                                Opprettet av
                            </Typography>

                            <Typography variant="small" component="dd">
                                {tilbud.radgiverNavn}
                            </Typography>
                            <Typography variant="small" component="dd">
                                {tilbud.radgiverEpost}
                            </Typography>
                        </div>
                    </dl>

                    <Typography variant="heading-2" component="p">
                        {formatValuta(tilbud.pris)}/år
                    </Typography>
                </NavCard>
            ))}
        </div>
    );
};

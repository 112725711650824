import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { PrimaryButton } from "@fremtind/jkl-button-react";
import { FieldGroup } from "@fremtind/jkl-input-group-react";
import { UnorderedList } from "@fremtind/jkl-list-react";
import { ErrorMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton, RadioButtonGroup } from "@fremtind/jkl-radio-button-react";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { isValidEpost, isValidTelefonnummer } from "@fremtind/jkl-validators-util";

import { SecondaryLinkButton } from "../../../../components/Button";
import { Toast } from "../../../../components/Toast";
import { Trackingkey, track } from "../../../../tracking";
import { useBruker } from "../../../bruker/queries";
import { SamtykkeQueryData, useUpdateSamtykke } from "../../queries";
import "./SamtykkeForm.scss";

type JaEllerNei = "Ja" | "Nei";

type FormData = {
    ekunde: JaEllerNei | undefined;
    delingAvData: JaEllerNei | undefined;
    mobil: string;
    epost: string;
};

type SubmitData = {
    ekunde: boolean | undefined;
    delingAvData: boolean | undefined;
    mobil: string;
    epost: string;
};

const errorMessageBoxs = {
    MISSING_EPOST: "Du må fylle inn e-post",
    MISSING_MOBILNUMMER: "Du må fylle inn mobilnummer",
    INVALID_EPOST: "Ugyldig e-post",
    INVALID_MOBILNUMMER: "Ugyldig mobilnummer",
    UNCHECKED_CHOICES: "Du har ikke tatt et valg"
};

const MessageBoxTemplate = (props: { title: string; message: string; severity: "error" | "warning" }) => {
    const Component = props.severity === "error" ? ErrorMessageBox : WarningMessageBox;

    return (
        <Component className="jkl-spacing-2xl--bottom" title={props.title}>
            {props.message}
        </Component>
    );
};

const renderMessageBox = (causes: string[]) => {
    const invalidMessageBox = "Har det lurt seg inn en skrivefeil?";

    let title = "";
    let message = "";
    let severity = "error";

    if (causes.includes(errorMessageBoxs.UNCHECKED_CHOICES)) {
        title = "Du har ikke tatt et valg";
        message =
            "Vi ønsker at du svarer ja eller nei på samtykke, hvis ikke vil du bli spurt om dette på nytt neste gang du logger deg inn i løsningen.";
        severity = "warning";
    } else if (
        causes.includes(errorMessageBoxs.MISSING_EPOST) &&
        causes.includes(errorMessageBoxs.MISSING_MOBILNUMMER)
    ) {
        title = "Mangler kontaktinfo";
        message = "Du må fylle inn mobilnummer og e-post.";
    } else if (causes.includes(errorMessageBoxs.MISSING_EPOST)) {
        title = "Mangler e-post";
        message = "Du må fylle inn e-post.";
    } else if (causes.includes(errorMessageBoxs.MISSING_MOBILNUMMER)) {
        title = "Mangler mobilnummer";
        message = "Du må fylle inn mobilnummer.";
    } else if (
        causes.includes(errorMessageBoxs.INVALID_EPOST) &&
        causes.includes(errorMessageBoxs.INVALID_MOBILNUMMER)
    ) {
        title = "Ugyldig kontaktinfo";
        message = invalidMessageBox;
    } else if (causes.includes(errorMessageBoxs.INVALID_EPOST)) {
        title = "Ugyldig e-post";
        message = invalidMessageBox;
    } else if (causes.includes(errorMessageBoxs.INVALID_MOBILNUMMER)) {
        title = "Ugyldig mobilnummer";
        message = invalidMessageBox;
    }

    return title && message ? (
        <MessageBoxTemplate {...{ title, message, severity: severity as "error" | "warning" }} />
    ) : null;
};

const translateJaEllerNei = (value?: JaEllerNei) => {
    return value ? (value === "Ja" ? true : false) : value;
};

const toSubmitData = (formData: FormData): SubmitData => ({
    ...formData,
    ekunde: translateJaEllerNei(formData.ekunde),
    delingAvData: translateJaEllerNei(formData.delingAvData)
});

const validate = (formData: FormData, isDNB: boolean) => {
    const data = toSubmitData(formData);

    const errors = {
        epost: "",
        mobilnummer: "",
        ekunde: "",
        delingAvData: ""
    };

    if (data.ekunde === undefined || data.ekunde === null) {
        errors.ekunde = errorMessageBoxs.UNCHECKED_CHOICES;
    }

    if ((data.delingAvData === undefined || data.delingAvData === null) && !isDNB) {
        errors.delingAvData = errorMessageBoxs.UNCHECKED_CHOICES;
    }

    if (data.ekunde === true) {
        errors.epost = !formData.epost
            ? errorMessageBoxs.MISSING_EPOST
            : isValidEpost(formData.epost)
              ? ""
              : errorMessageBoxs.INVALID_EPOST;

        errors.mobilnummer = !formData.mobil
            ? errorMessageBoxs.MISSING_MOBILNUMMER
            : isValidTelefonnummer(formData.mobil)
              ? ""
              : errorMessageBoxs.INVALID_MOBILNUMMER;
    }

    return errors;
};

interface Props {
    orgNavn: string;
    isRadgiver: boolean;
    isDNB: boolean;
    samtykke: SamtykkeQueryData;
}

export function SamtykkeForm(props: Props) {
    const {
        handleSubmit,
        register,
        watch,
        reset,
        getValues,
        formState: { errors }
    } = useForm<FormData>({
        shouldFocusError: false
    });

    const ekunde = watch("ekunde");
    const delingAvData = watch("delingAvData");
    const mobilnummer = watch("mobil");
    const epost = watch("epost");

    const [customErrors, setCustomErrors] = useState({
        ekunde: "",
        delingAvData: "",
        mobilnummer: "",
        epost: ""
    });

    const [expandedAccordions, setExpandedAccordions] = useState({
        ekunde: false,
        delingAvData: false
    });

    const mutation = useUpdateSamtykke();
    const { valgtOrganisasjon } = useBruker();

    const onSubmit = (formData: FormData) => {
        mutation.reset();

        const newErrors = validate(formData, props.isDNB);

        setCustomErrors(newErrors);

        if ([formData.ekunde, formData.delingAvData].includes(undefined)) {
            track({
                hendelse: Trackingkey.Samtykke,
                ekunde: "Blank",
                delingAvData: "Blank"
            });
        }

        if (Object.values(newErrors).some((value) => value)) {
            return;
        }

        mutation.mutate(toSubmitData(formData), {
            onSuccess: () => {
                track({
                    hendelse: Trackingkey.Samtykke,
                    ekunde: ekunde!,
                    delingAvData
                });
            }
        });
    };

    useEffect(() => {
        if (Object.values(errors).some((value) => value)) {
            setCustomErrors(validate(getValues(), props.isDNB));
        }
    }, [errors, getValues, props.isDNB]);

    useEffect(() => {
        if (mutation.status !== "idle") {
            mutation.reset();
        }

        if (Object.values(customErrors).some((value) => value)) {
            setCustomErrors({ ekunde: "", delingAvData: "", mobilnummer: "", epost: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ekunde, delingAvData, mobilnummer, epost]);

    useEffect(() => {
        const samtykke = props.samtykke;

        if (samtykke) {
            reset({
                ekunde: samtykke.ekunde === true ? "Ja" : samtykke.ekunde === false ? "Nei" : undefined,
                delingAvData:
                    samtykke.delingAvData === true ? "Ja" : samtykke.delingAvData === false ? "Nei" : undefined,
                mobil: samtykke.mobil,
                epost: samtykke.epost
            });
        }
    }, [props.samtykke, reset]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="jkl-spacing-2xl--bottom" data-testid="ekunde-section">
                <FieldGroup
                    legend="Elektronisk kommunikasjon (E-kunde)"
                    className="samtykke__field-group"
                    data-testautoid="samtykke-fieldset-elektronisk-kommunikasjon"
                >
                    <RadioButtonGroup
                        legend={`Samtykker du til at ${valgtOrganisasjon?.navn} sine dokumenter i avtaleforholdet mellom bedriften og Fremtind Forsikring kan kommuniseres elektronisk?`}
                        inline
                    >
                        <RadioButton label="Ja" value="Ja" {...register("ekunde")} />
                        <RadioButton label="Nei" value="Nei" {...register("ekunde")} />
                    </RadioButtonGroup>

                    {ekunde === "Ja" && (
                        <div className="samtykke__kontakt-info">
                            <TextInput
                                label="Mobilnummer"
                                {...register("mobil", {
                                    required: "Du må fylle inn mobilnummer",
                                    validate: (value) => (isValidTelefonnummer(value) ? true : "Ugyldig mobilnummer")
                                })}
                                className="jkl-spacing-xl--right samtykke__mobilnummer"
                                errorLabel={errors.mobil?.message}
                                data-testautoid="samtykke-input-mobilnummer"
                            />

                            <TextInput
                                label="E-post"
                                {...register("epost", {
                                    required: "Du må fylle inn e-post",
                                    validate: (value) => (isValidEpost(value) ? true : "Ugyldig e-post")
                                })}
                                errorLabel={errors.epost?.message}
                                className="samtykke__epost"
                                data-testautoid="samtykke-input-epost"
                            />
                        </div>
                    )}

                    <div
                        role="none"
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.Utvid,
                                id: "ekunde",
                                kategori: "Samtykke"
                            });

                            setExpandedAccordions((prev) => ({ ...prev, ekunde: !prev.ekunde }));
                        }}
                    >
                        <Accordion className="jkl-spacing-l--top samtykke__accordion">
                            <AccordionItem title={`Les ${expandedAccordions.ekunde ? "mindre" : "mer"}`}>
                                Med elektronisk kommunikasjon mener vi e-post og SMS. Det betyr at du blir varslet når
                                et nytt dokument ligger klart i kundeløsningen som ikke blir sendt i posten. Noen
                                dokumenter kan likevel bli sendt i posten, da blir det ikke sendt et varsel.
                                <br />
                                <br />
                                Når du gir samtykke til elektronisk kommunikasjon betyr det at de digitale dokumentene
                                er likestilt med brevpost. Du kan når som helst trekke tilbake ditt samtykke i
                                kundeløsningen. Det er også mulig å endre kontaktinformasjonen ovenfor.
                            </AccordionItem>
                        </Accordion>
                    </div>
                </FieldGroup>
            </div>

            {!props.isDNB && (
                <div className="jkl-spacing-2xl--bottom" data-testid="deling-section">
                    <FieldGroup
                        legend="Deling av data"
                        className="samtykke__field-group"
                        data-testautoid="samtykke-fieldset-deling-av-data"
                    >
                        <RadioButtonGroup
                            legend={`Samtykker du til at ${valgtOrganisasjon?.navn} sine data kan deles i SpareBank 1-konsernet?`}
                            inline
                        >
                            <RadioButton label="Ja" value="Ja" {...register("delingAvData")} />
                            <RadioButton label="Nei" value="Nei" {...register("delingAvData")} />
                        </RadioButtonGroup>

                        <div
                            role="none"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.Utvid,
                                    id: "delingAvData",
                                    kategori: "Samtykke"
                                });

                                setExpandedAccordions((prev) => ({
                                    ...prev,
                                    delingAvData: !prev.delingAvData
                                }));
                            }}
                        >
                            <Accordion className="jkl-spacing-l--top samtykke__accordion">
                                <AccordionItem title={`Les ${expandedAccordions.delingAvData ? "mindre" : "mer"}`}>
                                    Hvis du gir samtykke til datadeling, vil informasjon om ditt kundeforhold bli delt
                                    mellom selskapene i SpareBank 1-konsernet. Informasjonen blir brukt til:
                                    <UnorderedList>
                                        <li>
                                            å vise informasjon fra både liv- og skadeforsikring til
                                            forsikringsrådgivere, slik at du får best mulig rådgivning.
                                        </li>
                                        <li>
                                            å forstå hvordan du bruker digitale løsninger, slik at vi kan gjøre
                                            løsningene bedre.
                                        </li>
                                        <li>
                                            å samle informasjon på tvers av bank, pensjon og forsikring slik at vi kan
                                            gi deg relevante tilbud.
                                        </li>
                                    </UnorderedList>
                                    Det er SpareBank 1 Forsikring og Fremtind Forsikring som har ansvar for å behandle
                                    opplysningene om bedriften. Du har rett til innsyn i disse opplysningene.
                                    Opplysningene blir ikke delt utenfor SpareBank 1-konsernet. Du kan når som helst
                                    trekke tilbake ditt samtykke i kundeløsningen.
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </FieldGroup>
                </div>
            )}

            {renderMessageBox(Object.values(customErrors))}

            {mutation.status === "success" ? (
                <SecondaryLinkButton to="/hjem">Til forsiden</SecondaryLinkButton>
            ) : (
                <PrimaryButton
                    className="jkl-spacing-l--right"
                    data-testautoid="samtykke-button-bekreft"
                    loader={{
                        showLoader: mutation.isPending,
                        textDescription: "Oppdaterer samtykke"
                    }}
                >
                    Bekreft
                </PrimaryButton>
            )}

            <Toast open={mutation.isSuccess} onClose={mutation.reset} severity="success" title="Takk!">
                Samtykket er oppdatert!
            </Toast>

            <Toast open={mutation.isError} onClose={mutation.reset} severity="error" title="Kunne ikke oppdatere">
                Prøv en gang til eller kom tilbake senere.
            </Toast>

            <Toast
                open={mutation.error?.status === 403 && props.isRadgiver}
                onClose={mutation.reset}
                severity="error"
                title="Kan ikke endre"
            >
                Rådgiver kan ikke gjøre endringer på samtykke. Kunden må endre dette selv.
            </Toast>
        </form>
    );
}

import { AgreementDetails } from "src/model/gen";

import { Link } from "@fremtind/jkl-core";
import { formatValuta } from "@fremtind/jkl-formatters-util";

interface Props {
    currentAgreement: AgreementDetails;
    newAgreement: AgreementDetails;
}

export const FornyelseAntallKanIkkeEndres = ({ currentAgreement, newAgreement }: Props) => {
    return (
        <>
            Årlig pris endres til {formatValuta(newAgreement.annualPremium || "")}. Se{" "}
            <Link
                href={`/bedrift/api/dokumenter/avtale/pdf/${currentAgreement.id}/fornyelse`}
                target="_blank"
                rel="noopener noreferrer"
            >
                nytt avtaledokument
            </Link>{" "}
            for detaljer. Hvis du ønsker å gjøre endringer ta kontakt med din bankrådgiver. Vær oppmerksom på at dette
            kan føre til ny pris og faktura.
        </>
    );
};

import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { useQueryClient } from "@tanstack/react-query";

import { SigneringFormData, SigneringStegProps } from ".";
import { Typography } from "../../../components/Typography";
import { signeringQueryKey } from "../queries";

export const Signer = ({ onFrem, onRestart }: SigneringStegProps) => {
    const form = useFormContext<SigneringFormData>();

    const queryClient = useQueryClient();
    const acceptedOrigins = [
        "http://localhost.sparebank1.no:9001",
        "https://forsikring-bedrift.sparebank1.no",
        "https://forsikring-bm.app.fremtind-test-app.devaws.sparebank1.no",
        "https://forsikring-bm-at.app.fremtind-test-app.devaws.sparebank1.no",
        "https://forsikring-bm-radgiver.app.fremtind-test-app.devaws.sparebank1.no",
        "https://forsikring-bm-radgiver-at.app.fremtind-test-app.devaws.sparebank1.no",
        "https://forsikring-bedrift-radgiver.sparebank1.no"
    ];

    useEffect(() => {
        function handleMessage(event: MessageEvent) {
            if (acceptedOrigins.includes(event.origin)) {
                switch (event.data) {
                    case "SIGNING_COMPLETE": {
                        queryClient.invalidateQueries({ queryKey: [signeringQueryKey.all] });
                        onFrem();
                        break;
                    }
                    case "SIGNING_ABORT":
                    case "SIGNING_ERROR": {
                        onRestart();
                        break;
                    }
                }
            }
        }

        // Meldingen blir sendt fra /public/static/signingComplete.html
        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [onFrem, onRestart, queryClient]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Typography variant="heading-1" component="h2" className="jkl-spacing-xl--bottom">
                Signer dokument
            </Typography>

            <iframe
                style={{ borderStyle: "none", height: 425, width: 600, maxWidth: "100%" }}
                title="signering"
                src={form.getValues().signering.signUrl}
            />
        </div>
    );
};

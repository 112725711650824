import { queryClient } from "src/common/queryClient";
import { InvoiceDetailsDto, InvoiceDto, InvoiceRemindersDto } from "src/model/gen";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { EntityResponse } from "@features/core/http";
import { useQuery } from "@tanstack/react-query";

import { useFetchFakturaListe } from "./../dokumenter/queries";

const invoiceQueryKey = {
    list: ["faktura"],
    reminder: ["reminder"],
    details: (id: string) => ["details", id]
};

const fetchInvoiceReminders = async (organisationNumber: string) => {
    const data = await http.getEntity<InvoiceRemindersDto>(`/faktura/${organisationNumber}/reminders`);

    return data;
};

export const useInvoiceReminders = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [invoiceQueryKey.reminder],
        queryFn: () => fetchInvoiceReminders(bruker!.gjeldendeOrganisasjonsnummer!),
        enabled: !!bruker?.gjeldendeOrganisasjonsnummer
    });
};

const fetchInvoiceDetails = async (organisasjonsnummer: string, invoiceId: string) => {
    return http.getEntity<InvoiceDetailsDto>(`/faktura/${organisasjonsnummer}/details/${invoiceId}`);
};

export const invoiceDetailsQuery = (organisationNumber: string, invoiceId: string) => {
    return queryClient.fetchQuery({
        queryKey: [invoiceQueryKey.details(invoiceId)],
        queryFn: () => fetchInvoiceDetails(organisationNumber, invoiceId)
    });
};

export const useInvoiceDetails = (invoiceId: string) => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [invoiceQueryKey.details(invoiceId)],
        queryFn: () => fetchInvoiceDetails(bruker!.gjeldendeOrganisasjonsnummer!, invoiceId)
    });
};

export const useSingleInvoice = (invoiceId: string | undefined) => {
    const invoices = useFetchFakturaListe();
    if (!invoiceId) return;
    const invoice = invoices.data?.entity.find((invoice) => invoice.id === invoiceId);
    return invoice;
};

const fetchInvoiceList = async (organisationNumber: string) => {
    return http.get<EntityResponse<InvoiceDto[]>>(`faktura/${organisationNumber}`);
};

export const invoiceListQuery = (organisationNumber: string) => {
    return queryClient.fetchQuery({
        queryKey: invoiceQueryKey.list,
        queryFn: () => fetchInvoiceList(organisationNumber),
        staleTime: Infinity
    });
};

export const useInvoiceList = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [invoiceQueryKey.list],
        queryFn: () => fetchInvoiceList(bruker!.gjeldendeOrganisasjonsnummer!),
        refetchOnWindowFocus: false,
        staleTime: Infinity
    });
};

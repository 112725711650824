import React from "react";

import { Trackingkey, track } from "src/tracking";

import { Flag } from "./featureflag.model";
import { useFeatureflag } from "./useFeatureflag";

interface Props {
    flag: Flag;
}

export const Featureflag: React.FC<React.PropsWithChildren<Props>> = ({ flag: featureflag, children }) => {
    const isEnabled = useFeatureflag(featureflag);

    Flag.LONNSBERETNINGER_VARSEL ?? // eslint-disable-line
        track({
            hendelse: Trackingkey.Varsel,
            type: "årsavslutning",
            variant: "info"
        });

    if (!isEnabled) {
        return null;
    }

    return <>{children}</>;
};

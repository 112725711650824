import { useFormContext } from "react-hook-form";

import { formatValuta } from "@fremtind/jkl-formatters-util";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";

import { EndreSykelonnFormState, EndreSykelonnStegProps } from ".";
import { toNorwegianDateFormat } from "../../../../common/formatting";
import { PrimaryLinkButton, SecondaryLinkButton } from "../../../../components/Button";
import { SkjemaFooter } from "../../../../components/SkjemaFooter";
import { Typography } from "../../../../components/Typography";

export const Kvittering = ({ onFerdig, onRestart, ansatte }: EndreSykelonnStegProps) => {
    const { watch } = useFormContext<EndreSykelonnFormState>();

    const chosenAnsatte = ansatte.filter((ansatt) => watch("ansatteIndekser")?.includes(ansatt.indeks)) ?? [];
    const formData = watch();

    return (
        <>
            <Typography variant="heading-3" component="h2" className="sykelonn-kvittering__header">
                Lønn er endret
            </Typography>
            <Typography>Endringene vil gjelde fra datoen du har valgt.</Typography>
            <Table className="sykelonn-kvittering__table" collapseToList>
                <TableHead>
                    <TableRow>
                        <TableHeader density="compact" bold={false}>
                            Navn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Ny årslønn
                        </TableHeader>
                        <TableHeader density="compact" bold={false}>
                            Gyldig fra
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {chosenAnsatte.map((ansatt) => (
                        <TableRow key={ansatt.indeks}>
                            <TableCell data-th="Navn" className="sykelonn-kvittering__table-cell--first">
                                {ansatt.fornavn} {ansatt.etternavn}
                                <br />
                                <Typography component="span" variant="small">
                                    {ansatt.fodselsnummer}
                                </Typography>
                            </TableCell>
                            <TableCell data-th="Ny årslønn" width="120px">
                                {formatValuta(formData.ansatte[ansatt.indeks].lonn!)}
                            </TableCell>
                            <TableCell data-th="Gyldig fra" width="120px">
                                {toNorwegianDateFormat(formData.ansatte[ansatt.indeks].fraDato!)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <SkjemaFooter className="sykelonn-kvittering__footer">
                <PrimaryLinkButton to="/ansatte-navn" onClick={() => onFerdig()}>
                    Ferdig
                </PrimaryLinkButton>
                <SecondaryLinkButton to="/ansatte-navn/endre-sykelonn" onClick={() => onRestart()}>
                    Endre flere
                </SecondaryLinkButton>
            </SkjemaFooter>
        </>
    );
};

import { TableCell, TableRow } from "@fremtind/jkl-table-react";

import { SecondaryButton } from "../../../../../components/Button/Button";
import { Trackable, Trackingkey } from "../../../../../tracking";

interface Callback {
    success?: () => void;
    error?: () => void;
}

export interface DokumentRad {
    key: string;
    radInnhold: Array<string>;
    handling?: {
        tekst: string;
        handling: (callback?: Callback) => void;
    };
    tracking: string;
    indeks: string;
    fakturaId: string;
}

interface Props {
    rad: DokumentRad;
    kolonner: Array<string>;
    buttonAction: string;
}

export function ListeRad({ rad, kolonner, buttonAction }: Props) {
    const { radInnhold, key } = rad;

    const trackingEvents: Trackable[] = [
        { hendelse: Trackingkey.Knappetrykk, knappeId: "dokumentliste-last-ned-dokument" }
    ];

    if (rad.tracking === "Faktura") {
        trackingEvents.push({
            hendelse: Trackingkey.LastNed,
            enkelt: false,
            dokumenttype: "faktura"
        });
    } else {
        trackingEvents.push({
            hendelse: Trackingkey.LastNed,
            enkelt: false,
            dokumenttype: rad.tracking
        });
    }

    return (
        <TableRow>
            {radInnhold.map((data, i) => (
                <TableCell key={key + data} data-kolonneoverskrift={kolonner[i]} data-th={kolonner[i]}>
                    {data}
                </TableCell>
            ))}
            <TableCell>
                {
                    <SecondaryButton
                        density="compact"
                        aria-label={buttonAction}
                        onClick={() => window.open("/bedrift/api/dokumenter/document/" + rad.indeks, "_blank")}
                        dataTestautoid="dokumentliste-last-ned-dokument"
                        track={trackingEvents}
                    >
                        Åpne
                    </SecondaryButton>
                }
            </TableCell>
        </TableRow>
    );
}

import { useEffect, useState } from "react";

import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useFormContext } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";
import { Trackingkey, track } from "src/tracking";

import { SkjemaFooter } from "@components/SkjemaFooter";
import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { PrimaryButton, TertiaryButton } from "@fremtind/jkl-button-react";
import { DatePicker, formatInput } from "@fremtind/jkl-datepicker-react";
import { ErrorMessageBox, InfoMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { RadioButton } from "@fremtind/jkl-radio-button-react";
import { TextArea, TextInput } from "@fremtind/jkl-text-input-react";

import "../../AgreementChanges.scss";
import { ContactInfoInputs } from "../../ContactInfoInputs";
import { useEndreAvtaledetaljer } from "../../agreementchanges.queries";
import { FormProps } from "../FormProps";
import { FormDataChanges } from "../SkjemaKontroller";
import { GetFormData } from "./GetFormData";

export interface AvtaleTilEndring {
    annet?: string;
    epost: string;
    telefon?: string;
}

export type FieldValues = {
    avtale: AvtaleTilEndring;
};

export const CancelForm = (props: FormProps) => {
    useEffect(() => {
        props.onFerdig();
    }, [props]);

    const { register, formState, handleSubmit } = useFormContext<FormDataChanges>();
    const { valgtOrganisasjon } = useBruker();
    const [submitError, setSubmitError] = useState(false);

    const { setValue } = useFormContext<FormDataChanges>();

    const { errors } = formState;

    const [isLoading, setIsLoading] = useState(false);
    const endringerMutation = useEndreAvtaledetaljer();
    const agreementSpecific = GetFormData(props.avtaleDetaljer);

    const [annulationCause, setAnnulationCause] = useState("");

    const [showCustomAnnulationCause, setShowCustomAnnulationCause] = useState(false);
    const [showChangeInsuranceMessage, setShowChangeInsuranceMessage] = useState(false);
    const [showNumberPlateDelivered, setShowNumberPlateDelivered] = useState(false);
    const [showRestOfForm, setShowRestOfForm] = useState(true);
    const [sluttDatoError, setSluttDatoError] = useState(false);
    const [sluttDatoErrorMsg, setSluttDatoErrorMsg] = useState("");

    // create useeffect to set annulationCause to first value in array
    useEffect(() => {
        if (annulationCause === "Annen grunn") {
            setShowCustomAnnulationCause(true);
        } else {
            setShowCustomAnnulationCause(false);
        }

        if (annulationCause === "Levert skilter til Statens vegvesen") {
            setShowNumberPlateDelivered(true);
        } else {
            setShowNumberPlateDelivered(false);
        }
        if (annulationCause === "Byttet forsikringsselskap") {
            setShowChangeInsuranceMessage(true);
        } else {
            setShowChangeInsuranceMessage(false);
        }

        if (
            annulationCause === "Byttet forsikringsselskap" ||
            annulationCause === "Levert skilter til Statens vegvesen"
        ) {
            setShowRestOfForm(false);
        } else {
            setShowRestOfForm(true);
        }
    }, [annulationCause]);

    function cancelForm() {
        props.dialogRef.current.hide();
    }

    // format the form values to a string
    function formatFormValues(formValues: FormDataChanges) {
        let returnstring = `Endringstype: Oppsigelse\nOrganisasjonsnummer: ${valgtOrganisasjon?.organisasjonsnummer}\nOrganisasjonsnavn: ${valgtOrganisasjon?.navn}\nAvtalenummer: ${props.avtaleDetaljer.agreementId}`;
        if (props.chosenObject) {
            returnstring += `\nValgt objekt: ${props.chosenObject}`;
        }
        Object.entries(formValues).map(([key, value]) => {
            if (key === "annulationcause" && value !== "") {
                returnstring += `\nÅrsak til oppsigelse: ${value}`;
            }
            if (key === "customAnnulationCause" && value !== "") {
                returnstring += `\nAnnen årsak til oppsigelse: ${value}`;
            }
            if (key === "sluttdato" && value !== "") {
                returnstring += `\nAvslutningsdato: ${toNorwegianDateFormat(value)}`;
            }
            if (key === "improvements" && value !== "") {
                returnstring += `\nFritekst: ${value}`;
            }
        });
        return returnstring;
    }

    const onSubmit = async (formValues: FormDataChanges) => {
        if (props.avtaleDetaljer.id && !sluttDatoError) {
            setIsLoading(true);
            try {
                const res = await endringerMutation.mutateAsync({
                    id: props.avtaleDetaljer.id,
                    email: formValues.email,
                    phone: formValues.telefon,
                    message: formatFormValues(formValues) as string
                });

                if (!res) {
                    // commitEndringState("REJECTED");
                } else {
                    // commitEndringState("RESOLVED");
                    props.onFrem();
                    setSubmitError(false);
                    setIsLoading(false);
                }

                track({
                    hendelse: Trackingkey.AvsluttAvtale,
                    avtale: props.avtaleDetaljer.productCode,
                    årsak: formValues.customAnnulationCause
                        ? formValues.customAnnulationCause
                        : formValues.annulationcause,
                    dato: formValues.sluttdato,
                    kommentar: formValues.improvements
                });
            } catch {
                setSubmitError(true);
            }
        }

        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: "Endre avtale",
            handling: "ferdig",
            stegnavn: "Endre avtale"
        });
    };

    if (props.onClose === true) {
        cancelForm();
    }

    const utmeldingsDato = (() => {
        const min = startOfDay(new Date());
        const max = endOfDay(new Date());
        max.setMonth(max.getMonth() + 3);

        return {
            min,
            max
        };
    })();

    const handleDate = (selectedDate: Date) => {
        if (isBefore(selectedDate, utmeldingsDato.min)) {
            setSluttDatoError(true);
            setSluttDatoErrorMsg(`Forsikringen kan ikke avsluttes før ${toNorwegianDateFormat(utmeldingsDato.min)}`);
        } else if (isAfter(selectedDate, utmeldingsDato.max)) {
            setSluttDatoError(true);
            setSluttDatoErrorMsg(`Forsikringen kan ikke avsluttes etter ${toNorwegianDateFormat(utmeldingsDato.max)}`);
        } else {
            setSluttDatoError(false);
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="agreement-changes-form">
            <Typography className={"jkl-spacing-24--bottom agreement-changes-form__heading"} variant={"heading-3"}>
                Avslutt avtale
            </Typography>

            {agreementSpecific.annulationCause?.length > 0 ? (
                <>
                    {agreementSpecific.annulationCause?.map((item: string) => (
                        <RadioButton
                            type="checkbox"
                            value={item}
                            key={item}
                            {...register("annulationcause", {
                                required: `Du må velge en årsak til at du ønsker å avslutte forsikringen.`
                            })}
                            onChange={(e) => {
                                setValue("annulationcause", item);
                                setAnnulationCause(e.target.value || "");
                            }}
                        >
                            {item}
                        </RadioButton>
                    ))}

                    {showChangeInsuranceMessage && (
                        <>
                            <InfoMessageBox className="jkl-spacing-xl--top">
                                Hvis du bytter forsikringsselskap, avslutter vi automatisk forsikringen din.
                            </InfoMessageBox>
                            <TertiaryButton
                                className="jkl-spacing-xl--top"
                                type="button"
                                aria-label="Avbryt endring"
                                onClick={() => cancelForm()}
                            >
                                Avbryt
                            </TertiaryButton>
                        </>
                    )}

                    {showNumberPlateDelivered && (
                        <>
                            <InfoMessageBox className="jkl-spacing-xl--top">
                                Hvis du har levert skiltene til Statens vegvesen, avslutter vi automatisk forsikringen
                                din.
                            </InfoMessageBox>
                            <TertiaryButton
                                className="jkl-spacing-xl--top"
                                type="button"
                                aria-label="Avbryt endring"
                                onClick={() => cancelForm()}
                            >
                                Avbryt
                            </TertiaryButton>
                        </>
                    )}

                    {showCustomAnnulationCause && (
                        <TextInput
                            className="jkl-spacing-l--top"
                            label="Forklar hvorfor du vil avslutte forsikringen"
                            labelProps={{ variant: "medium" }}
                            {...register("customAnnulationCause", {
                                required: `Du må beskrive hvorfor du ønsker å avslutte forsikringen.`,
                                maxLength: {
                                    value: 1000,
                                    message: "Meldingen kan ikke være lengre enn 1000 tegn"
                                }
                            })}
                            itemRef={"customAnnulationCause"}
                            errorLabel={errors.customAnnulationCause?.message}
                        />
                    )}
                    {showRestOfForm && (
                        <>
                            <DatePicker
                                className="jkl-spacing-l--top"
                                label="Dato for avslutning"
                                {...register("sluttdato", {
                                    required: `Du må velge den siste dagen du ønsker at forsikringen skal gjelde.`
                                })}
                                onChange={(e, date) => {
                                    if (date) {
                                        handleDate(date);
                                    }
                                }}
                                labelProps={{ variant: "medium" }}
                                errorLabel={sluttDatoError ? sluttDatoErrorMsg : errors.sluttdato?.message}
                                disableBeforeDate={formatInput(utmeldingsDato.min)}
                                disableAfterDate={formatInput(utmeldingsDato.max)}
                                data-testautoid="merinfo-meldut-sluttdato"
                                helpLabel={`Velg den siste dagen du ønsker at
                            kjøretøyet skal være forsikret. Du kan ikke velge en dato før ${toNorwegianDateFormat(
                                utmeldingsDato.min
                            )}
         eller etter ${toNorwegianDateFormat(utmeldingsDato.max)}.`}
                            />

                            <TextArea
                                className=" jkl-spacing-l--top jkl-spacing-32--bottom"
                                {...register("improvements", {
                                    required: false,
                                    maxLength: {
                                        value: 1000,
                                        message: "Meldingen kan ikke være lengre enn 1000 tegn"
                                    }
                                })}
                                startOpen
                                labelProps={{ variant: "medium" }}
                                label="Hvordan kunne vi forbedret forsikringen? (valgfritt)"
                                placeholder={"Hjelp oss å forbedre forsikringen."}
                                errorLabel={errors.improvements?.message}
                            />

                            {submitError && (
                                <div className="jkl-spacing-xl--top jkl-spacing-xl--bottom">
                                    <ErrorMessageBox title="Endringen ble ikke lagret">
                                        En teknisk feil har oppstått. Beklager!
                                    </ErrorMessageBox>
                                </div>
                            )}
                            <ContactInfoInputs />
                            {agreementSpecific.isVehicle && showCustomAnnulationCause && (
                                <Typography className="jkl-spacing-xl--top" variant="body">
                                    Forsikring kan ikke avsluttes uten gyldig grunn. Hvis vi er usikre på om grunnen din
                                    er gyldig vil rådgiver kontakte deg.
                                </Typography>
                            )}

                            <SkjemaFooter>
                                <PrimaryButton
                                    disabled={showChangeInsuranceMessage}
                                    type="submit"
                                    loader={{ showLoader: isLoading, textDescription: "Lagrer endringer" }}
                                >
                                    Bekreft og lagre
                                </PrimaryButton>
                                <TertiaryButton
                                    type="button"
                                    aria-label="Avbryt endring"
                                    onClick={() => {
                                        cancelForm();
                                        track({
                                            hendelse: Trackingkey.Skjemaflyt,
                                            flytnavn: "Avslutt avtale",
                                            handling: "avbryt",
                                            stegnavn: "Avslutt avtale"
                                        });
                                    }}
                                >
                                    Avbryt
                                </TertiaryButton>
                            </SkjemaFooter>
                        </>
                    )}
                </>
            ) : (
                <div className="agreement-changes-form__nochange-info">
                    <WarningMessageBox className="jkl-spacing-xl--top">
                        Du kan ikke si opp forsikringen
                    </WarningMessageBox>

                    <Typography variant="heading-4" className="jkl-spacing-xl--top">
                        Vi avslutter forsikringen automatisk hvis du:
                    </Typography>
                    <Typography>
                        <ul>
                            <li>Selger eller vraker kjøretøyet</li>
                            <li>Bytter forsikringsselskap</li>
                            <li>Leverer skiltene til Statens vegvesen</li>
                        </ul>
                    </Typography>
                    <Typography variant="heading-4" className="jkl-spacing-xl--top">
                        Har du avsluttet et leasingforhold?
                    </Typography>
                    <Typography>
                        Da må du ta kontakt med rådgiver for å avslutte. Du trenger dokumentasjon fra leasingselskapet
                        på at bilen er innlevert.
                    </Typography>
                    <Typography variant="heading-4" className="jkl-spacing-xl--top">
                        Skal du oppbevare bilen over en lengre periode?
                    </Typography>
                    <Typography>
                        Har du en bil du kun bruker deler av året kan du avregistrere den midlertidig hos Statens
                        vegvesen. Avtalen vil bli gjort om til en lagringsforsikring, som senere kan avsluttes.
                    </Typography>

                    <NavLink
                        className="jkl-link jkl-nav-link jkl-spacing-s--top"
                        to="https://www.vegvesen.no/"
                        target="blank"
                    >
                        Gå til vegvesen.no
                    </NavLink>
                    <TertiaryButton
                        className="jkl-spacing-xl--top"
                        type="button"
                        aria-label="Avbryt endring"
                        onClick={() => cancelForm()}
                    >
                        Avbryt
                    </TertiaryButton>
                </div>
            )}
        </form>
    );
};

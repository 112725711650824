export type CoverageInfo = {
    [key: string]: {
        name: string;
        description?: string;
        coverageAmount: number;
        deductible: number;
    };
};

export const coverageInfo: CoverageInfo = {
    minikasko: {
        name: "Minikasko",
        description:
            "Passer for eldre biler med en verdi under 50 000 kr. Dekker brann, tyveri og glasskader. Inkluderer ansvar.",
        coverageAmount: 10000,
        deductible: 500
    },
    topp: {
        name: "Topp",
        description:
            "Vår beste bilforsikring, passer spesielt for biler som er inntil 10 år. Dekker maskinskade og leiebil inntil 30 dager. Inkluderer kasko, minikasko og ansvar.",
        coverageAmount: 1000000,
        deductible: 2000
    },
    kasko: {
        name: "Kasko",
        description:
            "En forsikring som passer de fleste biler. Dekker det meste av skader, også de du selv er ansvarlig for. Inkluderer minikasko og ansvar.",
        coverageAmount: 1000000,
        deductible: 2000
    },
    delkasko: {
        name: "Delkasko",
        coverageAmount: 50000,
        deductible: 1000
    },
    ansvar: {
        name: "Ansvar",
        description: "Er lovpålagt for alle registrerte kjøretøy. Dekker skader på personer og andres ting.",
        coverageAmount: 1000000,
        deductible: 2000
    },
    glasskade: {
        name: "Glasskade",
        coverageAmount: 1000000,
        deductible: 2000
    },
    veihjelp: {
        name: "Veihjelp",
        coverageAmount: 1000000,
        deductible: 2000
    },
    "fastm.tilleggsutstyr": {
        name: "Fastmontert tilleggsutstyr",
        coverageAmount: 1000000,
        deductible: 2000
    },
    eiendeleribilen: {
        name: "Eiendeler i bilen",
        coverageAmount: 1000000,
        deductible: 2000
    },
    skadepåegenbil: {
        name: "Skade på egen bil",
        coverageAmount: 1000000,
        deductible: 2000
    },
    nybilvedtotalskade: {
        name: "Ny bil ved totalskade",
        coverageAmount: 1000000,
        deductible: 2000
    },
    leiebil: {
        name: "Leiebil",
        coverageAmount: 1000000,
        deductible: 2000
    },
    "kaskom/leiebil": {
        name: "Kasko m/leiebil",
        coverageAmount: 1000000,
        deductible: 2000
    },
    "fører-ogpassasjerulykke": {
        name: "Fører- og passasjerulykke",
        coverageAmount: 1000000,
        deductible: 2000
    },
    "brann,tyverioghærverk": {
        name: "Brann, tyveri og hærverk",
        coverageAmount: 1000000,
        deductible: 2000
    },
    bilnøkkel: {
        name: "Bilnøkkel",
        coverageAmount: 1000000,
        deductible: 2000
    },
    verktøyibil: {
        name: "Verktøy i bil",
        coverageAmount: 1000000,
        deductible: 2000
    },
    maskinskade: {
        name: "Maskinskade",
        coverageAmount: 1000000,
        deductible: 2000
    },
    parkeringsskade: {
        name: "Parkeringsskade",
        coverageAmount: 1000000,
        deductible: 2000
    }
};

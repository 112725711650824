import type { AgreementDetails } from "src/model/gen";

import { InfoMessageBox } from "@fremtind/jkl-message-box-react";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementOnHold = ({ agreementDetails }: Props) => {
    const isOnHold = agreementDetails.status === "ACCEPT_ON_HOLD";

    if (!isOnHold) return null;

    return (
        <InfoMessageBox title={"Avtalen er på vent"} fullWidth data-testid="agreement-onhold">
            Avtalen trer automatisk i kraft når kjøretøyet er registrert på ny eier.
        </InfoMessageBox>
    );
};

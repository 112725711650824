import { BMRoute } from "src/sider";

import { invoice, invoiceOverview } from "./views";

export const invoiceRoutes: BMRoute[] = [
    {
        path: "faktura",
        children: [
            {
                element: <invoiceOverview.InvoiceOverview />,
                loader: invoiceOverview.loader,
                breadcrumbLabel: "Faktura og betaling",
                errorElement: <invoiceOverview.InvoiceOverviewErrorBoundary />,
                index: true
            },
            {
                path: ":invoiceId",
                element: <invoice.InvoiceDetails />,
                loader: invoice.loader,
                breadcrumbLabel: "Fakturadetaljer"
            }
        ]
    }
];
